import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import Task, { TaskRecord } from './../types/Task';

export default class TasksAPI{

    public static async filterTask(filter):Promise<{success:boolean, data:Task[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/tasks/filter", filter);
    }

    public static async editTask(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/tasks/edit", body);
    }

    public static async newTask(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/tasks/new", body);
    }

    public static async filterTaskRecords(
      filter, page
    ): Promise<{ success: boolean; data: TaskRecord[]; reason: string }> {
      return await NetworkUtil.makePost("/api/admin/taskrecords/filter", filter);
    }
  
    public static async queryTaskRecord(
      body
    ): Promise<{ success: boolean; record: TaskRecord }> {
      return await NetworkUtil.makePost("/api/admin/taskrecords/details", body);
    }
  
    public static async editTaskRecord(
      body,
    ): Promise<ServerResponse> {
      return NetworkUtil.makePost("/api/admin/taskrecords/edit", body);
    }
  
    public static async newTaskRecord(body): Promise<ServerResponse> {
      return await NetworkUtil.makePost("/api/admin/taskrecords/new", body);
    }

    public static async assignTaskToEmployee(body): Promise<ServerResponse> {
      return await NetworkUtil.makePost("/api/admin/taskrecords/assignTaskToEmployee", body);
    }
}