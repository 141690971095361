import React from 'react';
import Select from 'react-select';
import { buildRow, getBoolSelectVal, getLabel, getServiceTypeLabel, getSpecificServiceType, hideModal, getLabelForEvents, parseDate, dateWithNoTime } from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import Validator from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import { isEmptyObject } from 'jquery';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import GetField from '../../customfields/GetField';
import SystemAPI from '../../network/SystemAPI';
import RecordType from '../../types/RecordType';
import { IncidentRecord } from '../../types/Incident';

interface IncidentRecordModalState {
  selectedIncident: IncidentRecord;
  isHintActive:boolean
  hintID:number
  employee
}

interface IncidentRecordModalProps {
  selectedIncident: IncidentRecord;
  onSubmit?: (incident: IncidentRecord) => void;
  pageCustomQuestions?;
  facilities?;
  employees
  incidents
}
export class IncidentRecordModal extends React.Component<IncidentRecordModalProps, IncidentRecordModalState> {
  public static ID = 'IncidentRecordModal';

  constructor(props) {
    super(props);
    this.state = {
      isHintActive: false,
      hintID: 0,
      employee: null,
      selectedIncident: {
        ID: null,
        EmployeeUID: null,
        ReqNum: null,
        FacilityID: null,
        CustomFieldData: null,
        CreatedDate: null,
      } as IncidentRecord,
    };
    this.clearFields = this.clearFields.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<IncidentRecordModalProps>, nextContext: any) {
    // console.log('RecordsModal nextProps', nextProps);
    if (!isEmptyObject(nextProps.selectedIncident)) {
      let incident = nextProps.selectedIncident;
      this.setState({
        selectedIncident: {
          ID: incident.ID,
          IncidentID: incident.IncidentID,
          EmployeeUID: incident && incident.EmployeeUID ? incident.EmployeeUID : null,
          ReqNum: incident && incident.ReqNum ? incident.ReqNum : null,
          FacilityID: incident && incident.FacilityID ? incident.FacilityID : null,
          CustomFieldData: incident && incident.CustomFieldData && JSON.stringify(incident.CustomFieldData) !== '{}' ? JSON.parse(incident.CustomFieldData) : null,
          CreatedDate: incident && incident.CreatedDate ? incident.CreatedDate : null,
        } as IncidentRecord,
      });
    }
  }

  clearFields() {
    this.setState({
      selectedIncident: {
        ID: null,
        EmployeeUID: null,
        ReqNum: null,
        FacilityID: null,
        CustomFieldData: null,
        CreatedDate: null,
      } as IncidentRecord,
    });
  }

  render() {
    // console.log('IncidentRecordsModal props', this.props);
    // console.log('IncidentRecordsModal STATE', this.state);

    let employee = this.props.employees && this.props.employees.length > 0 ? this.props.employees.find(e => e.UID === this.state.selectedIncident.EmployeeUID) : null;
    let employeeNameString;
    if(employee){
      employeeNameString = `${employee.FirstName} ${employee.LastName} - ${dateWithNoTime(employee.DateOfBirth)} - ${employee.UID}`
    }
    

    let incident = this.props.incidents && this.props.incidents.length > 0 ? this.props.incidents.find(f => f.value === this.state.selectedIncident.IncidentID) : null;
    let incidentNameString;
    if(incident){
      incidentNameString = incident.label;
    }
    
   

    return (
      <div className="modal fade form_modal" id={IncidentRecordModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl" role="document">
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">
                  Update Incident Records
                </h5>
                <button style={{ outline: 'none' }} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body m-3">
                {buildRow(
                  'Employee Name',
                  <input
                    className="form-control"
                    id="EmployeeName"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'EmployeeName'}
                    aria-label="EmployeeName"
                    readOnly={true}
                    value={employeeNameString}
                  />
                )}
                {buildRow(
                  'Confirmation Code',
                  <input
                    className="form-control"
                    id="ConfirmationCode"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'ConfirmationCode'}
                    aria-label="ConfirmationCode"
                    readOnly={true}
                    value={this.state.selectedIncident.ReqNum ? this.state.selectedIncident.ReqNum : ''}
                  />
                )}
                 {/* {buildRow(
                //   'Facility',
                //   <input
                //     className="form-control"
                //     id="Facility"
                //     maxLength={500}
                //     autoComplete="off"
                //     type={'search'}
                //     name={'Facility'}
                //     aria-label="Facility"
                //     readOnly={true}
                //     //@ts-ignore
                //     value={this.state.selectedIncident && this.state.selectedIncident.FacilityID ? getLabel(this.state.selectedIncident.FacilityID, this.props.facilities).label : ''}
                //   />
                )}*/}

                {buildRow(
                  'Incident',
                  <input
                    className="form-control"
                    id="Incident"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'Incident'}
                    aria-label="Incident"
                    readOnly={true}
                    value={incidentNameString}
                  />
                )}

                {/*Custom Questionnaire Data*/}

                {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField  
                                                            val={val} 
                                                            selectedItem={this.state.selectedIncident ? this.state.selectedIncident : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedIncident: e})
                                                            }}  
                                                            />
                                                        </div>
                                                        <div className={this.state.isHintActive && val.ID === this.state.hintID ? "form-group row" : "d-none"}>
                                                            <div className="col-12 col-md-4">
                                                                <label className={"col-form-label"} htmlFor={'Hint'} style={{paddingTop: '0.5em', }}>Hint</label>
                                                            </div>
                                                            <div className="col-12 col-md-8 pl-0">
                                                                {val.Options.hint}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    let formValidation = {
                      EmployeeUID: this.state.selectedIncident && this.state.selectedIncident.EmployeeUID ? this.state.selectedIncident.EmployeeUID : null,
                      ReqNum: this.state.selectedIncident && this.state.selectedIncident.ReqNum ? this.state.selectedIncident.ReqNum : null,
                      FacilityID: this.state.selectedIncident && this.state.selectedIncident.FacilityID ? this.state.selectedIncident.FacilityID : null,
                      CustomFieldData: this.state.selectedIncident && this.state.selectedIncident.CustomFieldData ? this.state.selectedIncident.CustomFieldData : null,
                      CreatedDate: this.state.selectedIncident && this.state.selectedIncident.CreatedDate ? this.state.selectedIncident.CreatedDate : null,
                    };

                    let validator = new Validator<any>();
                    validator
                      .withSimpleValidation('EmployeeUID', Validators.requireNonNullValidator('Employee UID'))
                      .withSimpleValidation('ReqNum', Validators.requireNonNullValidator('Confirmation Code'))
                      .withSimpleValidation('FacilityID', Validators.requireNonNullValidator('Facility ID'));

                    let validationResponse = validator.validate(formValidation);
                    if (!validationResponse.success) {
                      return sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
                    }

                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedIncident.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedIncident)

                    if(invalid){
                        return;
                    }

                    hideModal(IncidentRecordModal.ID);
                    this.props.onSubmit({
                      ID: this.state.selectedIncident.ID,
                      EmployeeUID: formValidation.EmployeeUID,
                      ReqNum: formValidation.ReqNum,
                      FacilityID: formValidation.FacilityID,
                      CustomFieldData: JSON.stringify(formValidation.CustomFieldData),
                      CreatedDate: formValidation.CreatedDate,
                      RecordType: 'Incident'
                    } as IncidentRecord);
                    this.setState({
                      selectedIncident: {
                        ID: null,
                        EmployeeUID: null,
                        ReqNum: null,
                        FacilityID: null,
                        CustomFieldData: null,
                        CreatedDate: null,
                      } as IncidentRecord,
                    });
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
