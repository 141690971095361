import React from 'react';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import Overlay from '../Overlay';
import { getBoolSelectOptions, getBoolSelectVal, getNumPages, getPageOptions, hideModal, showModalNoOutsideClick, slicePages } from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import FilterCard, { FIELD_TYPE, ReactSelect } from '../FilterCard';
import NetworkUtil from '../../network/NetworkUtil';
import { Column } from '../tables/TableBase';
import { Sorter, TableOrder } from '../../sorting/Sorter';
import SimpleTable from '../tables/SimpleTable';
import InBetweenOverlay from '../InBetweenOverlay';
import Claim from '../../types/Claim';
import { ClaimManagementModal } from '../modals/ClaimsManagementModal';
import SystemAPI from '../../network/SystemAPI';
import { AdminPages } from './AdminNav';
import ClaimsAPI from '../../network/ClaimsAPI';
import EmployeeAPI from '../../network/EmployeeAPI';
import { AddRecordToEmployeeModal } from '../modals/AddRecordToEmployeeModal';

const ITEMS_PER_PAGE = 25;

interface ClaimManagementState {
  selectedClaim?;
  claims: Claim[];
  showLoading?;
  showInBetween?;
  filter?: {
    SelectedClaim;
    IsEnabled;
  };
  tableData: {}[];
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  claimQueryResults: Claim[];
  pageCustomQuestions?;
  addRecordPageCustomQuestions
  employees?;
}

export default class ClaimManagement extends React.Component<any, ClaimManagementState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showInBetween: false,
      selectedClaim: {} as Claim,
      claims: [],
      filter: {
        SelectedClaim: [],
        IsEnabled: [],
      },
      pageCustomQuestions: [],
      addRecordPageCustomQuestions: [],
      employees: [],
      tableData: [] as any,
      direction: 'asc',
      page_options: [{ label: '1', value: '1' }],
      selected_page: { label: '1', value: 1 },
      claimQueryResults: [] as any,
    };
    this.createOrModifyClaim = this.createOrModifyClaim.bind(this);
    this.assignClearState = this.assignClearState.bind(this);
  }

  clearFilterState: () => void = null;

  assignClearState(func) {
    this.clearFilterState = func;
  }

  componentDidMount() {
    document.title = 'Claim Management';
    this.setState({ showLoading: true }, () => {
      SystemAPI.getCustomQuestions(AdminPages.Claim).then(res => {
        this.setState({ pageCustomQuestions: res.questions });
      });
      SystemAPI.getCustomQuestions(AdminPages.ClaimRecords).then(res => {
        this.setState({ addRecordPageCustomQuestions: res.questions });
      });
      SystemAPI.getAllClaims().then(data => {
        this.setState({ claims: data });
      });
      EmployeeAPI.getEmployeesFromEmployeePortal().then(data => {
        this.setState({ employees: data.employees });
      });
      this.queryClaim(1);
    });
  }

  queryClaim(page: number) {
    this.setState({ showLoading: true }, async () => {
      try {
        let results = await ClaimsAPI.filterClaim({
          filter: this.state.filter ? this.state.filter : null,
        });

        if (results.data.length < 1) {
          this.setState({ showLoading: false, tableData: [], claimQueryResults: null });
          return sweetalert.fire({ title: '', text: 'No Claims returned', icon: 'info' });
        }

        if (!results.success) {
          return sweetalert.fire({ title: '', text: 'Unable to filter Claim data at this time', icon: 'error' });
        }
        this.setState({
          tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
          claimQueryResults: results.data,
          page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
          showLoading: false,
        });
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
      }
    });
  }

  createOrModifyClaim(claim: Claim) {
    this.setState({ showLoading: true }, async () => {
      if (claim.ID === null) {
        try {
          let result = await ClaimsAPI.newClaim(claim);
          if (result.success) {
            SystemAPI.getAllClaims().then(data => {
              this.setState({ claims: data});
            });
            sweetalert.fire({ icon: 'success', title: 'Claim saved', text: 'Do you want to assign employee to this claim?', confirmButtonText: 'Yes', showDenyButton: true, denyButtonText: 'No' }).then((result) => {
              if(result.isConfirmed){
                showModalNoOutsideClick(AddRecordToEmployeeModal.ID);
              }
              else{
                this.clearStateAndRefreshDataAfterAddRecordModal();
              }
            })
          } else {
            sweetalert.fire({ icon: 'error', title: '', text: result.reason });
            this.setState({ showLoading: false }, () => {
              this.clearStateAndRefreshDataAfterAddRecordModal()
            });
          }
        } catch (e) {
          console.error(e);
          this.setState({ showLoading: false }, () => {
            this.clearStateAndRefreshDataAfterAddRecordModal()
          });
        }
      } else {
        try {
          let result = await ClaimsAPI.editClaim(claim);
          if (result.success) {
            SystemAPI.getAllClaims().then(data => {
              this.setState({ claims: data });
            });
            sweetalert.fire({ icon: 'success', title: 'Claim saved', text: 'Do you want to assign employee to this claim?', confirmButtonText: 'Yes', showDenyButton: true, denyButtonText: 'No' }).then((result) => {
              if(result.isConfirmed){
                showModalNoOutsideClick(AddRecordToEmployeeModal.ID);
              }
              else{
                this.clearStateAndRefreshDataAfterAddRecordModal();
              }
            })
          } else {
            sweetalert.fire({ icon: 'error', title: '', text: result.reason });
            this.setState({ showLoading: false }, () => {
              this.clearStateAndRefreshDataAfterAddRecordModal()
            });
          }
        } catch (e) {
          console.error(e);
          this.setState({ showLoading: false }, () => {
            this.clearStateAndRefreshDataAfterAddRecordModal()
          });
        }
      }
    });
  }

  clearStateAndRefreshDataAfterAddRecordModal(){
    this.setState({
      showLoading: false,
      selectedClaim: {
        Name: '',
        IsEnabled: 0,
        CustomFieldData: null,
        CreatedDate: null
      } as Claim,
    }, () => {
      this.queryClaim(this.state.selected_page.value);
    })
  }

  handleExportToCSV(){
    this.setState({showLoading: true}, async () =>{
        await NetworkUtil.downloadCSV(
            "/api/admin/claims/csv",
            "Claim_Management.xlsx",
            {filter:  this.state.filter}
        )
        this.setState({showLoading: false})
    });
}

  useSorter(col: Column) {
    let sorter = new Sorter<any>();
    this.setState({
      tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction),
      direction: this.state.direction === 'asc' ? 'desc' : 'asc',
    });
  }

  changePage(page: number) {
    let allClaims = this.state.claimQueryResults;
    let returnData = slicePages(allClaims, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  renderClaimFilterFields() {
    return (
      <FilterCard
        passClearStateFunc={this.assignClearState}
        fields={[
          {
            label: 'Name',
            key: 'SelectedClaim',
            type: FIELD_TYPE.SELECT,
            options: this.state.claims,
            isMapped: true,
            isMulti: true,
            textType: 'text',
          },
          {
            label: 'Is Enabled',
            key: 'IsEnabled',
            type: FIELD_TYPE.SELECT,
            options: getBoolSelectOptions(),
            isMapped: true,
            isMulti: true,
            textType: 'text',
            isFilter: true,
          },
        ]}
        filterChanged={e => this.setState({ filter: e })}
      />
    );
  }

  assignClaimToEmployee(obj) {
    this.setState({ showLoading: true }, async () => {
      obj.ClaimID = obj.RecordID;
      delete obj.RecordID;
      try {
        let result = await ClaimsAPI.assignClaimToEmployee(obj);
        if (result.success) {
          sweetalert.fire({ icon: 'success', title: '', text: 'Claim assigned successfully.' })
            .then(() => {
                this.clearStateAndRefreshDataAfterAddRecordModal()
            });
        } else {
          sweetalert.fire({ icon: 'error', title: '', text: result.reason }).then(() => {
            this.clearStateAndRefreshDataAfterAddRecordModal()
          });
        }
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
        sweetalert.fire({icon: 'error', title: '', text: 'Error assigning Claim to employee'})
        this.clearStateAndRefreshDataAfterAddRecordModal();
        return
      }
    });
  }

  render() {
    // console.log('ClaimMgmt state', this.state)
    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        <InBetweenOverlay showInBetween={this.state.showInBetween} />
        <ClaimManagementModal
          key={this.state.selectedClaim && this.state.selectedClaim.ID ? this.state.selectedClaim.ID : `new${new Date().getTime()}`}
          selectedClaim={this.state.selectedClaim}
          onSubmit={(claim: Claim) => {
            hideModal(ClaimManagementModal.ID);
            this.createOrModifyClaim(claim);
          }}
          employees={this.state.employees}
          pageCustomQuestions={this.state.pageCustomQuestions}
        />
        <AddRecordToEmployeeModal
          fromMgmt={true}
          passedInRecord={this.state.selectedClaim && this.state.selectedClaim.ID ? this.state.selectedClaim : this.state.claims && this.state.claims.length > 0 ? this.state.claims[this.state.claims.length-1] : null}
          pageCustomQuestions={this.state.addRecordPageCustomQuestions}
          options={this.state.claims}
          employees={this.state.employees}
          recordType={'Claim'}
          onSubmit={(obj) => {
            this.assignClaimToEmployee(obj);
          }}
          clearStateAndRefreshDataAfterAddRecordModal={() => {
            this.clearStateAndRefreshDataAfterAddRecordModal()
          }}
        />
        <div className="container-fluid ">
          <div className={'row'}>
            <div className="col-12 col-lg-8 col-xl-5 pt-2">
              <div className="card mb-2">
                <div className="card-header verlag-bold">
                  <h4>Claim Management</h4>
                </div>
                <div className="card-body">{this.renderClaimFilterFields()}</div>
                <div className="card-footer">
                  <button
                    className={'btn immySubmitButtonOutline'}
                    onClick={() => {
                      this.setState(
                        {
                          selected_page: { label: 1, value: 1 },
                        },
                        () => {
                          this.queryClaim(this.state.selected_page.value);
                        }
                      );
                    }}
                  >
                    Search
                  </button>

                  <button
                    className="btn btn-outline-success float-right"
                    onClick={() =>
                      this.setState(
                        {
                          selectedClaim: {},
                        },
                        () => {
                          showModalNoOutsideClick(ClaimManagementModal.ID);
                        }
                      )
                    }
                  >
                    Create Claim
                  </button>
                </div>
              </div>
            </div>

            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold">
                    <h4 className="text-center text-md-left">
                      Claims
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={'float-md-right'}>Total: {this.state.claimQueryResults.length}</h4>
                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={'1'}
                            noOptionsMessage={() => 'No option'}
                            value={this.state.selected_page}
                            onChange={(e: ReactSelect) => this.setState({ selected_page: e }, () => this.changePage(e.value))}
                            className={'state_select'}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button className={'d-none d-md-inline btn btn-outline-primary ml-3'} onClick={() => this.handleExportToCSV()}>
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      table_style="tableFixHead"
                      columns={[
                        {
                          label: 'Name',
                          key: 'Name',
                          rawFormat: val => {
                            return (
                              <a
                                href={'#top'}
                                className={'tableNameLinkColor'}
                                onClick={() => {
                                  this.setState({ selectedClaim: val }, () => {
                                    showModalNoOutsideClick(ClaimManagementModal.ID);
                                  });
                                }}
                              >
                                {val.Name}
                              </a>
                            );
                          },
                          popoverText: 'Click to sort by Name',
                        },
                        {
                          label: 'Is Enabled',
                          key: 'IsEnabled',
                          popoverText: 'Click to sort by Enabled',
                          rawFormat: val => {
                            let string = getBoolSelectVal(val.IsEnabled);
                            return <p>{string.label}</p>;
                          },
                        },
                        {label:"Assign To Employee", key:"",
                          rawFormat: val => (
                          <button 
                            type={'button'} 
                            className={'btn btn-outline-success'} 
                            onClick={() => {
                              this.setState({ selectedClaim: val }, () => {
                                showModalNoOutsideClick(AddRecordToEmployeeModal.ID);
                              });
                            }}>
                            Assign To Employee
                          </button>
                        )},
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={col => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
