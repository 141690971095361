import React from "react";
import Select from "react-select";
import {
    buildRow,
    getBoolSelectVal,
    hideModal,
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {isEmptyObject} from "jquery";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import GetField from "../../customfields/GetField";
import Claim from "../../types/Claim";

interface ClaimManagementState {
    selectedClaim
    customFieldData?
    isHintActive:boolean
    hintID:number
}

interface ClaimManagementModalProps{
    selectedClaim:Claim
    onSubmit?:(claim:Claim) => void
    pageCustomQuestions?
    employees?
}
export class ClaimManagementModal extends React.Component<ClaimManagementModalProps, ClaimManagementState> {
    public static ID = "claimmanagement"

    constructor(props) {
        super(props);
        this.state = {
            isHintActive: false,
            hintID: 0,
            selectedClaim: {
                ID:  null,
                Name: '',
                IsEnabled: 0,
                CustomFieldData: null,
                CreatedDate: null,
            } as Claim
        }
        this.clearFields = this.clearFields.bind(this)
    }

    componentWillReceiveProps(nextProps: Readonly<ClaimManagementModalProps>, nextContext: any) {
        // console.log('Claim Management Modal nextProps', nextProps)
        if(!isEmptyObject(nextProps.selectedClaim)){
            let claim = nextProps.selectedClaim;

            this.setState({
                selectedClaim: {
                    ID: claim.ID,
                    Name: claim.Name,
                    IsEnabled: !claim.IsEnabled ? 0 : 1,
                    CustomFieldData: claim && claim.CustomFieldData && JSON.stringify(claim.CustomFieldData) !== '{}' ? JSON.parse(claim.CustomFieldData) : null,
                    CreatedDate: claim && claim.CreatedDate ? claim.CreatedDate : null,
                } as Claim,
            });
        }
    }

    clearFields(){
        this.setState({
            selectedClaim: {
              ID:  null,
              Name: '',
              IsEnabled: 0,
              CustomFieldData: null,
              CreatedDate: null,
            } as Claim,
        })
    }


    render() {

        // console.log('Claim Mgmt Modal props', this.props)
        // console.log('Claim Mgmt Modal state', this.state)
        
        return(
            <div className="modal fade form_modal" id={ClaimManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{this.state.selectedClaim && this.state.selectedClaim.ID ? 'Update Claim' : 'Create Claim'}</h5>
                            <button  style={{outline: 'none'}} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body m-3">

                            {buildRow("Name",
                                <input className="form-control"
                                       maxLength={500}
                                       autoComplete="off"
                                       type={"search"}
                                       name={"Name"}
                                       readOnly={this.state.selectedClaim.ID != null}
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedClaim: {
                                                   ...prevState.selectedClaim,
                                                   Name: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedClaim.Name}
                                />)}
                            {buildRow("Is Enabled?",
                                <Select
                                    isSearchable={true}
                                    placeholder={"Please Select..."}
                                    noOptionsMessage={() => "No option"}
                                    value={getBoolSelectVal(this.state.selectedClaim.IsEnabled)}
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedClaim: {
                                            ...prevState.selectedClaim,
                                            IsEnabled: e.value ? 1 : 0
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                            />)}
               


                            {/*Custom Field Data*/}

                            {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField  
                                                            val={val} 
                                                            selectedItem={this.state.selectedClaim ? this.state.selectedClaim : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedClaim: e})
                                                            }}  
                                                            />
                                                        </div>
                                                        <div className={this.state.isHintActive && val.ID === this.state.hintID ? "form-group row" : "d-none"}>
                                                            <div className="col-12 col-md-4">
                                                                <label className={"col-form-label"} htmlFor={'Hint'} style={{paddingTop: '0.5em', }}>Hint</label>
                                                            </div>
                                                            <div className="col-12 col-md-8 pl-0">
                                                                {val.Options.hint}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={async() => {
                                    let formValidation = {
                                        Name: this.state.selectedClaim.Name,
                                        IsEnabled: this.state.selectedClaim.IsEnabled,
                                    }
                                    
                                    let validator = new Validator<any>()
                                        .withValidation("Name", Validators.requireNotBlankAndLength(500))
                                    if(!this.state.selectedClaim.IsEnabled && this.state.selectedClaim.IsEnabled !== 0){
                                        validator = validator.withSimpleValidation("IsEnabled", Validators.requireNonNullValidator("Is Enabled"))
                                    }
                                       
                                    let validationResponse = validator.validate(formValidation);
                                    if(!validationResponse.success) {
                                        return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                    }

                                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedClaim.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedClaim)

                                    if(invalid){
                                        return;
                                    }

                                    hideModal(ClaimManagementModal.ID)
                                    this.props.onSubmit(
                                        {
                                            ID: this.state.selectedClaim.ID,
                                            Name: this.state.selectedClaim.Name,
                                            IsEnabled: this.state.selectedClaim.IsEnabled,
                                            CustomFieldData: this.state.selectedClaim.CustomFieldData,
                                            CreatedDate: this.state.selectedClaim.CreatedDate
                                    
                                        } as Claim)
                                    // this.setState({
                                    //     selectedClaim: {
                                    //         ID: null,
                                    //         Name: '',
                                    //         IsEnabled: 0,
                                    //         CustomFieldData: null,
                                    //         CreatedDate: null
                                    //     } as Claim,
                                    // })
                                }}>
                                    {this.state.selectedClaim && this.state.selectedClaim.ID ? 'Update' : 'Create'}
                                </button>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}