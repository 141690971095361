import React from 'react';
import Select from 'react-select';
import { buildRow, getBoolSelectVal, getLabel, getServiceTypeLabel, getSpecificServiceType, hideModal, getLabelForEvents, parseDate, dateWithNoTime } from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import Validator from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import { isEmptyObject } from 'jquery';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import GetField from '../../customfields/GetField';
import SystemAPI from '../../network/SystemAPI';
import RecordType from '../../types/RecordType';
import { TaskRecord } from '../../types/Task';

interface TaskRecordModalState {
  selectedTask: TaskRecord;
  isHintActive:boolean
  hintID:number
  employee
}

interface TaskRecordModalProps {
  selectedTask: TaskRecord;
  onSubmit?: (task: TaskRecord) => void;
  pageCustomQuestions?;
  facilities?;
  employees
  tasks
}
export class TaskRecordModal extends React.Component<TaskRecordModalProps, TaskRecordModalState> {
  public static ID = 'TaskRecordModal';

  constructor(props) {
    super(props);
    this.state = {
      isHintActive: false,
      hintID: 0,
      employee: null,
      selectedTask: {
        ID: null,
        EmployeeUID: null,
        ReqNum: null,
        FacilityID: null,
        CustomFieldData: null,
        CreatedDate: null,
      } as TaskRecord,
    };
    this.clearFields = this.clearFields.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<TaskRecordModalProps>, nextContext: any) {
    // console.log('RecordsModal nextProps', nextProps);
    if (!isEmptyObject(nextProps.selectedTask)) {
      let task = nextProps.selectedTask;
      this.setState({
        selectedTask: {
          ID: task.ID,
          TaskID: task.TaskID,
          EmployeeUID: task && task.EmployeeUID ? task.EmployeeUID : null,
          ReqNum: task && task.ReqNum ? task.ReqNum : null,
          FacilityID: task && task.FacilityID ? task.FacilityID : null,
          CustomFieldData: task && task.CustomFieldData && JSON.stringify(task.CustomFieldData) !== '{}' ? JSON.parse(task.CustomFieldData) : null,
          CreatedDate: task && task.CreatedDate ? task.CreatedDate : null,
        } as TaskRecord,
      });
    }
  }

  clearFields() {
    this.setState({
      selectedTask: {
        ID: null,
        EmployeeUID: null,
        ReqNum: null,
        FacilityID: null,
        CustomFieldData: null,
        CreatedDate: null,
      } as TaskRecord,
    });
  }

  render() {
    // console.log('TaskRecordsModal props', this.props);
    // console.log('TaskRecordsModal STATE', this.state);

    let employee = this.props.employees && this.props.employees.length > 0 ? this.props.employees.find(e => e.UID === this.state.selectedTask.EmployeeUID) : null;
    let employeeNameString;
    if(employee){
      employeeNameString = `${employee.FirstName} ${employee.LastName} - ${dateWithNoTime(employee.DateOfBirth)} - ${employee.UID}`
    }
    

    let task = this.props.tasks && this.props.tasks.length > 0 ? this.props.tasks.find(f => f.value === this.state.selectedTask.TaskID) : null;
    let taskNameString;
    if(task){
      taskNameString = task.label;
    }
    
   

    return (
      <div className="modal fade form_modal" id={TaskRecordModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl" role="document">
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">
                  Update Task Records
                </h5>
                <button style={{ outline: 'none' }} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body m-3">
                {buildRow(
                  'Employee Name',
                  <input
                    className="form-control"
                    id="EmployeeName"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'EmployeeName'}
                    aria-label="EmployeeName"
                    readOnly={true}
                    value={employeeNameString}
                  />
                )}
                {buildRow(
                  'Confirmation Code',
                  <input
                    className="form-control"
                    id="ConfirmationCode"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'ConfirmationCode'}
                    aria-label="ConfirmationCode"
                    readOnly={true}
                    value={this.state.selectedTask.ReqNum ? this.state.selectedTask.ReqNum : ''}
                  />
                )}
                 {/* {buildRow(
                //   'Facility',
                //   <input
                //     className="form-control"
                //     id="Facility"
                //     maxLength={500}
                //     autoComplete="off"
                //     type={'search'}
                //     name={'Facility'}
                //     aria-label="Facility"
                //     readOnly={true}
                //     //@ts-ignore
                //     value={this.state.selectedTask && this.state.selectedTask.FacilityID ? getLabel(this.state.selectedTask.FacilityID, this.props.facilities).label : ''}
                //   />
                )}*/}

                {buildRow(
                  'Task',
                  <input
                    className="form-control"
                    id="Task"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'Task'}
                    aria-label="Task"
                    readOnly={true}
                    value={taskNameString}
                  />
                )}

                {/*Custom Questionnaire Data*/}

                {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField  
                                                            val={val} 
                                                            selectedItem={this.state.selectedTask ? this.state.selectedTask : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedTask: e})
                                                            }}  
                                                            />
                                                        </div>
                                                        <div className={this.state.isHintActive && val.ID === this.state.hintID ? "form-group row" : "d-none"}>
                                                            <div className="col-12 col-md-4">
                                                                <label className={"col-form-label"} htmlFor={'Hint'} style={{paddingTop: '0.5em', }}>Hint</label>
                                                            </div>
                                                            <div className="col-12 col-md-8 pl-0">
                                                                {val.Options.hint}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    let formValidation = {
                      EmployeeUID: this.state.selectedTask && this.state.selectedTask.EmployeeUID ? this.state.selectedTask.EmployeeUID : null,
                      ReqNum: this.state.selectedTask && this.state.selectedTask.ReqNum ? this.state.selectedTask.ReqNum : null,
                      FacilityID: this.state.selectedTask && this.state.selectedTask.FacilityID ? this.state.selectedTask.FacilityID : null,
                      CustomFieldData: this.state.selectedTask && this.state.selectedTask.CustomFieldData ? this.state.selectedTask.CustomFieldData : null,
                      CreatedDate: this.state.selectedTask && this.state.selectedTask.CreatedDate ? this.state.selectedTask.CreatedDate : null,
                    };

                    let validator = new Validator<any>();
                    validator
                      .withSimpleValidation('EmployeeUID', Validators.requireNonNullValidator('Employee UID'))
                      .withSimpleValidation('ReqNum', Validators.requireNonNullValidator('Confirmation Code'))
                      .withSimpleValidation('FacilityID', Validators.requireNonNullValidator('Facility ID'));

                    let validationResponse = validator.validate(formValidation);
                    if (!validationResponse.success) {
                      return sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
                    }

                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedTask.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedTask)

                    if(invalid){
                        return;
                    }

                    hideModal(TaskRecordModal.ID);
                    this.props.onSubmit({
                      ID: this.state.selectedTask.ID,
                      EmployeeUID: formValidation.EmployeeUID,
                      ReqNum: formValidation.ReqNum,
                      FacilityID: formValidation.FacilityID,
                      CustomFieldData: JSON.stringify(formValidation.CustomFieldData),
                      CreatedDate: formValidation.CreatedDate,
                      RecordType: 'Task'
                    } as TaskRecord);
                    this.setState({
                      selectedTask: {
                        ID: null,
                        EmployeeUID: null,
                        ReqNum: null,
                        FacilityID: null,
                        CustomFieldData: null,
                        CreatedDate: null,
                      } as TaskRecord,
                    });
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
