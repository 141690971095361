import React from "react";
import Select from "react-select";
import {
    buildRow,
    getBoolSelectVal,
    hideModal,
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {isEmptyObject} from "jquery";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import GetField from "../../customfields/GetField";
import EvaluationType from "../../types/EvaluationType";
import SystemAPI from "../../network/SystemAPI";

interface EvaluationManagementState {
    selectedEvaluation
    customFieldData?
    isHintActive:boolean
    hintID:number
}

interface EvaluationManagementModalProps{
    selectedEvaluation:EvaluationType
    onSubmit?:(evaluation:EvaluationType) => void
    pageCustomQuestions?
}
export default class EvaluationManagementModal extends React.Component<EvaluationManagementModalProps, EvaluationManagementState> {
    public static ID = "evaluationmanagement"

    constructor(props) {
        super(props);
        this.state = {
            isHintActive: false,
            hintID: 0,
            selectedEvaluation: {
                ID:  null,
                Name: '',
                IsEnabled: 0,
                CustomFieldData: null,
                CreatedDate: null,
            } as EvaluationType
        }
        this.clearFields = this.clearFields.bind(this)
    }

    componentWillReceiveProps(nextProps: Readonly<EvaluationManagementModalProps>, nextContext: any) {
        // console.log('EvaluationManagementModal nextProps', nextProps)
        if(!isEmptyObject(nextProps.selectedEvaluation)){
            let evaluation = nextProps.selectedEvaluation;

            this.setState({
                selectedEvaluation: {
                    ID: evaluation.ID,
                    Name: evaluation.Name,
                    IsEnabled: !evaluation.IsEnabled ? 0 : 1,
                    CustomFieldData: evaluation.CustomFieldData && JSON.stringify(evaluation.CustomFieldData) !== '{}' ? JSON.parse(evaluation.CustomFieldData) : null,
                    CreatedDate: evaluation.CreatedDate ? evaluation.CreatedDate : null,
                } as EvaluationType,
            });
        }
    }

    clearFields(){
        this.setState({
            selectedEvaluation: {
              ID:  null,
              Name: '',
              IsEnabled: 0,
              CustomFieldData: null,
              CreatedDate: null,
            } as EvaluationType,
        })
    }


    render() {
        // console.log('Evaluation Management Modal render', this.state)
        return(
            <div className="modal fade form_modal" id={EvaluationManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{this.state.selectedEvaluation && this.state.selectedEvaluation.ID ? 'Update Evaluation' : 'Create Evaluation'}</h5>
                            <button  style={{outline: 'none'}} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body m-3">

                            {buildRow("Name",
                                <input className="form-control"
                                       maxLength={500}
                                       autoComplete="off"
                                       type={"search"}
                                       name={"Name"}
                                       readOnly={this.state.selectedEvaluation.ID != null}
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedEvaluation: {
                                                   ...prevState.selectedEvaluation,
                                                   Name: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedEvaluation.Name}
                                />)}
                            {buildRow("Is Enabled?",
                                <Select
                                    isSearchable={true}
                                    placeholder={"Please Select..."}
                                    noOptionsMessage={() => "No option"}
                                    value={getBoolSelectVal(this.state.selectedEvaluation.IsEnabled)}
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedEvaluation: {
                                            ...prevState.selectedEvaluation,
                                            IsEnabled: e.value ? 1 : 0
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                            />)}

                            {/*Custom Field Data*/}

                            {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedEvaluation ? this.state.selectedEvaluation : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedEvaluation: e})
                                                            }}  
                                                            />
                                                        </div>
                                                        <div className={this.state.isHintActive && val.ID === this.state.hintID ? "form-group row" : "d-none"}>
                                                            <div className="col-12 col-md-4">
                                                                <label className={"col-form-label"} htmlFor={'Hint'} style={{paddingTop: '0.5em', }}>Hint</label>
                                                            </div>
                                                            <div className="col-12 col-md-8 pl-0">
                                                                {val.Options.hint}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={async() => {
                                    let formValidation = {
                                        Name: this.state.selectedEvaluation.Name,
                                        IsEnabled: this.state.selectedEvaluation.IsEnabled,
                                    }
                                    let passed = 1;

                                    if(this.state.selectedEvaluation.IsEnabled === 0){
                                        await SystemAPI.checkIfEvaluationAssignedToService(this.state.selectedEvaluation.ID).then(services => {
                                            if(services.length > 0){
                                                passed = 0;
                                                if(services.length > 1){
                                                    let serviceNames = []
                                                    services.map((elem) => {
                                                        serviceNames.push(elem.Name)
                                                    })
                                                    let nameString = serviceNames.toString().replace(/,/g, ', ')
                                                    return sweetalert.fire({icon: 'error', title: '', html:`<p style="font-weight: bold;">${this.state.selectedEvaluation.Name}</p><p>must be removed from the below services before it can be disabled.</p><p style="font-weight: bold;">${nameString}</p>`});
                                                } else {
                                                    return sweetalert.fire({icon: 'error', title: '', text: `<p style="font-weight: bold;">${this.state.selectedEvaluation.Name}</p><p>must be removed from the below service before it can be disabled <p style="font-weight: bold;">${services[0].Name}</p>`});
                                                }
                                                
                                            }
                                        })
                                    }

                                    if(passed === 0){
                                        return;
                                    }

                                    let validator = new Validator<any>()
                                        .withValidation("Name", Validators.requireNotBlankAndLength(500))
                                    if(!this.state.selectedEvaluation.IsEnabled && this.state.selectedEvaluation.IsEnabled !== 0){
                                        validator = validator.withSimpleValidation("IsEnabled", Validators.requireNonNullValidator("Is Enabled"))
                                    }  
                                    let validationResponse = validator.validate(formValidation);
                                    if(!validationResponse.success) {
                                        return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                    }

                                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedEvaluation.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedEvaluation)

                                    if(invalid){
                                        return;
                                    }

                                    hideModal(EvaluationManagementModal.ID)
                                    this.props.onSubmit(
                                        {
                                            ID: this.state.selectedEvaluation.ID,
                                            Name: this.state.selectedEvaluation.Name,
                                            IsEnabled: this.state.selectedEvaluation.IsEnabled,
                                            CustomFieldData: this.state.selectedEvaluation.CustomFieldData,
                                            CreatedDate: this.state.selectedEvaluation.CreatedDate
                                        } as EvaluationType)
                                    this.setState({
                                        selectedEvaluation: {
                                            ID: null,
                                            Name: '',
                                            IsEnabled: 0, 
                                            CustomFieldData: null,
                                            CreatedDate: null
                                        } as EvaluationType,
                                    })
                                }}>
                                    {this.state.selectedEvaluation && this.state.selectedEvaluation.ID ? 'Update' : 'Create'}
                                </button>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}