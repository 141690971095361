import React from 'react';
import Select from 'react-select';
import { buildRow, getBoolSelectVal, getLabel, getServiceTypeLabel, getSpecificServiceType, hideModal, getLabelForEvents, parseDate } from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import Validator from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import { isEmptyObject } from 'jquery';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import GetField from '../../customfields/GetField';
import SystemAPI from '../../network/SystemAPI';
import RecordType from '../../types/RecordType';

interface RecordsModalState {
  selectedRecord: RecordType;
}

interface RecordsModalProps {
  selectedRecord;
  onSubmit?: (record: RecordType) => void;
  employeeName;
  customQuestions?;
  vaccines?;
  evaluations?;
  tests?;
  serviceTypes?;
  facilities?;
  services?;
  events?;
}
export class RecordsModal extends React.Component<RecordsModalProps, RecordsModalState> {
  public static ID = 'records';

  constructor(props) {
    super(props);
    this.state = {
      selectedRecord: {
        ID: null,
        ServiceType: null,
        ServiceTypeSpecificLabel: null,
        EmployeeUID: null,
        ReqNum: null,
        LinkedApptsID: null,
        EventApptID: null,
        AppointmentDate: null,
        AppointmentTime: null,
        ApptCanceled: null,
        CheckedInDate: null,
        FacilityID: null,
        ServiceID: null,
        CustomFieldData: null,
        CreatedDate: null
      } as RecordType,
    };
    this.clearFields = this.clearFields.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<RecordsModalProps>, nextContext: any) {
    // console.log('RecordsModal nextProps', nextProps);
    if (!isEmptyObject(nextProps.selectedRecord)) {
      let record = nextProps.selectedRecord;
      this.setState({
        selectedRecord: {
          ID: record.ID,
          ServiceType: record ? getServiceTypeLabel(record, this.props.serviceTypes) : null,
          ServiceTypeSpecificLabel: record ? getSpecificServiceType(record, this.props.vaccines, this.props.evaluations, this.props.tests) : [],
          EmployeeUID: record && record.EmployeeUID ? record.EmployeeUID : null,
          ReqNum: record && record.ReqNum ? record.ReqNum : null,
          LinkedApptsID: record && record.LinkedApptsID ? record.LinkedApptsID : null,
          EventApptID: record && record.EventApptID ? record.EventApptID : null,
          AppointmentDate: record && record.AppointmentDate ? record.AppointmentDate : null,
          AppointmentTime: record && record.AppointmentTime ? record.AppointmentTime : null,
          ApptCanceled: record && record.ApptCanceled ? record.ApptCanceled : null,
          CheckedInDate: record && record.CheckedInDate ? record.CheckedInDate : null,
          FacilityID: record && record.FacilityID ? record.FacilityID : null,
          ServiceID: record && record.ServiceID ? record.ServiceID : null,
          CustomFieldData: record && record.CustomFieldData && JSON.stringify(record.CustomFieldData) !== '{}' ? JSON.parse(record.CustomFieldData) : null,
          CreatedDate: record && record.CreatedDate ? record.CreatedDate : null
        } as RecordType,
      });
    }
  }

  clearFields() {
    this.setState({
      selectedRecord: {
        ID: null,
        ServiceType: null,
        ServiceTypeSpecificLabel: null,
        EmployeeUID: null,
        ReqNum: null,
        LinkedApptsID: null,
        EventApptID: null,
        AppointmentDate: null,
        AppointmentTime: null,
        ApptCanceled: null,
        CheckedInDate: null,
        FacilityID: null,
        ServiceID: null,
        CustomFieldData: null,
        CreatedDate: null
      } as RecordType,
    });
  }

  render() {
    // console.log('RecordsModal props', this.props);
    // console.log('RecordsModal STATE', this.state);


    let serviceQuestions = this.props.customQuestions
      ? this.props.customQuestions.filter(f => f.ServiceID && f.ServiceID === this.state.selectedRecord.ServiceID && f.Options.isActive && !f.IsPatientField)
      : [];
    
    //@ts-ignore
    if(serviceQuestions && serviceQuestions.length > 0 && this.state.selectedRecord && this.state.selectedRecord.ServiceType && this.state.selectedRecord.ServiceType.value){
      //@ts-ignore
      //if serviceType === 5 then 
      serviceQuestions = serviceQuestions.filter(f => f.Options.serviceType === this.state.selectedRecord.ServiceType.value);
    }

    return (
      <div className="modal fade form_modal" id={RecordsModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl" role="document">
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">
                  Update Record
                </h5>
                <button style={{ outline: 'none' }} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body m-3">
                <div className="row">
                  <div className="col-12 col-lg-6">
                    {buildRow(
                      'Employee Name',
                      <input
                        className="form-control"
                        id="EmployeeName"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'EmployeeName'}
                        aria-label="EmployeeName"
                        readOnly={true}
                        value={this.props.employeeName ? this.props.employeeName : ''}
                      />
                    )}
                    {buildRow(
                      'Employee UID',
                      <input
                        className="form-control"
                        id="EmployeeUID"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'EmployeeUID'}
                        aria-label="EmployeeUID"
                        readOnly={true}
                        value={this.state.selectedRecord.EmployeeUID ? this.state.selectedRecord.EmployeeUID : ''}
                      />
                    )}
                    {buildRow(
                      'Service',
                      <input
                        className="form-control"
                        id="Service"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'Service'}
                        aria-label="Service"
                        readOnly={true}
                        //@ts-ignore
                        value={this.state.selectedRecord && this.state.selectedRecord.ServiceID ? getLabel(this.state.selectedRecord.ServiceID, this.props.services).label : ''}
                      />
                    )}
                    {buildRow(
                      'Service Type',
                      <input
                        className="form-control"
                        id="ServiceType"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'ServiceType'}
                        aria-label="ServiceType"
                        readOnly={true}
                        //@ts-ignore
                        value={this.state.selectedRecord && this.state.selectedRecord.ServiceType && this.state.selectedRecord.ServiceType.label ? this.state.selectedRecord.ServiceType.label : []}
                      />
                    )}
                    {/* {buildRow(
                      'Event',
                      <input
                        className="form-control"
                        id="Event"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'Event'}
                        aria-label="Event"
                        readOnly={true}
                        //@ts-ignore
                        value={this.state.selectedRecord && this.state.selectedRecord.EventApptID ? getLabelForEvents(this.state.selectedRecord.EventApptID, this.props.events).label : []}
                      />
                    )} */}
                    {buildRow(
                      'Appointment Date',
                      <input
                        className="form-control"
                        id="AppointmentDate"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'AppointmentDate'}
                        aria-label="AppointmentDate"
                        readOnly={true}
                        value={this.state.selectedRecord && this.state.selectedRecord.AppointmentDate ? parseDate(this.state.selectedRecord.AppointmentDate) : ''}
                      />
                    )}
                    {buildRow(
                        'Appointment Canceled',
                        <input
                            className="form-control"
                            id="AppointmentCanceled"
                            maxLength={500}
                            autoComplete="off"
                            type={'search'}
                            name={'AppointmentCanceled'}
                            aria-label="AppointmentCanceled"
                            readOnly={true}
                            value={this.state.selectedRecord ? getBoolSelectVal(this.state.selectedRecord.ApptCanceled).label : ''}
                        />
                        // <Select
                        //     isSearchable={true}
                        //     placeholder={<div className="accessibilityText">Please Select...</div>}
                        //     noOptionsMessage={() => "No option"}
                        //     value={getBoolSelectVal(this.state.selectedTest.IsEnabled)}
                        //     aria-label="Is Enabled?"
                        //     onChange={(e) => this.setState((prevState)=>({
                        //         selectedTest: {
                        //             ...prevState.selectedTest,
                        //             IsEnabled: e.value ? 1 : 0
                        //         }
                        //     }))}
                        //     className={"state_select"}
                        //     options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                        // />
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    {buildRow(
                      'Confirmation Code',
                      <input
                        className="form-control"
                        id="ConfirmationCode"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'ConfirmationCode'}
                        aria-label="ConfirmationCode"
                        readOnly={true}
                        value={this.state.selectedRecord.ReqNum ? this.state.selectedRecord.ReqNum : ''}
                      />
                    )}
                    {buildRow(
                      'Appointment Code',
                      <input
                        className="form-control"
                        id="AppointmentCode"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'AppointmentCode'}
                        aria-label="AppointmentCode"
                        readOnly={true}
                        value={this.state.selectedRecord.LinkedApptsID ? this.state.selectedRecord.LinkedApptsID : ''}
                      />
                    )}
                    {buildRow(
                      'Facility',
                      <input
                        className="form-control"
                        id="Facility"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'Facility'}
                        aria-label="Facility"
                        readOnly={true}
                        //@ts-ignore
                        value={this.state.selectedRecord && this.state.selectedRecord.FacilityID ? getLabel(this.state.selectedRecord.FacilityID, this.props.facilities).label : ''}
                      />
                    )}
                    {buildRow(
                      'Service Specific Type',
                      <input
                        className="form-control"
                        id="ServiceSpecificType"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'ServiceSpecificType'}
                        aria-label="ServiceSpecificType"
                        readOnly={true}
                        //@ts-ignore
                        value={this.state.selectedRecord && this.state.selectedRecord.ServiceTypeSpecificLabel && this.state.selectedRecord.ServiceTypeSpecificLabel.label ? this.state.selectedRecord.ServiceTypeSpecificLabel.label : []}
                      />
                    )}
                    {buildRow(
                      'Appointment Time',
                      <input
                        className="form-control"
                        id="AppointmentTime"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'AppointmentTime'}
                        aria-label="AppointmentTime"
                        readOnly={true}
                        value={this.state.selectedRecord && this.state.selectedRecord.AppointmentTime ? this.state.selectedRecord.AppointmentTime : ''}
                      />
                    )}
                    {buildRow(
                      'Checked In Date',
                      <input
                        className="form-control"
                        id="CheckedInDate"
                        maxLength={500}
                        autoComplete="off"
                        type={'search'}
                        name={'CheckedInDate'}
                        aria-label="CheckedInDate"
                        readOnly={true}
                        value={this.state.selectedRecord && this.state.selectedRecord.CheckedInDate ? parseDate(this.state.selectedRecord.CheckedInDate) : ''}
                      />
                    )}
                    
                    
                  </div>
                </div>

                

                {/*Custom Questionnaire Data*/}

                {serviceQuestions && serviceQuestions.length > 0 ? (
                  <div className="row">
                    {serviceQuestions.map((val, index) => {
                      if (serviceQuestions.length === 1) {
                        if (val.Options.isActive) {
                          return (
                            <div className="col-12">
                              <div className="form-group row">
                                <GetField
                                  val={val}
                                  selectedItem={this.state.selectedRecord ? this.state.selectedRecord : {}}
                                  handleChange={e => {
                                    this.setState({ selectedRecord: e });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                      } else if(val.DisplayName.length > 50){
                        return (
                          <div className="col-12">
                            <div className="form-group row">
                              <GetField
                                val={val}
                                selectedItem={this.state.selectedRecord ? this.state.selectedRecord : {}}
                                handleChange={e => {
                                  this.setState({ selectedRecord: e });
                                }}
                              />
                            </div>
                          </div>
                        );
                      }
                      else if (val.Options.isActive) {
                        if (index % 2 === 0) {
                          return (
                            <div className="col-12 col-lg-6">
                              <div className="form-group row">
                                <GetField
                                  val={val}
                                  selectedItem={this.state.selectedRecord ? this.state.selectedRecord : {}}
                                  handleChange={e => {
                                    this.setState({ selectedRecord: e });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        } else if (index % 2 === 1) {
                          return (
                            <div className="col-12 col-lg-6">
                              <div className="form-group row">
                                <GetField
                                  val={val}
                                  selectedItem={this.state.selectedRecord ? this.state.selectedRecord : {}}
                                  handleChange={e => {
                                    this.setState({ selectedRecord: e });
                                  }}
                                />
                              </div>
                            </div>
                          );
                        }
                      }
                    })}
                  </div>
                ) : (
                  <div className="invisible"></div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    let formValidation = {
                      ServiceType: this.state.selectedRecord && this.state.selectedRecord.ServiceType  ? this.state.selectedRecord.ServiceType : null,
                      ServiceTypeSpecificLabel: this.state.selectedRecord && this.state.selectedRecord.ServiceTypeSpecificLabel ? this.state.selectedRecord.ServiceTypeSpecificLabel : null,
                      EmployeeUID: this.state.selectedRecord && this.state.selectedRecord.EmployeeUID ? this.state.selectedRecord.EmployeeUID : null,
                      ReqNum: this.state.selectedRecord && this.state.selectedRecord.ReqNum ? this.state.selectedRecord.ReqNum : null,
                      LinkedApptsID: this.state.selectedRecord && this.state.selectedRecord.LinkedApptsID ? this.state.selectedRecord.LinkedApptsID : null,
                      EventApptID: this.state.selectedRecord && this.state.selectedRecord.EventApptID ? this.state.selectedRecord.EventApptID : null,
                      AppointmentDate: this.state.selectedRecord && this.state.selectedRecord.AppointmentDate ? this.state.selectedRecord.AppointmentDate : null,
                      AppointmentTime: this.state.selectedRecord && this.state.selectedRecord.AppointmentTime ? this.state.selectedRecord.AppointmentTime : null,
                      ApptCanceled: this.state.selectedRecord && this.state.selectedRecord.ApptCanceled ? this.state.selectedRecord.ApptCanceled : null,
                      CheckedInDate: this.state.selectedRecord && this.state.selectedRecord.CheckedInDate ? this.state.selectedRecord.CheckedInDate : null,
                      FacilityID: this.state.selectedRecord && this.state.selectedRecord.FacilityID ? this.state.selectedRecord.FacilityID : null,
                      ServiceID: this.state.selectedRecord && this.state.selectedRecord.ServiceID ? this.state.selectedRecord.ServiceID : null,
                      CustomFieldData: this.state.selectedRecord && this.state.selectedRecord.CustomFieldData ? this.state.selectedRecord.CustomFieldData : null,
                      CreatedDate: this.state.selectedRecord && this.state.selectedRecord.CreatedDate ? this.state.selectedRecord.CreatedDate : null
                    };

                    let validator = new Validator<any>();
                    validator.withSimpleValidation("ServiceType", Validators.requireNonNullValidator("Service Type"))
                    .withSimpleValidation("ServiceTypeSpecificLabel", Validators.requireNonNullValidator("Service Type Specific Label"))
                    .withSimpleValidation("EmployeeUID", Validators.requireNonNullValidator("Employee UID"))
                    .withSimpleValidation("ReqNum", Validators.requireNonNullValidator("Confirmation Code"))
                    .withSimpleValidation("LinkedApptsID", Validators.requireNonNullValidator("Appointment Code"))
                    .withSimpleValidation("FacilityID", Validators.requireNonNullValidator("Facility ID"))
                    .withSimpleValidation("ServiceID", Validators.requireNonNullValidator("Service ID"))
                    if(this.state.selectedRecord.EventApptID){
                      validator = validator.withSimpleValidation("EventApptID", Validators.requireNonNullValidator("Event Code"))
                      .withSimpleValidation("AppointmentDate", Validators.requireNonNullValidator("Appointment Date"))
                      .withSimpleValidation("AppointmentTime", Validators.requireNonNullValidator("Appointment Time"))
                    }
                    
                    let validationResponse = validator.validate(formValidation);
                    if (!validationResponse.success) {
                      return sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
                    }

                    let invalid = false;

                    if (serviceQuestions.length > 0) {
                      invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedRecord?.CustomFieldData, serviceQuestions, formValidation);
                    }

                    if (invalid) {
                      return;
                    }

                    hideModal(RecordsModal.ID);
                    this.props.onSubmit({
                      ID: this.state.selectedRecord.ID,
                      ServiceType: JSON.stringify(formValidation.ServiceType),
                      ServiceTypeSpecificLabel: JSON.stringify(formValidation.ServiceTypeSpecificLabel),
                      EmployeeUID: formValidation.EmployeeUID,
                      ReqNum: formValidation.ReqNum,
                      LinkedApptsID: formValidation.LinkedApptsID,
                      EventApptID: formValidation.EventApptID,
                      AppointmentDate: formValidation.AppointmentDate,
                      AppointmentTime: formValidation.AppointmentTime,
                      ApptCanceled: formValidation.ApptCanceled,
                      CheckedInDate: formValidation.CheckedInDate,
                      FacilityID: formValidation.FacilityID,
                      ServiceID: formValidation.ServiceID,
                      CustomFieldData: JSON.stringify(formValidation.CustomFieldData),
                      CreatedDate: formValidation.CreatedDate
                    } as RecordType);
                    this.setState({
                      selectedRecord: {
                        ID: null,
                        ServiceType: null,
                        ServiceTypeSpecificLabel: null,
                        EmployeeUID: null,
                        ReqNum: null,
                        LinkedApptsID: null,
                        EventApptID: null,
                        AppointmentDate: null,
                        AppointmentTime: null,
                        ApptCanceled: null,
                        CheckedInDate: null,
                        FacilityID: null,
                        ServiceID: null,
                        CustomFieldData: null,
                        CreatedDate: null
                      } as RecordType,
                    });
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
