import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import VaccineType from "../types/VaccineType";

export default class VaccinesAPI {
    
    public static async filterVaccine(filter):Promise<{success:boolean, data:VaccineType[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/vaccines/filter", filter);
    }

    public static async editVaccine(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/vaccines/edit", body);
    }

    public static async newVaccine(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/vaccines/new", body);
  }
}