import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import Incident, { IncidentRecord } from './../types/Incident';

export default class IncidentsAPI{

    public static async filterIncident(filter):Promise<{success:boolean, data:Incident[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/incidents/filter", filter);
    }

    public static async editIncident(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/incidents/edit", body);
    }

    public static async newIncident(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/incidents/new", body);
    }

    public static async filterIncidentRecords(
      filter, page
    ): Promise<{ success: boolean; data: IncidentRecord[]; reason: string }> {
      return await NetworkUtil.makePost("/api/admin/incidentrecords/filter", filter);
    }
  
    public static async queryIncidentRecord(
      body
    ): Promise<{ success: boolean; record: IncidentRecord }> {
      return await NetworkUtil.makePost("/api/admin/incidentrecords/details", body);
    }
  
    public static async editIncidentRecord(
      body,
    ): Promise<ServerResponse> {
      return NetworkUtil.makePost("/api/admin/incidentrecords/edit", body);
    }
  
    public static async newIncidentRecord(body): Promise<ServerResponse> {
      return await NetworkUtil.makePost("/api/admin/incidentrecords/new", body);
    }

    public static async assignIncidentToEmployee(body): Promise<ServerResponse> {
      return await NetworkUtil.makePost("/api/admin/incidentrecords/assignIncidentToEmployee", body);
    }
}