export interface CustomFieldType{
    ID:number
    DisplayName:string
    FieldTypeID:FieldID
    ServiceID:number
    IsEmployeeField:number
    Order:number
    Options:FieldOptions
}
export type FieldID = number;

export class CustomFields {
    public static TEXT_FIELD:FieldID = 1 as const;
    public static SELECT_FIELD:FieldID = 2 as const;
    public static DATE_FIELD:FieldID = 3 as const;
    public static TEXTAREA_FIELD:FieldID = 4 as const;
    public static NUMBER_FIELD:FieldID = 5 as const;
    public static TIME_FIELD:FieldID = 6 as const;
    public static DATETIME_FIELD:FieldID = 7 as const;
    public static PHONE_FIELD:FieldID = 8 as const;
    public static EMAIL_FIELD:FieldID = 9 as const;
}

export const CustomFieldTypesObj = {
    'Text Box': 1,
    'Select': 2,
    'Date': 3,
    'Text Area': 4,
    'Number': 5,
    // 'Time': 6,
    // 'DateTime': 7,
    'Phone': 8,
    'Email': 9
}

export const CustomFieldTypesPages = {
    'Vaccine': 'Vaccine',
    'Evaluation': 'Evaluation',
    'Test': 'Test',
    'Facility': 'Facility',
    'Result': 'Result',
    'Events': 'Events',
    'Task': 'Task',
    'Incident': 'Incident',
    'Claim': 'Claim',
    'Task Records': 'Task Records',
    'Incident Records': 'Incident Records',
    'Claim Records': 'Claim Records',
}

export interface FieldOptions{
    validPages:string[];
    isRequired:boolean;
    isActive:boolean;
    options:string[];
    isMulti:boolean;
    isBoolean:boolean;
    isNumeric:boolean;
    popoverText:string;
    maxLength:number;
    min:number;
    max:number;
    allowFutureDate:boolean;
    serviceType:number;
}


export const standardEmployeeFields = [
    'First Name',
    'Middle Name',
    'Last Name',
    'Date of Birth',
    'Email',
    'Ethnicity',
    'Race',
    'Gender',
    'Phone',
    'Address',
    'City',
    'State',
    'Zipcode',
    'County',
    'Country',
    'Height',
    'Weight',
]