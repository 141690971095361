import NetworkUtil from "./NetworkUtil";
import {ServerResponse} from "../types/ServerResponse";
import Claim, { ClaimRecord } from './../types/Claim';

export default class ClaimsAPI{

    public static async filterClaim(filter):Promise<{success:boolean, data:Claim[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/claims/filter", filter);
    }

    public static async editClaim(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/claims/edit", body);
    }

    public static async newClaim(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/claims/new", body);
    }

    public static async filterClaimRecords(
      filter, page
    ): Promise<{ success: boolean; data: ClaimRecord[]; reason: string }> {
      return await NetworkUtil.makePost("/api/admin/claimrecords/filter", filter);
    }
  
    public static async queryClaimRecord(
      body
    ): Promise<{ success: boolean; record: ClaimRecord }> {
      return await NetworkUtil.makePost("/api/admin/claimrecords/details", body);
    }
  
    public static async editClaimRecord(
      body,
    ): Promise<ServerResponse> {
      return NetworkUtil.makePost("/api/admin/claimrecords/edit", body);
    }
  
    public static async newClaimRecord(body): Promise<ServerResponse> {
      return await NetworkUtil.makePost("/api/admin/claimrecords/new", body);
    }

    public static async assignClaimToEmployee(body): Promise<ServerResponse> {
      return await NetworkUtil.makePost("/api/admin/claimrecords/assignClaimToEmployee", body);
    }
}