import NetworkUtil from "./NetworkUtil";
import TestType from "../types/TestType"
import {ServerResponse} from "../types/ServerResponse";

export default class TestsAPI {

    public static async getAllTestDetails():Promise<{success:boolean, reason?:string, tests?:TestType[]}>{
        return NetworkUtil.makeGet("/api/admin/tests/details");
    }

    public static async getValidResultTypes():Promise<{label:string, value:number}[]>{
        return NetworkUtil.makeGet("/api/admin/validResultTypes");
    }

    public static async newTest(body):Promise<ServerResponse>{
        return await NetworkUtil.makePost("/api/admin/tests/new", body);
    }
    
    public static async editTest(body):Promise<ServerResponse>{
        return await NetworkUtil.makePost("/api/admin/tests/edit", body);
    }
    
    public static async filterTest(filter):Promise<{success:boolean, data:TestType[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/tests/filter", filter);
    }

}