import React from 'react';
import 'react-quill/dist/quill.snow.css';
import Select from 'react-select';
import Overlay from '../Overlay';
import {
  getBoolSelectOptions,
  getBoolSelectVal,
  getNumPages,
  getPageOptions,
  getServiceTypeLabel,
  getSpecificServiceType,
  hideModal,
  parseDate,
  showModalNoOutsideClick,
  slicePages,
} from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import TestsAPI from '../../network/TestsAPI';
import SystemAPI from '../../network/SystemAPI';
import FilterCard, { FIELD_TYPE, ReactSelect } from '../FilterCard';
import NetworkUtil from '../../network/NetworkUtil';
import { Column } from '../tables/TableBase';
import { Sorter, TableOrder } from '../../sorting/Sorter';
import SimpleTable from '../tables/SimpleTable';
import InBetweenOverlay from '../InBetweenOverlay';
import { TaskRecord } from '../../types/Task';
import TasksAPI from '../../network/TasksAPI';
import CustomDate from '../form/CustomDate';
import EmployeeAPI from '../../network/EmployeeAPI';
import EventAPI from '../../network/EventAPI';
import { EventSchedForSelect } from '../../types/Event';
import { FacilityAPI } from '../../network/FacilityAPI';
import ServicesAPI from '../../network/ServicesAPI';
import { TaskRecordModal } from '../modals/TaskRecordModal';
import CustomFieldsAPI from '../../network/CustomFieldsAPI';
import { AddRecordToEmployeeModal } from '../modals/AddRecordToEmployeeModal';
import { AdminPages } from './AdminNav';

const ITEMS_PER_PAGE = 25;

interface TaskRecordsState {
  selectedTask?;
  taskRecords: TaskRecord[];
  showLoading?;
  showInBetween?;
  filter?: {
    EmployeeUID: null;
    ReqNum: null;
    // FacilityID: null;
    TaskID: null;
  };
  tableData: {}[];
  direction?: TableOrder;
  page_options: ReactSelect[];
  selected_page?: { label; value };
  tasksQueryResults: TaskRecord[];
  selectedEmployeeLabel?;
  facilities?;
  employees?;
  pageCustomQuestions?;
  tasks?;
}

export default class TaskRecords extends React.Component<any, TaskRecordsState> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showInBetween: false,
      selectedTask: {} as TaskRecord,
      taskRecords: [],
      facilities: [],
      employees: [],
      tasks: [],
      pageCustomQuestions: [],
      filter: {
        EmployeeUID: null,
        ReqNum: null,
        // FacilityID: null,
        TaskID: null,
      },
      tableData: [] as any,
      direction: 'asc',
      page_options: [{ label: '1', value: '1' }],
      selected_page: { label: '1', value: 1 },
      tasksQueryResults: [] as any,
    };
    this.editTask = this.editTask.bind(this);
  }

  componentDidMount() {
    document.title = 'TaskRecords';
    this.setState({ showLoading: true }, () => {
      SystemAPI.getCustomQuestions(AdminPages.TaskRecords).then(res => {
        this.setState({ pageCustomQuestions: res.questions });
      });
      EmployeeAPI.getEmployeesFromEmployeePortal().then(data => {
        this.setState({ employees: data.employees });
      });
      SystemAPI.getAllTasks().then(data => {
        this.setState({ tasks: data });
      });
      FacilityAPI.getFacilitiesForSubmissionForm().then(data => {
        this.setState({ facilities: data.authorizedFacilities });
      });
    });
    this.setState({ showLoading: false });
  }

  queryTasks(page: number) {
    this.setState({ showLoading: true }, async () => {
      try {
        let results = await TasksAPI.filterTaskRecords(
          {
            EmployeeUID: this.state.selectedEmployeeLabel && this.state.selectedEmployeeLabel.value ? this.state.selectedEmployeeLabel.value : null,
            ReqNum: this.state.filter && this.state.filter.ReqNum ? this.state.filter.ReqNum : null,
            // FacilityID: this.state.filter && this.state.filter.FacilityID ? this.state.filter.FacilityID : null,
            TaskID: this.state.filter && this.state.filter.TaskID ? this.state.filter.TaskID : null,
          },
          page
        );

        if (results.data.length < 1) {
          this.setState({ showLoading: false, tableData: [], tasksQueryResults: null });
          return sweetalert.fire({ title: '', text: 'No Tasks returned', icon: 'info' });
        }

        if (!results.success) {
          return sweetalert.fire({ title: '', text: 'Unable to filter Task data at this time', icon: 'error' });
        }
        this.setState({
          tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
          tasksQueryResults: results.data,
          page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
          showLoading: false,
        });
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
      }
    });
  }

  editTask(task: TaskRecord) {
    this.setState({ showLoading: true }, async () => {
      try {
        let result = await TasksAPI.editTaskRecord(task);
        if (result.success) {
          sweetalert.fire({ icon: 'success', title: '', text: 'Task updated' }).then(() => {
            this.setState(
              {
                selectedTask: null,
              },
              () => {
                this.queryTasks(this.state.selected_page.value);
              }
            );
          });
        } else {
          sweetalert.fire({ icon: 'error', title: '', text: result.reason });
          this.setState({ showLoading: false });
        }
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
      }
    });
  }

  handleExportToCSV() {
    let combinedFilter = {
      EmployeeUID: this.state.selectedEmployeeLabel && this.state.selectedEmployeeLabel.value ? this.state.selectedEmployeeLabel.value : null,
      ReqNum: this.state.filter && this.state.filter.ReqNum ? this.state.filter.ReqNum : null,
      // FacilityID: this.state.filter && this.state.filter.FacilityID ? this.state.filter.FacilityID : null,
      TaskID: this.state.filter && this.state.filter.TaskID ? this.state.filter.TaskID : null,
    };
    this.setState({ showLoading: true }, async () => {
      await NetworkUtil.downloadCSV('/api/admin/taskrecords/csv', 'TaskRecords.xlsx', { filter: combinedFilter });
      this.setState({ showLoading: false });
    });
  }

  useSorter(col: Column) {
    let sorter = new Sorter<any>();

    this.setState({ tableData: sorter.sortByKey(this.state.tableData, col.key as keyof any, this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc' });
  }

  changePage(page: number) {
    let allTasks = this.state.tasksQueryResults;
    let returnData = slicePages(allTasks, page, ITEMS_PER_PAGE);
    this.setState({ tableData: returnData });
  }

  handleDateOnChange(value, state) {
    if (value) {
      this.setState(prevState => ({
        filter: {
          ...prevState.filter,
          [state]: value,
        },
      }));
    } else {
      this.setState(prevState => ({
        filter: {
          ...prevState.filter,
          [state]: null,
        },
      }));
    }
  }

  assignTaskToEmployee(obj) {
    this.setState({ showLoading: true }, async () => {
      obj.TaskID = obj.RecordID;
      delete obj.RecordID;
      console.log('obj', obj);
      try {
        let result = await TasksAPI.assignTaskToEmployee(obj);
        if (result.success) {
          sweetalert.fire({ icon: 'success', title: '', text: 'Task assigned successfully.' })
            .then(() => this.queryTasks(this.state.selected_page.value));
        } else {
          sweetalert.fire({ icon: 'error', title: '', text: result.reason });
          this.setState({ showLoading: false });
        }
      } catch (e) {
        console.error(e);
        this.setState({ showLoading: false });
        return sweetalert.fire({icon: 'error', title: '', text: 'Error assigning Task to employee'})
      }
    });
  }

  render() {
    // console.log('Tasks state', this.state)

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        <InBetweenOverlay showInBetween={this.state.showInBetween} />
        <TaskRecordModal
          selectedTask={this.state.selectedTask}
          employees={this.state.employees}
          tasks={this.state.tasks}
          pageCustomQuestions={this.state.pageCustomQuestions}
          onSubmit={(task: TaskRecord) => {
            this.editTask(task);
          }}
          facilities={
            this.state.facilities && this.state.facilities.length > 0
              ? this.state.facilities.map(f => {
                  return { label: f.FacilityName, value: f.ID };
                })
              : []
          }
        />
        <AddRecordToEmployeeModal
          pageCustomQuestions={this.state.pageCustomQuestions}
          options={this.state.tasks}
          employees={this.state.employees}
          recordType={'Task'}
          onSubmit={(obj) => {
            this.assignTaskToEmployee(obj);
          }}
        />
        <div className="container-fluid ">
          <div className={'row'}>
            <div className="col-12 col-lg-8 col-xl-5 pt-2">
              <main id="main-content" tabIndex={-1} aria-label="Tasks">
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    <h4>Task Records</h4>
                  </div>
                  <div className="card-body">
                    <div className={'form-group row'} data-toggle={'tooltip'} data-placement={'top'} title={'EmployeeSearch'}>
                      <label htmlFor={'EmployeeSearch'} className={'col-sm-4 col-form-label'}>
                        {'Employee'}
                      </label>
                      <div className={'col-8 p-0 m-0'} id={'EmployeeSearch'}>
                        <Select
                          name="EmployeeSearch"
                          isSearchable={true}
                          isClearable={true}
                          placeholder={<div className="accessibilityText">Please Select...</div>}
                          noOptionsMessage={() => 'No option'}
                          className={'state_select'}
                          options={
                            this.state.employees && this.state.employees.length > 0
                              ? this.state.employees.map(p => {
                                  return {
                                    label: `${p.FirstName} ${p.LastName} - ${parseDate(p.DateOfBirth)} - ${p.UID}`,
                                    value: p.UID,
                                  };
                                })
                              : []
                          }
                          onChange={e =>
                            this.setState({
                              selectedEmployeeLabel: e,
                            })
                          }
                          value={this.state.selectedEmployeeLabel ? this.state.selectedEmployeeLabel : null}
                          aria-label="EmployeeSearch"
                        />
                      </div>
                    </div>
                    <FilterCard
                      fields={[
                        {
                          label: 'Confirmation Code',
                          key: 'ReqNum',
                          type: FIELD_TYPE.TEXT,
                          options: [],
                          isMapped: true,
                          onInput: e => (e.target.value = e.target.value.toUpperCase()),
                        },
                        // {
                        //   label: 'Facility',
                        //   key: 'FacilityID',
                        //   type: FIELD_TYPE.SELECT,
                        //   options:
                        //     this.state.facilities && this.state.facilities.length > 0
                        //       ? this.state.facilities.map(f => {
                        //           return { label: f.FacilityName, value: f.ID };
                        //         })
                        //       : [],
                        //   isMapped: true,
                        //   isMulti: true,
                        //   textType: 'text',
                        //   isFilter: true,
                        // },
                        {
                          label: 'Task',
                          key: 'TaskID',
                          type: FIELD_TYPE.SELECT,
                          options:
                            this.state.tasks && this.state.tasks.length > 0
                              ? this.state.tasks
                              : [],
                          isMapped: true,
                          isMulti: true,
                          textType: 'text',
                          isFilter: true,
                        },
                      ]}
                      filterChanged={e => this.setState({ filter: e })}
                    />
                  </div>
                  <div className="card-footer">
                    <button
                      className={'btn immySubmitButtonOutline'}
                      onClick={() => {
                        this.setState(
                          {
                            selected_page: { label: 1, value: 1 },
                          },
                          () => {
                            this.queryTasks(this.state.selected_page.value);
                          }
                        );
                      }}
                    >
                      Search
                    </button>
                    <button
                    className="btn btn-outline-success float-right"
                    onClick={() => showModalNoOutsideClick(AddRecordToEmployeeModal.ID)}
                  >
                    Assign Task to Employee
                  </button>
                  </div>
                </div>
              </main>
            </div>

            {this.state.tableData && this.state.tableData.length > 0 && (
              <div className="col-md-12 pt-2">
                <div className="card mt-2 mb-5">
                  <div className="card-header verlag-bold" style={{ zIndex: 0 }}>
                    <h4 className="text-center text-md-left">
                      Task Records
                      <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                        <h4 className={'float-md-right'} aria-label="Total Tasks" role="alert">
                          Total: {this.state.tasksQueryResults.length}
                        </h4>
                        <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                        <div className=" align-middle float-right pages ">
                          <Select
                            isSearchable={true}
                            placeholder={'1'}
                            noOptionsMessage={() => 'No option'}
                            value={this.state.selected_page}
                            aria-label="Table Page Number"
                            onChange={(e: ReactSelect) => this.setState({ selected_page: e }, () => this.changePage(e.value))}
                            className={'state_select'}
                            options={this.state.page_options}
                          />
                        </div>
                      </section>
                      <button className={'d-none d-md-inline btn btn-outline-primary ml-3'} onClick={() => this.handleExportToCSV()}>
                        Export to CSV
                      </button>
                    </h4>
                  </div>
                  <div className="card-body p-0 m-0 table-responsive">
                    <SimpleTable
                      table_style="tableFixHead"
                      columns={[
                        {
                          label: 'Name',
                          key: 'Name',
                          rawFormat: v => {
                            let employeeName = this.state.employees && this.state.employees.length > 0 ? this.state.employees.find(e => e.UID === v.EmployeeUID).DisplayName : '';
                            return (
                              <a
                                href={'#!'}
                                className={'tableNameLinkColor'}
                                onClick={() => {
                                  this.setState({ selectedTask: v }, () => {
                                    showModalNoOutsideClick(TaskRecordModal.ID);
                                  });
                                }}
                              >
                                {employeeName}
                              </a>
                            );
                          },
                        },
                        {
                          label: 'Confirmation Code',
                          key: 'ReqNum',
                        },
                        // {
                        //   label: 'Facility',
                        //   key: 'FacilityID',
                        //   rawFormat: v => {
                        //     let facilityName = this.state.facilities && this.state.facilities.length > 0 ? this.state.facilities.find(f => f.ID === v.FacilityID).FacilityName : '';
                        //     return <p>{facilityName}</p>;
                        //   },
                        // },
                        {
                          label: 'Task',
                          key: 'TaskID',
                          rawFormat: v => {
                            let taskName = this.state.tasks && this.state.tasks.length > 0 ? this.state.tasks.find(f => f.value === v.TaskID) : '';
                            return <p>{taskName.label}</p>;
                          },
                        },
                      ]}
                      table_data={this.state.tableData}
                      columnClickedCallback={col => {
                        this.useSorter(col);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
