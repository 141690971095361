import React from "react";
import Select from "react-select";
import Overlay from "../Overlay";
import {
    getNumPages, getPageOptions, hideModal,
    parseDate,
    showModalNoOutsideClick,
    slicePages,
    getSelectLabels,
    prepareMultiSelect,
    getNameFromID,
    toLocalDateTime,
    buildRow,
    isEighteenOrOlder,
} from "../../util/FormatUtil";
import {sweetalert} from '../../App';
import { isEmptyObject } from "jquery";
import { Sample } from "../../types/Sample";
import SystemAPI from "../../network/SystemAPI";
import AdminAPI from "../../network/AdminAPI";
import moment from "moment";
import {AdminPages} from "./AdminNav";
import FilterCard, {FIELD_TYPE, ReactSelect} from "../FilterCard";
import NetworkUtil from "../../network/NetworkUtil";
import {Column} from "../tables/TableBase";
import {Sorter, TableOrder} from "../../sorting/Sorter";
import SimpleTable from "../tables/SimpleTable";
import InBetweenOverlay from "../InBetweenOverlay";
import Employee from "../../types/Employee";
import EmployeeAPI from "../../network/EmployeeAPI";
import EmployeeInfoModal from "../modals/EmployeeInfoModal";
import CustomFieldsAPI from "../../network/CustomFieldsAPI";
import SamplesAPI from "../../network/SamplesAPI";
import { EventSchedForSelect } from "../../types/Event";
import {CustomFields} from "../../types/CustomFieldType";
import ServicesAPI from "../../network/ServicesAPI";
import ViewTestInfoModal from "../modals/ViewTestInfoModal";
import { standardEmployeeFields } from "../../types/CustomFieldType";
import EmployeeRecordModal from "../modals/EmployeeRecordModal";
import PaginationTool from "../PaginationTool";
import Task from "../../types/Task";
import EventAPI from "../../network/EventAPI";
import { FacilityAPI } from "../../network/FacilityAPI";

const ITEMS_PER_PAGE = 25;

interface EmployeeRecordState {
    employeeInformation?;
    employees: Employee[]
    showLoading?
    showInBetween?
    filter?:{
        facility?
        testDate?
        test?
        reqNum?
        service?
        ResultIDs?
    }
    testsTableData?
    evaluationsTableData?
    vaccinesTableData?
    direction?:TableOrder
    page_options:ReactSelect[]
    selected_page?:{label,value}
    employeeQueryResults:Employee[]
    race
    gender
    ethnicity
    states
    countries
    facilities
    customEmployeeFields?
    tests
    testQueryResults?
    evaluationQueryResults?
    vaccineQueryResults?
    services?
    getResultsButtonEnabled?
    selectedSample?
    employee?
    isManagement?
    isITG?
    testDetails?
    resultTypes?
    validResultTypes?
    facilityGroups?
    facilitiesForSampleModal?
    eventsForModal?
    customFields?
    customQuestions?
    employeeInfo?
    searched?
    totalSamples?
    isTestsTabActive?
    isEvaluationsTabActive?
    isVaccinesTabActive?
    injectionSites?
    vaccines?
    vaccinesForLabels?
    selectedEvaluation?
    EvaluationModalOpen?
    selectedRecord?
    VaccineModalOpen?
    evaluations?
    tasks?:Task[]
}

export default class EmployeeRecord extends React.Component<any, EmployeeRecordState> {
    private employeeInfoRef: any;
    private componentRef: any;
    constructor(props) {
        super(props);
        this.state = {
            showLoading: false,
            showInBetween: false,
            employees: [],
            employeeInformation: {} as Employee,
            filter: {
              facility:null,
              testDate:null,
              test:null,
              reqNum: null,
              service: null
            },
            testsTableData: [] as any,
            evaluationsTableData: [] as any,
            vaccinesTableData: [] as any,
            direction: 'asc',
            page_options: [{label: "1", value: "1"}],
            selected_page: {label: "1", value: 1},
            employeeQueryResults: [] as any,
            race: [],
            gender: [],
            ethnicity: [],
            states: [],
            countries: [],
            facilities: [],
            customEmployeeFields: [],
            tests: [],
            services: [],
            isTestsTabActive: false,
            isEvaluationsTabActive: true,
            isVaccinesTabActive: false,
            tasks: []

        }
        this.employeeInfoRef = React.createRef();
    }

    getFilteredObj(){
      return {
        EmployeeUID: this.props.user,
        EventID: null,
        Facilities: this.state.filter?.facility ? this.state.filter.facility : null,
        FacilityGroup: null,
        EmployeeFirstName: null,
        EmployeeMiddleName: null,
        EmployeeLastName: null,
        EmployeeDOB: null,
        NullEmployeeDOB: null,
        EmployeeEthnicityID: null,
        EmployeeRaceID: null,
        EmployeeGenderID: null,
        EmployeeEmail: null,    
        EmployeePhone: null,
        NullEmployeePhone: null,
        EmployeeCity: null,
        EmployeeZip: null,
        EmployeeState: null,
        EmployeeCounty: null,
        EmployeeCountry: null,
        LinkedApptsID: null,
        ReqNum: this.state.filter?.reqNum ? this.state.filter?.reqNum : null,
        SpecimenID: null,
        InternalSpecimenID: null,
        BinID: null,
        Lot: null,    
        BeginCreatedDate: null,
        EndCreatedDate: null,
        NullCreatedDate: null,    
        BeginCollectionDate: this.state.filter.testDate ? this.state.filter.testDate : null,
        EndCollectionDate: this.state.filter.testDate ? this.state.filter.testDate : null,
        NullCollectionDate: null,    
        BeginReceivedDate: null,
        EndReceivedDate: null,
        NullReceivedDate: null,    
        BeginResultDate: null,
        EndResultDate: null,          
        NullResultDate: null,    
        BeginResultsApprovedDate: null,
        EndResultsApprovedDate: null,
        NullResultsApprovedDate: null,
        BeginResultsAccessedDate: null,
        EndResultsAccessedDate: null,
        NullResultsAccessedDate: null,    
        BeginReportedDate: null,
        EndReportedDate: null,
        NullReportedDate: null,    
        ResultIDs: this.state.filter.ResultIDs ? this.state.filter.ResultIDs : null,
        TestIDs: this.state.filter?.test ? prepareMultiSelect(this.state.filter.test) : null,
        ServiceID: this.state.filter?.service ? this.state.filter.service : null,    
        //passing empty array if greater than 1 because if both 1 and null are selected then that is all the records.  same for ApptCanceled
        RerunFlag: null,
        ApptCanceled: null,
        ProviderFirstName: null,
        ProviderLastName: null,
      };
    }


    async componentDidMount() {
        document.title = 'Employee Management Page';
        this.setState({showLoading: true}, () => {
            AdminAPI.getPermissions().then((data) => {
              this.setState({
                isITG: data.isITG,
                isManagement: data.isManagement,
              });
            });
            SystemAPI.getAllRaces().then((data) => {
                this.setState({ race: data });
            });
            SystemAPI.getAllGenders().then((data) => {
                this.setState({ gender: data });
            });
            SystemAPI.getAllEthnicities().then((data) => {
                this.setState({ ethnicity: data });
            });
            
            SystemAPI.getAllStates().then((data) => {
                this.setState({ states: data });
            });
            // TestsAPI.getAllTestDetails().then((data) => {
            //   this.setState({ testDetails: data.tests });
            // });
            // SystemAPI.getAllTests().then((data) => {
            //     this.setState({ tests: data });
            // });
            FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
                this.setState({facilities: data.authorizedFacilities});
            });
            ServicesAPI.getAllServices().then((data) => {
              this.setState({services: data.data})
            })
            CustomFieldsAPI.getAllCustomFields().then((data) => {
              this.setState({
                customFields: data.data.customFields,
                customQuestions: data.data.customQuestions,
                customEmployeeFields: data.data.customEmployeeFields.sort((a,b) => a.Order - b.Order)
              });
            });
            EventAPI.getAllEvents(true).then((response) => {
              const today = moment().startOf('day');
              let events = response.data as {label: string; value: EventSchedForSelect}[];
      
              const beforeToday = events.filter((events)=>{
                let aDate = moment(events.value.TestDate, 'YYYY-MM-DD').startOf('day');
                return aDate.isBefore(today)
              }).reverse();
      
              const afterToday = events.filter((events)=>{
                let bDate = moment(events.value.TestDate, 'YYYY-MM-DD').startOf('day');
                return bDate.isSameOrAfter(today)
              })
              let groupedEvents = [
                { label: "Upcoming", options: afterToday},
                { label: "Past", options: beforeToday}
              ]
              this.setState({ eventsForModal: groupedEvents });
            });
            FacilityAPI.getFacilitiesForSubmissionForm().then((data) => {
              this.setState({facilities: data.authorizedFacilities});
            });
            SystemAPI.getAllCountries().then((data) => {
                this.setState({ countries: data});
            });
            CustomFieldsAPI.getAllCustomFields().then(data =>{
                this.setState({
                    customEmployeeFields: data.data.customEmployeeFields,
                  });
            })
            SystemAPI.getAllVaccines().then(data => {
              this.setState({vaccines: data, vaccinesForLabels: data})
            })
            EmployeeAPI.filterEmployees({uid: this.props.user}).then(async data => {
              this.setState({employee: data.data[0]}, () => {
                EmployeeAPI.getEmployeesFromEmployeePortal().then(data => {
                  this.setState({employees: data.employees}, () => {
                    if(!isEmptyObject(this.state.employee) && this.props.fromEmployeeMgmt){
                      this.updateRows({label: this.state.employee, value: this.state.employee.id})

                      // Loading all fake tasks for demo
                      // this.setState({tasks: FakeTasks})
                     } 
                  })
                })
                
              })
            })
            // TestsAPI.getValidResultTypes().then((data) => {
            //   this.setState({ validResultTypes: data });
            // });
            AdminAPI.getAllResultTypes().then((data) => {
              this.setState({ resultTypes: this.addNullSelection(data) });
            });

            SystemAPI.getAllEvaluations().then(data => {
              this.setState({evaluations: this.addNullSelection(data)})
            })
            SamplesAPI.getFilteredSamples(1, {filter: this.getFilteredObj()}).then(data =>{
              this.setState({
                testsTableData: slicePages(data.table_data, 1, ITEMS_PER_PAGE),
                searched: this.getFilteredObj(),
                testQueryResults: data,
                page_options: getPageOptions(getNumPages(data.table_data, ITEMS_PER_PAGE)),
              })
            })
            
            // this.queryEmployees(1)
        })
    }

    // componentWillReceiveProps(nextProps: Readonly<any>, nextContext: any): void {
    //   if(!isEmptyObject(nextProps.selectedEmployee) && nextProps.fromEmployeeMgmt){
    //     this.updateRows({label: nextProps.selectedEmployee, value: nextProps.selectedEmployee.id})
    //   } 
    // }

    // async componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<EmployeeRecordState>, snapshot?: any){
    //   if(!isEmptyObject(this.state.employee) && this.props.fromEmployeeMgmt && prevState.employees != this.state.employees){
    //     await this.updateRows({label: this.state.employee, value: this.state.employee.id})
    //    } 
    // }

    // queryEmployees(page:number) {
    //     this.setState({showLoading:true}, async () => {
    //         try {
    //             let results = await EmployeeAPI.filterEmployees(this.state.filter);
    //             if (results.data.length === 0) {
    //                 sweetalert.fire({
    //                   icon: "info",
    //                   title: "",
    //                   text: "No employees were returned",
    //                 });
    //               }

    //             if(!results.success){
    //                 return sweetalert.fire({title: '', text: 'Unable to filter Employee data at this time', icon: 'error'})
    //             }
    //             this.setState({
    //                 tableData: slicePages(results.data, page, ITEMS_PER_PAGE),
    //                 employeeQueryResults: results.data,
    //                 page_options: getPageOptions(getNumPages(results.data, ITEMS_PER_PAGE)),
    //                 showLoading: false,
    //                 employees: results.data
    //             })
    //         } catch (e) {
    //             console.log(e)
    //             this.setState({showLoading: false})
    //         }
    //     })
    // }

    addNullSelection(data) {
      return data.concat([
        { label: "No Data", value: "*null", color: '#C31230', fontWeight: 'bold' },
        { label: "Has Data", value: "<>null", color: '#21212E', fontWeight: 'bold' },
      ]);
    }

    handleActiveTab = (tab) => {
      switch (tab) {
          case 'tests':
            this.setState({isTestsTabActive: true, isEvaluationsTabActive: false, isVaccinesTabActive: false})
          break;
          case 'evaluations':
            this.setState({isTestsTabActive: false, isEvaluationsTabActive: true, isVaccinesTabActive: false})
          break;
          case 'vaccines':
            this.setState({isTestsTabActive: false, isEvaluationsTabActive: false, isVaccinesTabActive: true})
          break;
          default:
              break;
      }
  }
  

    modifyEmployee(employee){
        if(!employee || JSON.stringify(employee) === '{}' || !employee.employeeInfo || JSON.stringify(employee.employeeInfo) === '{}' || !employee.employeeInfo.CustomFieldData || JSON.stringify(employee.employeeInfo.CustomFieldData) === '{}'){
            return sweetalert.fire({icon: 'error', title: '', text: `employee Information must be complete`})
        }

        let employeeInformationCopy = JSON.parse(JSON.stringify(employee.employeeInfo)); 

        employeeInformationCopy.uid = employeeInformationCopy.CustomFieldData.uid ? employeeInformationCopy.CustomFieldData.uid : null;
        employeeInformationCopy.firstName = employeeInformationCopy.CustomFieldData['First Name'] ? employeeInformationCopy.CustomFieldData['First Name'] : null;
        employeeInformationCopy.middleName = employeeInformationCopy.CustomFieldData['Middle Name'] ? employeeInformationCopy.CustomFieldData['Middle Name'] : null;
        employeeInformationCopy.lastName = employeeInformationCopy.CustomFieldData['Last Name'] ? employeeInformationCopy.CustomFieldData['Last Name'] : null;
        employeeInformationCopy.email = employeeInformationCopy.CustomFieldData.Email ? employeeInformationCopy.CustomFieldData.Email : null;
        employeeInformationCopy.phoneNumber = employeeInformationCopy.CustomFieldData.Phone ? employeeInformationCopy.CustomFieldData.Phone : null;
        employeeInformationCopy.dateOfBirth = employeeInformationCopy.CustomFieldData['Date of Birth'] ? employeeInformationCopy.CustomFieldData['Date of Birth'] : null;
        employeeInformationCopy.streetAddress = employeeInformationCopy.CustomFieldData.Address ? employeeInformationCopy.CustomFieldData.Address : null;
        employeeInformationCopy.streetAddress2 = employeeInformationCopy.CustomFieldData['Address Cont.'] ? employeeInformationCopy.CustomFieldData['Address Cont.'] : null;
        employeeInformationCopy.city = employeeInformationCopy.CustomFieldData.City ? employeeInformationCopy.CustomFieldData.City : null;
        employeeInformationCopy.state = employeeInformationCopy.CustomFieldData.State ? this.state.states.find(f => f.label === employeeInformationCopy.CustomFieldData.State).value : null;
        employeeInformationCopy.county = employeeInformationCopy.CustomFieldData.County ? employeeInformationCopy.CustomFieldData.County : null;
        employeeInformationCopy.zipcode = employeeInformationCopy.CustomFieldData.Zipcode ? employeeInformationCopy.CustomFieldData.Zipcode : null;
        employeeInformationCopy.country = employeeInformationCopy.CustomFieldData.Country ? this.state.countries.find(f => f.label === employeeInformationCopy.CustomFieldData.Country).value : null;
        employeeInformationCopy.genderID = employeeInformationCopy.CustomFieldData.Gender ? this.state.gender.find(f => f.label === employeeInformationCopy.CustomFieldData.Gender).value : null;
        employeeInformationCopy.ethnicityID = employeeInformationCopy.CustomFieldData.Ethnicity ? this.state.ethnicity.find(f => f.label === employeeInformationCopy.CustomFieldData.Ethnicity).value : null;
        employeeInformationCopy.raceID = employeeInformationCopy.CustomFieldData.Race ? this.state.race.find(f => f.label === employeeInformationCopy.CustomFieldData.Race).value : null;
        employeeInformationCopy.guardianFirstName = employeeInformationCopy.CustomFieldData['Guardian First Name'] ? employeeInformationCopy.CustomFieldData['Guardian First Name'] : null;
        employeeInformationCopy.guardianLastName = employeeInformationCopy.CustomFieldData['Guardian Last Name'] ? employeeInformationCopy.CustomFieldData['Guardian Last Name'] : null;
        employeeInformationCopy.FacilityIDs = employeeInformationCopy.CustomFieldData.FacilityIDs ? employeeInformationCopy.CustomFieldData.FacilityIDs : null;

        delete employeeInformationCopy.CustomFieldData.Address;
        delete employeeInformationCopy.CustomFieldData.City;
        delete employeeInformationCopy.CustomFieldData.Country;
        delete employeeInformationCopy.CustomFieldData.County;
        delete employeeInformationCopy.CustomFieldData['Date of Birth'];
        delete employeeInformationCopy.CustomFieldData.Email;
        delete employeeInformationCopy.CustomFieldData.Ethnicity;
        delete employeeInformationCopy.CustomFieldData['First Name'];
        delete employeeInformationCopy.CustomFieldData.Gender;
        delete employeeInformationCopy.CustomFieldData['Last Name'];
        delete employeeInformationCopy.CustomFieldData['Middle Name'];
        delete employeeInformationCopy.CustomFieldData.Phone;
        delete employeeInformationCopy.CustomFieldData.Race;
        delete employeeInformationCopy.CustomFieldData.State;
        delete employeeInformationCopy.CustomFieldData.Zipcode;
        delete employeeInformationCopy.CustomFieldData.uid;
        delete employeeInformationCopy.CustomFieldData['Guardian First Name'];
        delete employeeInformationCopy.CustomFieldData['Guardian Last Name'];
        delete employeeInformationCopy.CustomFieldData.FacilityIDs;
      
        this.setState({showLoading:true}, async() => {
            try{
                let result = await EmployeeAPI.editEmployee(employeeInformationCopy)
                if(result.success){
                    sweetalert.fire({icon: 'success', title: '', text: 'Employee saved'}).then(()=>{
                        window.location.reload()
                    });
                }else{
                    sweetalert.fire({icon: 'error', title: '', text: result.reason});
                    this.setState({showLoading:false})
                }
            }
            catch (e) {
                console.error(e)
                this.setState({showLoading:false})
            }
        })
    }

    handleExportToCSV(){
      let filter = this.state.searched;
      let modifiedfilter = Object.assign(filter);
        this.setState({showLoading: true}, async () =>{
            await NetworkUtil.downloadCSV(
                "/api/admin/samples/results/csv",
                "Sample Results.xlsx",
                {filter: modifiedfilter}
            )
            this.setState({showLoading: false})
        });
    }

    useSorter(col:Column, tableName){
        let sorter = new Sorter<any>()
        if(tableName === "tests"){
          this.setState({testsTableData: sorter.sortByKey(this.state.testsTableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
        } else if(tableName === "evaluations"){
          this.setState({evaluationsTableData: sorter.sortByKey(this.state.evaluationsTableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
        } else if(tableName === "vaccines"){
          this.setState({vaccinesTableData: sorter.sortByKey(this.state.vaccinesTableData, col.key as keyof any,this.state.direction), direction: this.state.direction === 'asc' ? 'desc' : 'asc'});
        }
    }

    changePage(page:number, tableName){
      if(tableName === "tests"){
        let allRecords = this.state.testQueryResults. data;
        let returnData = slicePages(allRecords, page, ITEMS_PER_PAGE);
        this.setState({ testsTableData: returnData });
      } else if(tableName === "evaluations"){
        let allRecords = this.state.evaluationQueryResults;
        let returnData = slicePages(allRecords, page, ITEMS_PER_PAGE);
        this.setState({ evaluationsTableData: returnData });
      } else if(tableName === "vaccines"){
        let allRecords = this.state.vaccineQueryResults;
        let returnData = slicePages(allRecords, page, ITEMS_PER_PAGE);
        this.setState({ vaccinesTableData: returnData });
      }
    }

    async updateRows(e){
      if(!e){
          this.setState({employeeInfo: {}, employee: null})
          return;
      }
      this.setState({employee: e, employeeInfo: {}})
      let employeeInfo = e ? await this.state.employees?.find(p => p.ID === e.value) : null;
      let tempObj = {};
      let over18 = true;

      // Define a mapping configuration object
      const fieldMapping = {
          firstName: 'First Name',
          lastName: 'Last Name',
          dateOfBirth: 'Date of Birth',
          middleName: 'Middle Name',
          email: 'Email',
          streetAddress: 'Address',
          streetAddress2: 'Address Cont.',
          city: 'City',
          state: 'State',
          zipcode: 'Zipcode',
          country: 'Country',
          county: 'County',
          ethnicityID: 'Ethnicity',
          genderID: 'Gender',
          raceID: 'Race',
          guardianFirstName: 'Guardian First Name',
          guardianLastName: 'Guardian Last Name',
          phoneNumber: 'Phone',
          FacilityIDs: 'FacilityIDs'
      };

      const otherKeys = ['uid']

      try {
        if(employeeInfo){
          for (const key of Object.keys(employeeInfo)) {
              if(this.props.fromEmployeeMgmt && key === 'FacilityIDs'){
                  tempObj[key] =  JSON.parse(employeeInfo[key])
              }
              if(otherKeys.includes(key)){
                  tempObj[key] = employeeInfo[key];
                  continue;
              }
              if (key === 'firstName' || key === 'lastName') {
                  tempObj[fieldMapping[key]] = employeeInfo[key];
                  continue;
              }
              if (key === 'email') {
                tempObj[fieldMapping[key]] =employeeInfo[key];
                continue;
              }
              if (key === 'phoneNumber') {
                tempObj[fieldMapping[key]] =employeeInfo[key];
                continue;
              }
              if (key === 'dateOfBirth') {
                  tempObj[fieldMapping[key]] =employeeInfo[key];
                  over18 = isEighteenOrOlder(employeeInfo[key])
                  continue;
              }
              if(key !== 'firstName' && key !== 'lastName' && key !== 'dateOfBirth' && !otherKeys.includes(key)){
                  if(key.toLowerCase().includes('guardian') && over18){
                      continue;
                  }
                  let matchingElement = this.state.customEmployeeFields.find(f => f.DisplayName === fieldMapping[key]);
                  if(matchingElement && !isEmptyObject(matchingElement) && matchingElement.Options.isActive){
                      if(key === 'ethnicityID'){
                          tempObj[fieldMapping[key]] =employeeInfo[key] ? this.state.ethnicity.find(f => f.value ===employeeInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'raceID'){
                          tempObj[fieldMapping[key]] =employeeInfo[key] ? this.state.race.find(f => f.value ===employeeInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'genderID'){
                          tempObj[fieldMapping[key]] =employeeInfo[key] ? this.state.gender.find(f => f.value ===employeeInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'state'){
                          tempObj[fieldMapping[key]] =employeeInfo[key] ? this.state.states.find(f => f.value ===employeeInfo[key]).label : null;
                          continue;
                      }
                      if(key === 'country'){
                          tempObj[fieldMapping[key]] =employeeInfo[key] ? this.state.countries.find(f => f.value ===employeeInfo[key]).label : null;
                          continue;
                      }
                      tempObj[fieldMapping[key]] =employeeInfo[key];
  
                  }
              }
          }
          if(employeeInfo.CustomFieldData && !isEmptyObject(JSON.parse(employeeInfo.CustomFieldData))){
              let parsedCustomFieldData = JSON.parse(employeeInfo.CustomFieldData)
              for (const key of Object.keys(parsedCustomFieldData)) {
                  tempObj[key] = parsedCustomFieldData[key]
              }
          }
        }
      } catch (error) {
        console.error(error)
          return sweetalert.fire({icon: 'error', title: '', text: 'Could not update rows at this time'})
      }
      
      this.setState((prevState) => {
          const updatedEmployeeInfo = { ...prevState.employeeInfo };
          if(isEmptyObject(updatedEmployeeInfo)){
              updatedEmployeeInfo.CustomFieldData = {};
          }
          for (const key in tempObj) {
              if (tempObj.hasOwnProperty(key)) {
                  updatedEmployeeInfo.CustomFieldData[key] = tempObj[key];
              }
          }
                  return {
              employeeInfo: updatedEmployeeInfo,
          };
      })
  }

    renderEmployeeRecordFilterFields(){
        return (
            <FilterCard fields={[
                {
                  label: "Confirmation #",
                    "key": "reqNum",
                    type: FIELD_TYPE.TEXT,
                },
                {
                    label: "Service",
                    "key": "service",
                    type: FIELD_TYPE.SELECT,
                    options: getSelectLabels(this.state.services),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                },
                {
                    label: "Test Date",
                    "key": "testDate",
                    type: FIELD_TYPE.DATE,
                }, 
                {
                    label: "Facility",
                    "key": "facility",
                    type: FIELD_TYPE.SELECT,
                    options: getSelectLabels(this.state.facilities),
                    isMapped: true,
                    isMulti: true,
                    textType: 'text',
                    isFilter: true
                },
                {
                  label: "Result",
                  "key": "ResultIDs",
                  type: FIELD_TYPE.SELECT,
                  options: this.state.resultTypes,
                  isMapped: true,
                  isMulti: true,
                  textType: 'text',
                  isFilter: true
                }, 
                // {
                //     label: "Gender",
                //     "key": "genderID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.gender,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Ethnicity",
                //     "key": "ethnicityID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.ethnicity,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
                // {
                //     label: "Race",
                //     "key": "raceID",
                //     type: FIELD_TYPE.SELECT,
                //     options: this.state.race,
                //     isMapped: true,
                //     isMulti: true,
                //     textType: 'text'
                // },
            ]} filterChanged={(e)=> this.setState({ filter: e })} />
        )
    }

    removeStandardEmployeeFields(){
      let customEmployeeFieldCopy = this.state.customEmployeeFields ? JSON.parse(JSON.stringify(this.state.customEmployeeFields)) : [];
      customEmployeeFieldCopy = customEmployeeFieldCopy.filter(f => !standardEmployeeFields.includes(f.DisplayName))
      return customEmployeeFieldCopy;
    }

    getVaccineRecordDetails(val){

      // this.setState({showLoading: true}, async () => {
      //     try {
      //         let result = await VaccineRecordsAPI.queryRecord({ID: val.ID})
      //         if (result.success) {
      //             let record = result.record
      //             this.setState({selectedRecord: record, VaccineModalOpen: true,
      //             showLoading: false}, () => showModalNoOutsideClick(EmployeeVaccineRecordModal.ID))
      //         }
      //     } catch (e) {
      //         console.error(e)
      //         this.setState({showLoading: false})
      //     }
      // })

    }

    getEvaluationRecordDetails(val){

      this.setState({showLoading: true}, async () => {
          // try {
          //     let result = await EvaluationRecordsAPI.queryRecord({ID: val.RecordID})
          //     if (result.success) {
          //         let record = result.record
          //         this.setState({selectedEvaluation: record, EvaluationModalOpen: true,
          //         showLoading: false}, () => showModalNoOutsideClick(EmployeeEvaluationRecordModal.ID))
          //     }
          // } catch (e) {
          //     console.error(e)
          //     this.setState({showLoading: false})
          // }
      })

  }

    needsPhysicianReview(selectedSample){
      //check service for EmailReportTo === 1
      let emailReportTo = this.state.services && selectedSample && selectedSample.ServiceID && (new Date('2023-05-18') < new Date(selectedSample.CreatedDate)) ? this.state.services.find(s => s.ID === selectedSample.ServiceID).EmailReportTo : null
      //check sample for HasPhysicianReviewed
      if(emailReportTo === 1){
        return true
      }
      return false
    }

    // Loading FakeTasks for demo
    renderEmployeeTaskList(){
      return (
        <ul className="list-group">
          {this.state.tasks?.map(task => {
            return (
              <>
                <li className="list-group-item">
                  <input type="checkbox" className="mr-2" 
                  onClick={ (e)=>{
                    // console.log(' checkbox e',e.target)
                    // TODO: set task.CompletedDate on check
                    // TODO: remove task.CompletedDate on uncheck
                  } }/>
                  {/* {task.Title} <br/> <small>{task.Description}</small> <br /> <small>Due: {moment(task.DueDate).format('MM/DD/YYYY h:mm a')}</small> */}
                </li>
              </>
            )
          })}
        </ul>
      )
    }

    render() {

      let over18 = this.state.employee && this.state.employee.CustomFieldData && this.state.employee.CustomFieldData['Date of Birth'] ? isEighteenOrOlder(this.state.employee.CustomFieldData['Date of Birth']) : true;

        // console.log('EmployeeMgmt state', this.state)

        return (<React.Fragment>
            <Overlay show_loading={this.state.showLoading}/>
            <PaginationTool />
            <InBetweenOverlay showInBetween={this.state.showInBetween} />
            <EmployeeRecordModal
              onSubmit={() => {}}
              isManagement={this.state.isManagement}
              isITG={this.state.isITG}
              getResultsButtonBoolean={this.state.getResultsButtonEnabled}
              getPhysicianReviewButtonBoolean={this.needsPhysicianReview(this.state.selectedSample)}
              selectedSample={
                this.state.selectedSample ? this.state.selectedSample : {}
              }
              states={this.state.states}
              countries={this.state.countries}
              ethnicity={this.state.ethnicity}
              race={this.state.race}
              gender={this.state.gender}
              testDetails={this.state.testDetails}
              tests={this.state.tests}
              result_types={this.state.resultTypes}
              validResultTypes={this.state.validResultTypes}
              facilities={this.state.facilitiesForSampleModal}
              events={this.state.eventsForModal}
              customFields={this.state.customFields}
              customQuestions={this.state.customQuestions}
              services={this.state.services}
              customEmployeeFields={this.removeStandardEmployeeFields()}
            />
            {/* <EmployeeEvaluationRecordModal
                evaluations={this.state.evaluations ? this.state.evaluations : ""}
                facilities={this.state.facilities ? this.state.facilities : ""}
                selectedEvaluation={this.state.selectedEvaluation}
                states={this.state.states ? this.state.states : ""}
                countries={this.state.countries ? this.state.countries : ""}
                customQuestions={this.state.customQuestions}
                ethnicity={this.state.ethnicity ? this.state.ethnicity : ""}
                race={this.state.race ? this.state.race : ""}
                gender={this.state.gender ? this.state.gender : ""}
                onClose={() => {this.setState({EvaluationModalOpen: false})}}
                onSubmit={(record:EvaluationRecordType, insuranceInformation, insuranceCards) => {
                    hideModal(EvaluationRecordsModal.ID);
                    //console.log(record, "RECORD")
                    this.setState({EvaluationModalOpen: false}, () => {
                        this.createOrModifyRecord(record, insuranceInformation, insuranceCards);
                    })
                }}
                
            /> */}
            {/* <EmployeeVaccineRecordModal
                facilities={this.state.facilities ? this.state.facilities : ""}
                vaccines={this.state.vaccines ? this.state.vaccines: ""}
                selectedRecord={this.state.selectedRecord}
                states={this.state.states ? this.state.states : ""}
                countries={this.state.countries ? this.state.countries : ""}
                ethnicity={this.state.ethnicity ? this.state.ethnicity : ""}
                race={this.state.race ? this.state.race : ""}
                gender={this.state.gender ? this.state.gender : ""}
                customQuestions={this.state.customQuestions}
                onClose={() => {this.setState({VaccineModalOpen: false})}}
                onSubmit={(record:RecordType, insuranceInformation, insuranceCards) => {
                    hideModal(VaccineRecordsModal.ID);
                    //console.log(record, "RECORD")
                    this.setState({VaccineModalOpen: false}, () => {this.createOrModifyRecord(record, insuranceInformation, insuranceCards)})
                    ;
                }}
                injectionSites={this.state.injectionSites ? this.state.injectionSites : ""}
                
            /> */}
            <EmployeeInfoModal 
                ref={this.employeeInfoRef}
                fromEmployeeMgmt={true}
                submit={(e) => {
                    this.modifyEmployee(e);
                }}
                selectedEmployee={this.state.employee}
                employees={this.state.employees}
                states={this.state.states}
                gender={this.state.gender}
                ethnicity={this.state.ethnicity}
                race={this.state.race}
                countries={this.state.countries}
                facilities={this.state.facilities}
                employeeFields={this.state.customEmployeeFields.sort((a,b) => a.Order - b.Order)}
            />
            <ViewTestInfoModal 
              selectedSample={this.state.selectedSample ? this.state.selectedSample : {}} 
              tests={this.state.tests}
              results={this.state.resultTypes}
              getResultsButtonBoolean={this.state.getResultsButtonEnabled}
              
            />
            <div className="container-fluid ">
                <div className={"row"}>
                    {/* <div className="col-12 col-lg-4 col-xl-4 pt-2">
                        <main id="main-content" tabIndex={-1} aria-label="Employee Management">
                            <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Employee Sample Filter</h4>
                                </div>
                                <div className="card-body">
                                    {this.renderEmployeeRecordFilterFields()}
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline "}
                                            onClick={ () => {
                                                this.setState({
                                                    showLoading: true,
                                                    selected_page: {label: 1, value: 1}}, async () => {
                                                    console.log(this.state.filter)
                                                    let result = await SamplesAPI.getFilteredSamples(this.state.selected_page.value, {
                                                      filter: this.getFilteredObj(),
                                                    })
                                                    if (result.totalSamples < 1) {
                                                      sweetalert.fire({
                                                        icon: "error",
                                                        title: "",
                                                        text: "No samples found",
                                                      });
                                                      this.setState({ showLoading: false, tableData: [], totalSamples: 0 });
                                                      return;
                                                    }
                                                    console.log(result)
                                                    this.setState({
                                                      totalSamples: result.totalSamples,
                                                      showLoading: false,
                                                      page_options: getPageOptions(result.num_pages),
                                                      tableData: result.table_data,
                                                      queryResults: result.table_data,
                                                      searched: this.getFilteredObj(),
                                                    });
                                                    // this.queryEmployees(this.state.selected_page.value)
                                                })
                                            }}
                                    >Search</button>
                                </div>
                            </div>
                        </main>
                    </div> */}
                    {/* Employee Info Card */}
                    <div className="col-12 col-lg-8 col-xl-4 pt-2">
                        
                            {this.state.customEmployeeFields && this.state.employeeInfo ? <div className="card mb-2">
                                <div className="card-header verlag-bold">
                                    <h4>Employee Info</h4>
                                </div>
                                <div className="card-body">
                                  {/* <div className="col-4">
                                    {buildRow("First Name",
                                        <input 
                                            id="FirstName"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            readOnly={true}
                                            type={"search"}
                                            name={"FirstName"}
                                            aria-label="First Name"
                                            value={this.state.employee?.firstName}
                                        />
                                    )}
                                    {buildRow("Last Name",
                                        <input 
                                            id="LastName"
                                            className={"form-control"}
                                            autoComplete={"off"}
                                            readOnly={true}
                                            type={"search"}
                                            name={"LastName"}
                                            aria-label="Last Name"
                                            value={this.state.employee?.lastName}
                                        />
                                    )}
                                  </div>
                                  <div className="col-4">
                                    
                                  </div>
                                  <div className="col-4">
                                    
                                  </div> */}
                                  <div className="row">
                                    {this.state.customEmployeeFields && this.state.customEmployeeFields.length > 0 && this.state.customEmployeeFields.map((val, index) => {
                                        if (val.Options.isActive) {
                                          let value = null;
                                          if(this.state.employeeInfo?.CustomFieldData && Object.keys(this.state.employeeInfo?.CustomFieldData).length > 0){
                                            if(val.FieldTypeID === CustomFields.DATE_FIELD){
                                              value = moment(this.state.employeeInfo?.CustomFieldData[val.DisplayName]).utc().format("MM/DD/YYYY")
                                            } else {
                                              value = this.state.employeeInfo?.CustomFieldData[val.DisplayName]
                                            }
                                              if(over18 && val.DisplayName.toLowerCase().includes('guardian')){
                                                  return <div className="d-none"></div>
                                              }
                                              return (
                                              <div className="col-12">
                                                  <div className="form-group row px-3">
                                                  {/* <GetField employeeSearchReadOnly={true} fromSamples={true} val={val} selectedItem={  this.state.employeeInfo ? this.state.employeeInfo : {} } handleChange={(e) => { this.setState({ employee: e, }); }} /> */}
                                                  
                                                  {val.FieldTypeID === CustomFields.DATE_FIELD ? 
                                                    <p className="d-flex justify-content-between mw-100" style={{minWidth: "100%"}}><strong className="mr-3">{val.DisplayName}:</strong><section className="w-50">{value}</section></p> 
                                                    : <p className="d-flex justify-content-between mw-100" style={{minWidth: "100%"}}><strong className="mr-3">{val.DisplayName}:</strong><section className="w-50">{value}</section></p> 
                                                    }
                                                  </div>
                                              </div>
                                              );
                                          }
                                          
                                        }
                                    })}
                                </div>
                                </div>
                                <div className="card-footer">
                                    <button className={"btn immySubmitButtonOutline "}
                                            onClick={() => {
                                                    showModalNoOutsideClick(EmployeeInfoModal.ID)
                                            }}
                                    >Edit</button>
                                </div>
                            </div> : <></>}
                        
                    </div>
{/* Task Info Card */}
              {
                this.state.employeeInfo &&
                <div className="col-xl-4 pt-2">
                  <div className="card mb-2">
                    <div className="card-header verlag-bold"><h4>Tasks</h4></div>
                    <div className="card-body">
                      <div className="list-group">
                        {this.renderEmployeeTaskList()}
                      </div>
                    </div>
                  </div>
                </div>
              }
                    { this.state.employeeInfo && 
                        <div className="container-fluid ">
                        <div className={"row"}>
                        <div className="col-12 pt-2">
                        <div className="card mb-2">
                        <div className="card-body">
                        <div className="col-12">
                          <nav className='d-md-none'>
                              <div className="nav nav-pills nav-fill mb-2" id="nav-tab" role="tablist" style={{borderBottom: '0.1em solid lightgrey'}}>
                                  {/* <a className="nav-item nav-link active col-12 col-xs-12 col-sm-4" style={{borderRight: '0.1em solid lightgrey', borderBottom: '0.1em solid lightgrey'}}  id="nav-tests-information-tab" data-toggle="tab" href="#nav-tests-information" role="tab" aria-controls="nav-tests-information" aria-selected="true" onClick={() => this.handleActiveTab('tests')}>
                                      Tests
                                      <BsFilter className={isTestsFilterActive ? "float-right" : "invisible"} color={isApptTabActive ? "aliceblue" : "#21212E"} size={20} />
                                  </a> */}
                                  <a className="d-none d-xs-none active d-sm-block nav-item nav-link col-6" style={{borderBottom: '0.1em solid lightgrey'}} id="nav-evaluations-information-tab" data-toggle="tab" href="#nav-evaluations-information" role="tab" aria-controls="nav-evaluations-information" aria-selected="true" onClick={() => this.handleActiveTab('evaluations')}>
                                      Evaluations
                                      {/* <BsFilter className={isSampleFilterActive ? "float-right" : "invisible"} color={isSampleTabActive ? "aliceblue" :"#21212E"} size={20} /> */}
                                  </a>
                                  <a className="d-none d-xs-none d-sm-block nav-item nav-link col-6" id="nav-vaccines-information-tab" data-toggle="tab" href="#nav-vaccines-information" role="tab" aria-controls="nav-vaccines-information" aria-selected="false" onClick={() => this.handleActiveTab('vaccines')}>
                                      Vaccines
                                      {/* <BsFilter className={isEmployeeFilterActive ? "float-right" : "invisible"} color={isEmployeeTabActive ? "aliceblue" : "#21212E"} size={20} /> */}
                                  </a>
                              </div>
                          </nav>
                          <nav className='d-none d-md-block'>
                              <div className="nav nav-pills nav-fill mb-2" id="nav-tab" role="tablist" style={{borderBottom: '0.1em solid lightgrey'}}>
                                  {/* <a className="nav-item nav-link active col-12 col-xs-12 col-sm-4" style={{borderRight: '0.1em solid lightgrey'}} id="nav-appointment-information-tab" data-toggle="tab" href="#nav-appointment-information" role="tab" aria-controls="nav-appointment-information" aria-selected="true" onClick={() => this.handleActiveTab('tests')}>
                                      Tests
                                      <BsFilter className={isApptFilterActive ? "float-right" : "invisible"} color={isApptTabActive ? "aliceblue" : "#21212E"} size={20} />
                                  </a> */}
                                  <a className="d-none d-xs-none active d-sm-block nav-item nav-link col-6" style={{borderRight: '0.1em solid lightgrey'}}  id="nav-evaluations-information-tab" data-toggle="tab" href="#nav-evaluations-information" role="tab" aria-controls="nav-evaluations-information" aria-selected="true" onClick={() => this.handleActiveTab('evaluations')}>
                                      Evaluations
                                      {/* <BsFilter className={isSampleFilterActive ? "float-right" : "invisible"} color={isSampleTabActive ? "aliceblue" :"#21212E"} size={20} /> */}
                                  </a>
                                  <a className="d-none d-xs-none d-sm-block nav-item nav-link col-6" id="nav-vaccines-information-tab" data-toggle="tab" href="#nav-vaccines-information" role="tab" aria-controls="nav-vaccines-information" aria-selected="false" onClick={() => this.handleActiveTab('vaccines')}>
                                      Vaccines
                                      {/* <BsFilter className={isEmployeeFilterActive ? "float-right" : "invisible"} color={isEmployeeTabActive ? "aliceblue" : "#21212E"} size={20} /> */}
                                  </a>
                              </div>
                          </nav>
                          { this.state.isTestsTabActive &&
                          <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-tests-information" role="tabpanel" aria-labelledby="nav-tests-information-tab">
                              <div className="col-12 pt-2">
                                  <div className="card mt-2 mb-5">
                                      <div className="card-header verlag-bold stickToTop">
                                          <h4 className="text-center text-md-left">Test History
                                          <button className={"ml-2 d-none d-md-inline btn btn-outline-primary"} onClick={() => this.handleExportToCSV()} >Export to CSV </button>
                                          <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                          <h4 style={{ float: "right" }} aria-label="Total Records" role="alert">Total: { this.state.testQueryResults ? this.state.testQueryResults?.totalSamples : 0}</h4>
                                          <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                          <div className=" align-middle float-right pages ">
                                              <Select
                                                  isSearchable={true}
                                                  placeholder={"1"}
                                                  noOptionsMessage={() => "No option"}
                                                  aria-label="Table Page Number"
                                                  value={this.state.selected_page}
                                                  onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                      () => this.changePage(e.value, "tests"))}
                                                  className={"state_select"}
                                                  options={this.state.page_options}
                                              />
                                          </div>
                                          </section>    
                                          </h4>
                                      </div>
                                      <div className="p-0 table-responsive">
                                      <SimpleTable ref={el => (this.componentRef = el)} columns={[
                                          {label:"Name", key:"Name", rawFormat:(val:Sample) => {
                                                  return <a href={"#top"}
                                                            className={'tableNameLinkColor'}
                                                            onClick={() => this.setState({getResultsButtonEnabled: val.ResultIDs ? !!(JSON.parse(val.ResultIDs).length > 0) : false, selectedSample:JSON.parse(JSON.stringify(val))}, () => showModalNoOutsideClick(EmployeeRecordModal.ID))}>{val.EmployeeFirstName + " " + val.EmployeeLastName}</a>}},
                                          {label:"DOB", key:"EmployeeDOB", formatFunc:parseDate},
                                          {label:"Appt #", key:"LinkedApptsID"},
                                          {label:"Conf #", key:"ReqNum"},
                                          {label:"Collection Date", key:"CollectionDate", formatFunc:toLocalDateTime},
                                          {label:"Phone #", key:"EmployeePhone"},
                                          {label:"Service", key:"ServiceID", rawFormat:(val) => {
                                            if(val.ServiceID){
                                              return(<p>{getNameFromID(val.ServiceID, this.state.services)}</p>)
                                            }
                                            else{
                                              return null
                                            }
                                          }},
                                          {label:"Test Info", key:"",
                                            rawFormat: (val:Sample) => (
                                            JSON.parse(val.TestIDs)?.length > 0 ?
                                            <button 
                                              type={'button'} 
                                              className={'btn btn-outline-success'} 
                                              onClick={() =>  this.setState({getResultsButtonEnabled: val.ResultIDs ? !!(JSON.parse(val.ResultIDs).length > 0) : false, selectedSample:JSON.parse(JSON.stringify(val))}, () => showModalNoOutsideClick(ViewTestInfoModal.ID))}>{JSON.parse(val.TestIDs).length > 1 ? 'View Tests Info' : 'View Test Info'}
                                            </button> : <div>No Test Assigned</div> ) 
                                          },
                                          {label:"Result Accessed", key:"ResultsAccessedDate",
                                            rawFormat: (val:Sample) => (
                                            val.ResultsAccessedDate ? 
                                            <div>{toLocalDateTime(val.ResultsAccessedDate)}</div> : 
                                            <p>Not Accessed</p> )
                                          }
                                      ]} table_data={this.state.testsTableData} columnClickedCallback={(col =>{
                                          this.useSorter(col, "tests");
                                      })}/>
                                    </div>
                                  </div>
                                  
                              </div>
                            </div>
                          </div>}
                          { this.state.isEvaluationsTabActive &&
                            <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-evaluation-information" role="tabpanel" aria-labelledby="nav-evaluation-information-tab">
                              <div className="col-12 pt-2">
                                  <div className="card mt-2 mb-5">
                                      <div className="card-header verlag-bold stickToTop">
                                          <h4 className="text-center text-md-left">Evaluation History
                                          <button className={"ml-2 d-none d-md-inline btn btn-outline-primary"} onClick={() => this.handleExportToCSV()} >Export to CSV </button>
                                          <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                          <h4 style={{ float: "right" }} aria-label="Total Records" role="alert">Total: { this.state.evaluationQueryResults ? this.state.evaluationQueryResults?.data.length : 0}</h4>
                                          <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                          <div className=" align-middle float-right pages ">
                                              <Select
                                                  isSearchable={true}
                                                  placeholder={"1"}
                                                  noOptionsMessage={() => "No option"}
                                                  aria-label="Table Page Number"
                                                  value={this.state.selected_page}
                                                  onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                      () => this.changePage(e.value, "evaluations"))}
                                                  className={"state_select"}
                                                  options={this.state.page_options}
                                              />
                                          </div>
                                          </section>    
                                          </h4>
                                      </div>
                                      <div className="p-0 table-responsive">
                                      <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Name", key: "Name",
                                            rawFormat: (val) => {
                                                return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                    this.getEvaluationRecordDetails(val)
                                                }}>{val.EmployeeFirstName + " " + val.EmployeeLastName}</a>
                                            },
                                            popoverText: "Click to sort by Name"
                                        },
                                        {
                                          label: "Phone Number", key: "EmployeePhone",
                                          popoverText: "Click to sort by Phone Number"
                                        },
                                        {
                                          label: "Confirmation Number", key: "ReqNum",
                                          popoverText: "Click to sort by Confirmation Number"
                                        },
                                        {
                                            label: "Evaluation", key: "Evaluation",
                                            popoverText: "Click to sort by Evaluation"
                                        },
                                        {
                                            label: "Notes", key: "Notes",
                                            popoverText: "Click to sort by Notes"
                                        },
                                        
                                    
                                        
                                    ]} table_data={this.state.evaluationsTableData} columnClickedCallback={(col =>{
                                        this.useSorter(col, "evaluations");
                                    })} />
                                    </div>
                                  </div>
                                  
                              </div>
                            </div>
                          </div>}
                          { this.state.isVaccinesTabActive &&
                            <div className="tab-content" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-vaccines-information" role="tabpanel" aria-labelledby="nav-vaccines-information-tab">
                              <div className="col-12 pt-2">
                                  <div className="card mt-2 mb-5">
                                      <div className="card-header verlag-bold stickToTop">
                                          <h4 className="text-center text-md-left">Vaccines History
                                          <button className={"ml-2 d-none d-md-inline btn btn-outline-primary"} onClick={() => this.handleExportToCSV()} >Export to CSV </button>
                                          <section className="tableHeaderSection float-md-right d-flex justify-content-around">
                                          <h4 style={{ float: "right" }} aria-label="Total Records" role="alert">Total: { this.state.vaccineQueryResults ? this.state.vaccineQueryResults?.data.length : 0}</h4>
                                          <h4 className="float-right align-middle pr-2 ml-5">Page </h4>
                                          <div className=" align-middle float-right pages ">
                                              <Select
                                                  isSearchable={true}
                                                  placeholder={"1"}
                                                  noOptionsMessage={() => "No option"}
                                                  aria-label="Table Page Number"
                                                  value={this.state.selected_page}
                                                  onChange={(e: ReactSelect) => this.setState({selected_page: e},
                                                      () => this.changePage(e.value, "vaccines"))}
                                                  className={"state_select"}
                                                  options={this.state.page_options}
                                              />
                                          </div>
                                          </section>    
                                          </h4>
                                      </div>
                                      <div className="p-0 table-responsive">
                                      <SimpleTable table_style='tableFixHead' columns={[
                                        {
                                            label: "Name", key: "Name",
                                            rawFormat: (val) => {
                                                return <a href={"#!"} className={'tableNameLinkColor'} onClick={() => {
                                                    this
                                                    this.getVaccineRecordDetails(val)
                                                }}>{val.EmployeeFirstName + " " + val.EmployeeLastName}</a>
                                            },
                                            popoverText: "Click to sort by Name"
                                        },
                                        {
                                          label: "Phone Number", key: "EmployeePhone",
                                          popoverText: "Click to sort by Phone Number"
                                        },
                                        {
                                          label: "Confirmation Number", key: "ReqNum",
                                          popoverText: "Click to sort by Confirmation Number"
                                        },
                                        {
                                            label: "Vaccine", key: "Vaccine",
                                            popoverText: "Click to sort by Vaccine",
                                            rawFormat: (val) => {
                                                
                                                    let vaccine = this.state.vaccines.find(f => f.value === val.VaccineID);
                                                    if(vaccine){
                                                        return vaccine.label;
                                                    }
                                                
                                                return vaccine?.map(m => (
                                                    <p className="mb-0">{m}</p>
                                                ))
                                            }
                                        },
                                        {
                                          label: "Injection Site", key: "InjectionSite",
                                          popoverText: "Click to sort by Injection Site",
                                          rawFormat: (val) => {
                                                
                                            let injectionSite = this.state.injectionSites?.find(f => f.value === val.InjectionSite);
                                            if(injectionSite){
                                                return injectionSite.label;
                                            }
                                        
                                        return injectionSite?.map(m => (
                                            <p className="mb-0">{m}</p>
                                        ))
                                    }
                                        },
                                        {
                                          label: "Vaccine Lot Number", key: "LotNumber",
                                          popoverText: "Click to sort by Lot Number"
                                        },
                                        {
                                          label: "Vaccine Expiration Date", key: "VaccineExpDate",
                                          popoverText: "Click to sort by Expiration Date",
                                          formatFunc:parseDate
                                        },
                                        {
                                          label: "Appoinment Date", key: "AppointmentDate",
                                          popoverText: "Click to sort by Injection Date", 
                                          rawFormat: (val) => {
                                            const inputDate = new Date(val.AppointmentDate);
                                            // Adjust for the time zone offset
                                            const adjustedDate = new Date(inputDate.getTime() + inputDate.getTimezoneOffset() * 60000);

                                            const month = (adjustedDate.getMonth() + 1).toString().padStart(2, "0");
                                            const day = adjustedDate.getDate().toString().padStart(2, "0");
                                            const year = adjustedDate.getFullYear();
                                                                                    

                                            const formattedDate = `${month}-${day}-${year}`;
                                            return (<div>{val.AppointmentDate ? formattedDate : null}</div>)
                                          }
                                        },
                                        
                                        
                                    ]} table_data={this.state.vaccinesTableData} columnClickedCallback={(col =>{
                                        this.useSorter(col, "vaccines");
                                    })} />
                                    </div>
                                  </div>
                                  
                              </div>
                            </div>
                          </div>}
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                        </div>
                      }

                </div>
            </div>
        </React.Fragment>)
    }
}