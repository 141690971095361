import React from 'react';
import Select from 'react-select';
import { buildRow, dateWithNoTime, hideModal } from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import Validator from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import GetField from '../../customfields/GetField';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import { isEmptyObject } from 'jquery';

interface AddRecordToEmployeeModalState {
  selectedRecordLabel;
  selectedEmployeeLabel;
  createdRecord: {
    RecordID: number;
    EmployeeUID: string;
    CustomFieldData;
    CreatedDate?;
  };
  isHintActive: boolean;
  hintID: number;
}

interface AddRecordToEmployeeModalProps {
  onSubmit?;
  clearStateAndRefreshDataAfterAddRecordModal?;
  pageCustomQuestions?;
  employees;
  recordType;
  options;
  fromMgmt?;
  passedInRecord?;
}
export class AddRecordToEmployeeModal extends React.Component<AddRecordToEmployeeModalProps, AddRecordToEmployeeModalState> {
  public static ID = 'AddRecordToEmployeeModal';

  constructor(props) {
    super(props);
    this.state = {
      isHintActive: false,
      hintID: 0,
      selectedRecordLabel: null,
      selectedEmployeeLabel: null,
      createdRecord: {
        RecordID: null,
        EmployeeUID: null,
        CustomFieldData: null,
        CreatedDate: null,
      },
    };
    this.clearFields = this.clearFields.bind(this);
  }

  clearFields() {
    this.setState({
      selectedRecordLabel: null,
      selectedEmployeeLabel: null,
      createdRecord: {
        RecordID: null,
        EmployeeUID: null,
        CustomFieldData: null,
        CreatedDate: null,
      },
    });
  }

  render() {
    // console.log('AddRecordToEmployeeModal props', this.props);
    // console.log('AddRecordToEmployeeModal STATE', this.state);

    //get selected record if passed by mgmt
    let passedInRecord = this.props.fromMgmt && this.props.passedInRecord && !isEmptyObject(this.props.passedInRecord) ? this.props.passedInRecord : null;
    // console.log('passedInRecord', passedInRecord);

    return (
      <div className="modal fade form_modal" id={AddRecordToEmployeeModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl" role="document">
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">
                  {`Add ${this.props.recordType} to Employee`}
                </h5>
                <button
                  style={{ outline: 'none' }}
                  type="button"
                  onClick={() => {
                    this.clearFields()
                    this.props.clearStateAndRefreshDataAfterAddRecordModal();
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body m-3">
                <div className={'form-group row'} data-toggle={'tooltip'} data-placement={'top'} title={'EmployeeSearch'}>
                  <label htmlFor={'EmployeeSearch'} className={'col-sm-4 col-form-label'}>
                    {'Employee'}
                  </label>
                  <div className={'col-8 p-0 m-0'} id={'EmployeeSearch'}>
                    <Select
                      name="EmployeeSearch"
                      isSearchable={true}
                      isClearable={true}
                      placeholder={<div className="accessibilityText">Please Select...</div>}
                      noOptionsMessage={() => 'No option'}
                      className={'state_select'}
                      options={
                        this.props.employees && this.props.employees.length > 0
                          ? this.props.employees.map(p => {
                              return {
                                label: `${p.FirstName} ${p.LastName} - ${dateWithNoTime(p.DateOfBirth)} - ${p.UID}`,
                                value: p.UID,
                              };
                            })
                          : []
                      }
                      onChange={e =>
                        this.setState({
                          selectedEmployeeLabel: e,
                        })
                      }
                      value={this.state.selectedEmployeeLabel ? this.state.selectedEmployeeLabel : null}
                      aria-label="EmployeeSearch"
                    />
                  </div>
                </div>
                {passedInRecord && (passedInRecord.ID || passedInRecord.label) ? (
                  buildRow(
                    this.props.recordType,
                    <input
                      className={'form-control'}
                      maxLength={500}
                      autoComplete={'off'}
                      type={'search'}
                      name={this.props.recordType}
                      aria-label={this.props.recordType}
                      readOnly={true}
                      value={passedInRecord && passedInRecord.Name ? passedInRecord.Name : passedInRecord.label}
                    />
                  )
                ) : (
                  <div className={'form-group row'} data-toggle={'tooltip'} data-placement={'top'} title={this.props.recordType}>
                    <label htmlFor={this.props.recordType} className={'col-sm-4 col-form-label'}>
                      {this.props.recordType}
                    </label>
                    <div className={'col-8 p-0 m-0'} id={this.props.recordType}>
                      <Select
                        name={this.props.recordType}
                        isSearchable={true}
                        isClearable={true}
                        placeholder={<div className="accessibilityText">Please Select...</div>}
                        noOptionsMessage={() => 'No option'}
                        className={'state_select'}
                        options={this.props.options && this.props.options.length > 0 ? this.props.options : []}
                        onChange={e =>
                          this.setState({
                            selectedRecordLabel: e,
                          })
                        }
                        value={this.state.selectedRecordLabel ? this.state.selectedRecordLabel : null}
                        aria-label={this.props.recordType}
                      />
                    </div>
                  </div>
                )}
                {/*Custom Questionnaire Data*/}

                {this.props.pageCustomQuestions ? (
                  <div className="row">
                    {this.props.pageCustomQuestions.map((val, index) => {
                      if (val.Options.isActive) {
                        return (
                          <div className="col-12">
                            <div className="form-group row">
                              <GetField
                                val={val}
                                selectedItem={this.state.createdRecord ? this.state.createdRecord : null}
                                handleChange={e => {
                                  this.setState({ createdRecord: e });
                                }}
                              />
                            </div>
                            <div className={this.state.isHintActive && val.ID === this.state.hintID ? 'form-group row' : 'd-none'}>
                              <div className="col-12 col-md-4">
                                <label className={'col-form-label'} htmlFor={'Hint'} style={{ paddingTop: '0.5em' }}>
                                  Hint
                                </label>
                              </div>
                              <div className="col-12 col-md-8 pl-0">{val.Options.hint}</div>
                            </div>
                          </div>
                        );
                      } else {
                        return <></>;
                      }
                    })}
                  </div>
                ) : (
                  <div className="invisible"></div>
                )}
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    let formValidation = {
                      EmployeeUID: this.state.selectedEmployeeLabel && this.state.selectedEmployeeLabel.value ? this.state.selectedEmployeeLabel.value : null,
                      RecordID:
                        passedInRecord && passedInRecord.ID
                          ? passedInRecord.ID
                          : passedInRecord && passedInRecord.value
                          ? passedInRecord.value
                          : this.state.selectedRecordLabel && this.state.selectedRecordLabel.value
                          ? this.state.selectedRecordLabel.value
                          : null,
                      CustomFieldData: this.state.createdRecord && this.state.createdRecord.CustomFieldData ? this.state.createdRecord.CustomFieldData : null,
                      CreatedDate: this.state.createdRecord && this.state.createdRecord.CreatedDate ? this.state.createdRecord.CreatedDate : null,
                    };

                    let validator = new Validator<any>();
                    validator
                      .withSimpleValidation('EmployeeUID', Validators.requireNonNullValidator('Employee'))
                      .withSimpleValidation('RecordID', Validators.requireNonNullValidator('Record'));

                    let validationResponse = validator.validate(formValidation);
                    if (!validationResponse.success) {
                      return sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
                    }

                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.createdRecord.CustomFieldData, this.props.pageCustomQuestions, this.state.createdRecord);

                    if (invalid) {
                      return;
                    }

                    let selectedEmployee = this.props.employees && this.props.employees.length > 0 ? this.props.employees.find(f => f.UID === formValidation.EmployeeUID) : null;

                    let parsedEmployeeFacilityIDsArray = selectedEmployee && selectedEmployee ? JSON.parse(selectedEmployee.FacilityIDs) : [];

                    hideModal(AddRecordToEmployeeModal.ID);
                    this.props.onSubmit({
                      EmployeeUID: formValidation.EmployeeUID,
                      RecordID: formValidation.RecordID,
                      //TODO: need to figure out if facility is needed
                      FacilityID: parsedEmployeeFacilityIDsArray[0],
                      CustomFieldData: JSON.stringify(formValidation.CustomFieldData),
                      CreatedDate: formValidation.CreatedDate,
                      RecordType: this.props.recordType,
                    });
                    this.setState({
                      selectedEmployeeLabel: null,
                      selectedRecordLabel: null,
                      createdRecord: {
                        RecordID: null,
                        EmployeeUID: null,
                        CustomFieldData: null,
                        CreatedDate: null,
                      },
                    });
                  }}
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
