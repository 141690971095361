import React from "react";
import Overlay from "../Overlay";
import { CommunicationTemplate } from "../../types/CommunicationTemplate";
import Select from "react-select";
import { buildRow, showModalNoOutsideClick } from "../../util/FormatUtil";
import { sweetalert } from "../../App";
import CommTemplatesAPI from "../../network/CommTemplatesAPI";
import Parser from "html-react-parser";
import { CustomQuillEditor } from "../CustomQuillEditor";
import { isEmptyObject } from "jquery";
import { HintModal } from "../modals/HintModal";
import { FaInfoCircle } from "react-icons/fa";
import { BsFillRecord2Fill } from "react-icons/bs";

import { isBlank, isOnlyWhitespace } from "../../util/ValidationUtil";
import AdminAPI from "../../network/AdminAPI";

interface CommunicationTemplatesState {
  showLoading: boolean;
  showQuillEditor: boolean;
  template: CommunicationTemplate;
  editableTemplates: CommunicationTemplate[];
  templateNames: { label; value }[];
  templateType: { label; value };
  brandingLogo?: string;
}

const TemplateFields = {
  //Text
  AlertText: ["Name", "Text"],
  AppointmentText: [
    "Name",
    "Address",
    "Date",
    "Time",
    "ServiceName",
    "LinkedApptsID",
    "Product",
    "CheckinLink",
    "ReqNum"
  ],
  CancellationText: ["Name", "ReqNum", "LinkedApptsID", "Product"],
  ReminderText: ["Name", "Address", "Date", "Time", "LinkedApptsID", "Product"],
  ResultsText: ["Name", "ReqNum", "Product", "Link"],
  ResultsReminderText: ["Name", "ReqNum", "Link", "Product"],
  WalkinText: ["Name", "ServiceName", "Product", "ReqNum", "LinkedApptsID"],
  RecordAssignedText: ["Name", "ReqNum", "Link", "Product", "RecordType"],
  //Email
  AlertEmail: ["Name", "Text", "Product"],
  AppointmentEmail: [
    "Name",
    "Location",
    "Address",
    "Date",
    "Time",
    "CheckinLink",
    "Link",
    "Product",
    "Email",
    "ServiceName",
    "LinkedApptsID",
    "ReqNum"
  ],
  CancellationEmail: [
    "Name",
    "ReqNum",
    "LinkedApptsID",
    "Product",
    "HelpEmail",
    "Link",
  ],
  ReminderEmail: ["Name", "LinkedApptsID", "Product", "HelpEmail"],
  ResultsEmail: ["Name", "ReqNum", "Product", "Email", "Link"],
  ResultsReminderEmail: ["Name", "ReqNum", "Product", "Link", "HelpEmail"],
  NewUserWelcomeEmail: [
    "Name",
    "DefaultPassword",
    "LoginLink",
    "Product",
    "Email",
  ],
  ResetPasswordEmail: ["Name", "ResetLink", "Product", "HelpEmail"],
  EnrollMFAEmail: ["Name", "LoginLink", "PhoneNumber", "Email", "Product"],
  PhysicianReviewEmail: ["ReqNum", "ServiceName", "Link", "Product", "Email"],
  WalkinEmail: ["Name", "ServiceName", "Product", "Email", "ReqNum", "LinkedApptsID"],
  RecordAssignedEmail: ["Name", "ReqNum", "Link", "Product", "Email", "RecordType"],

};

export default class CommunicationTemplates extends React.Component<
  any,
  CommunicationTemplatesState
> {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      showQuillEditor: false,
      template: {} as CommunicationTemplate,
      editableTemplates: [],
      templateNames: [],
      templateType: { label: "", value: "" },
    };
    this.save = this.save.bind(this);
    this.handleTypeSelect = this.handleTypeSelect.bind(this);
    this.handleNameSelect = this.handleNameSelect.bind(this);
    this.handleInput = this.handleInput.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  componentDidMount() {
    document.title = "Communication Templates Page";
    this.setState({ showLoading: true }, async () => {
      await this.loadEditableTemplates();
    });
  }

  loadEditableTemplates = async () => {
    let response = await CommTemplatesAPI.getEditableTemplates();
    if (!response.success) {
      return sweetalert.fire({
        icon: "info",
        title: "",
        text: "No Communication Templates found.",
      });
    }

    this.setState({
      editableTemplates: response.data,
      templateNames: response.data.map((n) => {
        let name = n.Name;
        let parsedName = name
          .match(/([A-Z]?[^A-Z]*)/g)
          .slice(0, -1)
          .join(" ");
        if (parsedName.includes("walk") || parsedName.includes("Walk")) {
          parsedName = "Submission Form";
        }
        return { label: parsedName, value: n.Name };
      }),
      showLoading: false,
    });
  };

  validateString(htmlString: string, templateName: string): any[] {
    console.log('htmlString', htmlString)
    console.log('templateName', templateName)
    let requiredFields = TemplateFields[templateName];
    console.log('requiredFields', requiredFields)
    let missing = [];

    for (const field of requiredFields) {
      console.log('field', field)
      let requiredField = new RegExp("#{" + field + "}");
      // if(field === "Logo"){requiredField = /<img class="customtoolbar-logo-width"/; }
      let exists = requiredField.test(htmlString);
      if (!exists) {
        missing.push(field);
      }
    }
    return missing;
  }

  save() {
    this.setState({ showLoading: true }, async () => {
      let baseTemplate =
        this.state.templateType.value === "Email"
          ? this.state.template.BaseTemplate
          : this.state.template.Template;

      let stringToReplace =
        this.state.templateType.value === "Email"
          ? '<div id="editable_template"></div>'
          : /(<([^>]+)>)/gi;

      let editedTemplate =
        this.state.templateType.value === "Email"
          ? this.state.template.EditableTemplate
          : "";
      let updatedTemplate = baseTemplate.replace(
        stringToReplace,
        editedTemplate
      );

      let missingFields = this.validateString(
        updatedTemplate,
        this.state.template.Name
      );
      console.log('missingFields', missingFields)
      if (missingFields && missingFields.length > 0) {
        // validate template fields exist
        sweetalert
          .fire({
            icon: "warning",
            title: "Attention",
            text:
              "Please ensure that the following fields are included with the template: " +
              missingFields.join(", "),
          })
          .then(() => {
            this.setState({ showLoading: false });
          });
      } else if (
        // validate subject line for email templates
        this.state.templateType.value === "Email" &&
        (!this.state.template.Subject ||
          isOnlyWhitespace(this.state.template.Subject) ||
          isBlank(this.state.template.Subject))
      ) {
        sweetalert
          .fire({
            icon: "warning",
            title: "Attention",
            text: "Please ensure that the template does not have an empty Subject line.",
          })
          .then(() => {
            this.setState({ showLoading: false });
          });
      } else {
        // save template
        this.setState(
          (prevState) => ({
            template: {
              ...prevState.template,
              Name: this.state.template.Name,
              Subject: this.state.template.Subject,
              Template: updatedTemplate,
              EditableTemplate: this.state.template.EditableTemplate,
            },
          }),
          async () => {
            let response = await CommTemplatesAPI.editTemplate(
              this.state.template
            );
            if (!response.success) {
              let msg =
                "Unable to Edit Communication Template at this time.\n\n";
              if (response.reason) {
                msg = response.reason;
              }
              return sweetalert.fire({
                icon: "error",
                title: "Attention",
                text: msg,
              });
            }
            sweetalert
              .fire({
                icon: "success",
                title: "",
                text: "Communication Template saved",
              })
              .then(() => {
                this.setState(
                  { showLoading: false, showQuillEditor: false },
                  () => this.loadEditableTemplates()
                );
              });
          }
        );
      }
    });
  }

  handleNameSelect(e) {
    let template = this.state.editableTemplates.find((t) => t.Name === e.value);
    if (!template) {
      console.error("Template not found", e);
      return sweetalert.fire({
        icon: "error",
        title: "Attention",
        text: "Template not found.",
      });
    }
    this.setState({ template: template });
  }

  handleInput(e) {
    this.setState((prevState) => ({
      template: {
        ...prevState.template,
        Subject: e.target.value,
      },
    }));
  }

  handleTypeSelect(e) {
    this.setState({ templateType: e, template: {} as CommunicationTemplate });
  }

  handleEditorChange(val) {
    console.log('val', val)
    if (this.state.templateType.value === "Email") {
      this.setState((prevState) => ({
        template: {
          ...prevState.template,
          EditableTemplate: val,
        },
      }));
    }
    if (this.state.templateType.value === "Text") {
      this.setState((prevState) => ({
        template: {
          ...prevState.template,
          Template: val,
        },
      }));
    }
  }

  handleCancel() {
    this.setState({
      showQuillEditor: false,
      template: {} as CommunicationTemplate,
      templateType: { label: "", value: "" },
    });
  }

  render() {
    // console.log('commTemplate state', this.state)

    return (
      <React.Fragment>
        <Overlay show_loading={this.state.showLoading} />
        <HintModal
          header={"Communication Templates"}
          content={[
            <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
              <BsFillRecord2Fill color={"#21212E"} size={25} /> Select Email or
              Text Type to edit an existing template.
            </div>,
            <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
              <BsFillRecord2Fill color={"#21212E"} size={25} /> Select the Name
              of the template you want to edit.
            </div>,
            <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
              <BsFillRecord2Fill color={"#21212E"} size={25} /> Click on the
              'Edit' button to edit the template.
            </div>,
            <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
              <BsFillRecord2Fill color={"#21212E"} size={25} /> Click on the
              'Save' button to save changes made to a template.
            </div>,
            <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
              <BsFillRecord2Fill color={"#21212E"} size={25} /> Email Templates
              - use the text editor sub-tool buttons to insert placeholders for
              Employee/Appointment information.
            </div>,
            <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
              <BsFillRecord2Fill color={"#21212E"} size={25} /> The email logo
              will be populated dynamically based on email purpose and related
              service.
            </div>,
            <div style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
              <BsFillRecord2Fill color={"#21212E"} size={25} /> Text Templates -
              are stripped of any paragraph formatting on save.
            </div>,
          ]}
          handleInBetweenOverlay={() => {}}
        />
        <div className="container-fluid">
          <div className={"row"}>
            <div className="col-12 col-md-12 col-lg-8 col-xl-6 pt-2">
              <main
                id="main-content"
                tabIndex={-1}
                aria-label="Communication Templates"
              >
                <div className="card mb-2">
                  <div className="card-header verlag-bold">
                    {/*<h4>Communication Templates <FiInfo tabIndex={5} className={'ml-1'} size={30} color={"#00539b"} onClick={() => HintModal.display() }/></h4>*/}
                    <h4>
                      Communication Templates{" "}
                      <FaInfoCircle
                        tabIndex={0}
                        className={"ml-1"}
                        size={25}
                        color={"#21212E"}
                        onClick={() => showModalNoOutsideClick(HintModal.ID)}
                      />
                    </h4>
                  </div>
                  <div className="card-body">
                    {buildRow(
                      "Type",
                      <Select
                        isSearchable={true}
                        placeholder={"Please select..."}
                        onChange={(e) => this.handleTypeSelect(e)}
                        className={"type_select"}
                        aria-label="Type"
                        options={[
                          { label: "Text", value: "Text" },
                          { label: "Email", value: "Email" },
                        ]}
                        value={this.state.templateType}
                        isDisabled={this.state.showQuillEditor}
                      ></Select>
                    )}
                    {this.state.templateType.value !== "" &&
                      buildRow(
                        "Name",
                        <Select
                          isSearchable={true}
                          placeholder={"Please select..."}
                          onChange={(e) => this.handleNameSelect(e)}
                          className={"state_select"}
                          aria-label="Name"
                          options={this.state.templateNames.filter((t) =>
                            t.value.match(this.state.templateType.value)
                          )}
                          value={
                            !isEmptyObject(this.state.template)
                              ? this.state.templateNames.find(
                                  (n) => n.label === this.state.template.Name
                                )
                              : { label: "", value: "" }
                          }
                          isDisabled={this.state.showQuillEditor}
                        ></Select>
                      )}
                    {this.state.templateType.value === "Email" &&
                      buildRow(
                        "Subject",
                        <input
                          className={"form-control"}
                          maxLength={500}
                          autoComplete={"off"}
                          type={"search"}
                          name={"Subject"}
                          aria-label="Subject"
                          value={this.state.template.Subject}
                          onChange={(e) => this.handleInput(e)}
                          readOnly={!this.state.showQuillEditor}
                        />
                      )}
                    {this.state.showQuillEditor ? (
                      <CustomQuillEditor
                        value={
                          this.state.templateType.value === "Email"
                            ? this.state.template.EditableTemplate
                            : this.state.template.Template
                        }
                        onChange={(val) => this.handleEditorChange(val)}
                        templateName={this.state.template.Name}
                        templateFields={TemplateFields}
                        formats={this.state.templateType.value === "Email"}
                      />
                    ) : (
                      <React.Fragment />
                    )}
                  </div>
                  {this.state.showQuillEditor ? (
                    <div className={"card-footer"}>
                      <button
                        className={"btn immySubmitButtonOutline"}
                        onClick={() => {
                          this.save();
                        }}
                      >
                        Save
                      </button>
                      <button
                        className={"btn immyClearButtonOutline float-right"}
                        onClick={() => {
                          this.handleCancel();
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                  {!this.state.showQuillEditor &&
                  !isEmptyObject(this.state.template) ? (
                    <div className={"card-footer"}>
                      <button
                        className={"btn btn-outline-success float-right"}
                        onClick={() => this.setState({ showQuillEditor: true })}
                      >
                        Edit
                      </button>
                    </div>
                  ) : (
                    <React.Fragment />
                  )}
                </div>
              </main>
            </div>

            {!isEmptyObject(this.state.template) ? (
              <div className="col-12 col-md-12 col-lg-4 col-xl-6 pt-2">
                <div className={"card mb-2"}>
                  <div className="card-body">
                    {this.state.template.EditableTemplate &&
                    this.state.templateType.value !== "" &&
                    this.state.templateType.value === "Email"
                      ? Parser(this.state.template.EditableTemplate)
                      : Parser(this.state.template.Template)}
                  </div>
                </div>
              </div>
            ) : (
              <React.Fragment />
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}
