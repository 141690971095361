import React from "react";
import Select from "react-select";
import {
    buildRow,
    getBoolSelectVal,
    hideModal
} from "../../util/FormatUtil";
import {PostedTest} from "../admin/TestManagement";
import {sweetalert} from '../../App';
import Validator from "../../validation/Validator";
import {Validators} from "../../validation/Validators";
import {isEmptyObject} from "jquery";
import {CustomFieldBuilder} from "../../customfields/CustomFieldBuilder";
import GetField from "../../customfields/GetField";
import SystemAPI from "../../network/SystemAPI";

interface TestManagementState {
    selectedTest:PostedTest
    allowedResults:{label, value}[]
    customFieldData?
}

interface TestManagementModalProps{
    selectedTest
    onSubmit?:(test:PostedTest) => void
    resultTypes:{label, value}[]
    pageCustomQuestions?
    allResults
}
export class TestManagementModal extends React.Component<TestManagementModalProps, TestManagementState> {
    public static ID = "testmanagement"

    constructor(props) {
        super(props);
        this.state = {
            selectedTest: {
                ID:  null,
                Name:  '',
                IsEnabled: 0,
                ValidResults: [],
                CustomFieldData: null,
                CreatedDate: null,
            } as PostedTest,
            allowedResults:[],
        }
        this.clearFields = this.clearFields.bind(this)
    }

    componentWillReceiveProps(nextProps: Readonly<TestManagementModalProps>, nextContext: any) {
        // console.log('TestManagementModal nextProps', nextProps)
        if(!isEmptyObject(nextProps.selectedTest)){
            let test = nextProps.selectedTest;
            let resultLabels = [];
            if(test && test.allowedResults && test.allowedResults.length > 0){
                for (let i = 0; i < test.allowedResults.length; i++) {
                    if(nextProps.allResults && nextProps.allResults.length > 0){
                        for (let j = 0; j < nextProps.allResults.length; j++) {
                            if(nextProps.allResults[j].ID === test.allowedResults[i]){
                            resultLabels.push({label: nextProps.allResults[j].Name, value: test.allowedResults[i]})
                            }
                        }
                    }
                }
            } 
            this.setState({
                selectedTest: {
                    ID: test.ID,
                    Name: test.Name,
                    IsEnabled: !test.IsEnabled ? 0 : 1, 
                    CustomFieldData: test.CustomFieldData && JSON.stringify(test.CustomFieldData) !== '{}' ? JSON.parse(test.CustomFieldData) : null,
                    CreatedDate: test.CreatedDate,
                } as PostedTest,
                allowedResults: resultLabels,
            });
        }
    }

    clearFields(){
        this.setState({
            selectedTest: {
                ID: null,
                Name: '',
                IsEnabled: 0,
                ValidResults: [],
                CustomFieldData: null,
                CreatedDate: null,
                ResultIDs: [],
            } as PostedTest,
            allowedResults:[]
        })
    }


    render() {

        // console.log('Test Mgmt Modal STATE', this.state)

        let enabledResultLabels = [];
        if(this.props.allResults && this.props.allResults.length > 0){
            for (let i = 0; i < this.props.allResults.length; i++) {
                if(this.props.allResults[i].IsEnabled){
                    enabledResultLabels.push({label: this.props.allResults[i].Name, value: this.props.allResults[i].ID})
                }
            }
        }

        return(
            <div className="modal fade form_modal" id={TestManagementModal.ID} tabIndex={-1} role="dialog"
                 aria-labelledby="result_modal_label"
                 aria-hidden="true">
                <div className="modal-dialog modal-lg modal-xl" role="document">
                    <div className="modal-content">
                    <div className="container-fluid">
                        <div className="modal-header">
                            <h5 className="modal-title" id="result_modal_label">{this.state.selectedTest && this.state.selectedTest.ID ? 'Update Test' : 'Create Test'}</h5>
                            <button  style={{outline: 'none'}} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body m-3">

                            {buildRow("Name",
                                <input className="form-control"
                                       id="Name"
                                       maxLength={500}
                                       autoComplete="off"
                                       type={"search"}
                                       name={"Name"}
                                       aria-label="Name"
                                       readOnly={this.state.selectedTest.ID != null}
                                       onChange={(e) =>{
                                           this.setState( (prevState) => ({
                                               selectedTest: {
                                                   ...prevState.selectedTest,
                                                   Name: e.target.value
                                               }
                                           }))
                                       }}
                                       value={this.state.selectedTest.Name}
                                />)}
                            {buildRow("Is Enabled?",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    value={getBoolSelectVal(this.state.selectedTest.IsEnabled)}
                                    aria-label="Is Enabled?"
                                    onChange={(e) => this.setState((prevState)=>({
                                        selectedTest: {
                                            ...prevState.selectedTest,
                                            IsEnabled: e.value ? 1 : 0
                                        }
                                    }))}
                                    className={"state_select"}
                                    options={[{label: "Yes", value: true}, {label: "No", value: false}]}
                            />)}
                            {buildRow("Allowed Results",
                                <Select
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    noOptionsMessage={() => "No option"}
                                    isMulti={true}
                                    value={this.state.allowedResults}
                                    aria-label="Allowed Results"
                                    onChange={(e) =>{
                                        this.setState({allowedResults: e as any})
                                    }}
                                    className={"state_select"}
                                    options={enabledResultLabels}
                                />)}

                            {/*Custom Field Data*/}

                            {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField 
                                                            val={val} 
                                                            selectedItem={this.state.selectedTest ? this.state.selectedTest : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedTest: e})
                                                            }}  
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }

                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-primary" onClick={async () => {
                                    let formValidation = {
                                        Name: this.state.selectedTest.Name,
                                        IsEnabled: this.state.selectedTest.IsEnabled,
                                        AllowResults: this.state.allowedResults && this.state.allowedResults.length > 0 ? this.state.allowedResults : null,
                                    }
                                    let passed = 1;

                                    if(this.state.selectedTest.IsEnabled === 0){
                                        await SystemAPI.checkIfTestAssignedToService(this.state.selectedTest.ID).then(services => {
                                            if(services.length > 0){
                                                passed = 0;
                                                if(services.length > 1){
                                                    let serviceNames = []
                                                    services.map((elem) => {
                                                        serviceNames.push(elem.Name)
                                                    })
                                                    let nameString = serviceNames.toString().replace(/,/g, ', ')
                                                    return sweetalert.fire({icon: 'error', title: '', html:`<p style="font-weight: bold;">${this.state.selectedTest.Name}</p><p>must be removed from the below services before it can be disabled.</p><p style="font-weight: bold;">${nameString}</p>`});
                                                } else {
                                                    return sweetalert.fire({icon: 'error', title: '', html: `<p style="font-weight: bold;">${this.state.selectedTest.Name}</p><p>must be removed from the below service before it can be disabled <p style="font-weight: bold;">${services[0].Name}</p>`});
                                                }
                                                
                                            }
                                        })
                                    }

                                    if(passed === 0){
                                        return;
                                    }
                                

                                    let validator = new Validator<any>()
                                        .withValidation("Name", Validators.requireNotBlankAndLength(500))
                                        .withSimpleValidation("AllowResults", Validators.requireNonNullValidator("Allow Results"))
                                        if(!this.state.selectedTest.IsEnabled && this.state.selectedTest.IsEnabled !== 0){
                                            validator = validator.withSimpleValidation("IsEnabled", Validators.requireNonNullValidator("Is Enabled"))
                                        }  
                                    let validationResponse = validator.validate(formValidation);
                                    if(!validationResponse.success) {
                                        return sweetalert.fire({icon: 'error', title: '', text: validationResponse.error});
                                    }

                                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedTest.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedTest)

                                    if(invalid){
                                        return;
                                    }

                                    hideModal(TestManagementModal.ID)
                                    this.props.onSubmit(
                                        {
                                            ID: this.state.selectedTest.ID,
                                            Name: this.state.selectedTest.Name,
                                            IsEnabled: this.state.selectedTest.IsEnabled,
                                            CustomFieldData: this.state.selectedTest.CustomFieldData,
                                            CreatedDate: this.state.selectedTest.CreatedDate,
                                            ResultIDs:this.state.allowedResults ? this.state.allowedResults.map(a => a.value) : [],
                                        } as PostedTest)
                                    this.setState({
                                        selectedTest: {
                                            ID: null,
                                            Name: '',
                                            IsEnabled: 0,
                                            ValidResults: [],
                                            CustomFieldData: null,
                                            CreatedDate: null,
                                            ResultIDs: [],
                                        } as PostedTest,
                                        allowedResults:[]
                                    })
            
                                }}>
                                    {this.state.selectedTest && this.state.selectedTest.ID ? 'Update' : 'Create'}
                                </button>
                            </div>
                    </div>
                </div>
                </div>
            </div>
        )
    }
}