import React, {useEffect, useState, useContext} from 'react';
import './App.css';
import {BrowserRouter, Redirect, Route, Switch} from "react-router-dom";
import { AuthContext } from './context/AuthContext';
import SkipLink from './components/SkipLink';
import SystemAPI from './network/SystemAPI';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { SampleCSVImport }  from './components/admin/SampleCSVImport';

//Public side
import Nav from "./components/Nav";
import Login from "./components/admin/Login"; //FC
import LoggedOut from "./components/admin/LoggedOut";
import Footer from "./components/Footer";
import ForgotPassword from './components/admin/ForgotPassword';
//Admin
import AdminHomePage from "./components/admin/AdminHomePage";
import AdminPanel from "./components/admin/AdminPanel";
//Submission
import WalkinForm from "./components/admin/WalkinForm";
//Events
import Events from "./components/admin/Events";
import CheckIn from "./components/admin/CheckIn";
import Alert from "./../src/components/admin/Alert";
//Employee
import EmployeeRecord from './components/admin/EmployeeRecord';
import EmployeeManagement from './components/admin/EmployeeManagement';
//Reports
import AuditLogs from "./components/admin/AuditLogs";
import Analytics from './components/admin/Analytics';
//Tasks
// import TaskRecords  from "./components/admin/TaskRecords";
//OSHA
import OSHA  from "./components/admin/OSHA"
//Incidents
// import IncidentRecords  from './components/admin/IncidentRecords';
//Claims
import Claims  from './components/admin/ClaimManagement';
//Records
import Records from './components/admin/Records';
//Management
import Facility from "./components/admin/Facility";
import ServicesManagement from "./components/admin/ServicesManagement";
import UserManagement from "./components/admin/UserManagement";
import CustomFields from "./components/admin/CustomFields";
import EmployeeFields from './components/admin/EmployeeFields';
import VaccineManagement  from './components/admin/VaccineManagement';
import EvaluationManagement from './components/admin/EvaluationManagement';
import TestManagement from "./components/admin/TestManagement";
import ResultsManagement from "./components/admin/ResultsManagement";
import TaskManagement from "./components/admin/TaskManagement";
import IncidentManagement from "./components/admin/IncidentManagement";
import ClaimManagement from "./components/admin/ClaimManagement";
import CommunicationTemplates from "./components/admin/CommunicationTemplates";
import DocumentUpload  from './components/admin/DocumentUpload';
import BannerManagement  from './components/admin/BannerManagement';
//Settings
import PasswordSettings  from './components/admin/PasswordSettings';
import IncidentRecords from './components/admin/IncidentRecord';
import ClaimRecords from './components/admin/ClaimRecords';
import TaskRecords from './components/admin/TaskRecords';

const swal = withReactContent(Swal);

export const CAPTCHA = "6Lc4JUAcAAAAAGPcDlen-26x_KpyZUjY7acuP4Al";

//setting defaults for sweetalert
export let sweetalert = swal.mixin({
    showCloseButton:false, // hide the 'x' at the top of the modal
    confirmButtonColor: '#21212E',      // dark blue
    cancelButtonText: 'Cancel',
    cancelButtonColor: '#808080',       // grey 
    denyButtonColor: '#dc3741',         // standard red
    allowOutsideClick: true,           // enforce user interact with modal buttons
    allowEscapeKey: false,              // enforce user interact with modal buttons
    reverseButtons: true,   // cancel left, confirm right
    // stopKeydownPropagation:false,
    keydownListenerCapture:false,
    // allowEnterKey:true,      
    showClass: {
        backdrop: 'swal2-noanimation',  // disable backdrop animation
        popup: '',                      // disable popup animation
        icon: '',                       // disable icon animation
    },
    hideClass: {
        popup: '',                     // disable popup fade-out animation
    },
    customClass: {
        container: 'swal-on-top swal2-html-container'
    }
});

function App(){
    const userContext = useContext(AuthContext);
    const [customEmployeeFields, setCustomEmployeeFields] = useState([]);
    const [demoUser, setDemoUser] = useState('Supervisor')

    useEffect(()=>{
        loadScript(`https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_PLACES_API_KEY}&libraries=places&loading=async`); 

        let currentURL = window.location.href;
        let title;
        const getProductBranding = async () => {
            let data = await SystemAPI.getProductBrandingFromURL(currentURL)
            title = data.Name;
            document.title = title
        }

        getProductBranding()

    },[]);// runs on load

    const loadScript = (src:string) => {
        var tag = document.createElement('script');     
        tag.src = src;
        document.body.appendChild(tag);
    }


    const getNavRoute = (comp:any)=>{
        return(
            <React.Fragment>
                <Nav />
                {comp}
                {/* <Footer/> */}
            </React.Fragment>
        )
    }

    const getAdminNavRoute = (comp:any, props)=>{
        document.body.style.backgroundColor = 'white'
        return (
            <> 
            { userContext && 
                <React.Fragment>
                    
                    <SkipLink targetId="main-content" label="Skip to Main Content" />
                    <AdminPanel {...props} handleDemoUser={(e) => handleDemoUser(e)} />
                    {comp}
                </React.Fragment>
            }
            </>
        )
    }

    const getLoginRoute = (comp:any)=>{
        return (
        <React.Fragment>
                <Nav />
                {comp}
            </React.Fragment>
        )
    }

    //function to set state for demoUser.  then pass demo user down to AdminHomePage

    const handleDemoUser = (demoUser) => {
        // console.log('App.tsx handleDemoUser', demoUser)
        setDemoUser(demoUser)
    }
    return (
        <BrowserRouter>
            <React.Fragment>
                <Switch>
                    <Route path="/" render={(props) => getNavRoute(<Login form_action={'login'} />)} exact />
                    <Route path="/admin" render={(props)=> getAdminNavRoute(<AdminHomePage demoUser={demoUser} />, props)} exact /> 
                    <Route path="/admin/CheckIn" render={(props) => getAdminNavRoute(<CheckIn />, props)} exact />
                    <Route path="/admin/Submission" render={(props) => getAdminNavRoute(<WalkinForm />, props)} exact />
                    <Route path="/admin/Events" render={(props) => getAdminNavRoute(<Events />, props)} exact />
                    <Route path="/admin/Alert" render={(props) => getAdminNavRoute(<Alert />, props)} exact />
                    <Route path="/admin/OSHAReport" render={(props) => getAdminNavRoute(<OSHA />, props)} exact />
                    <Route path="/admin/AuditLogs" render={(props) => getAdminNavRoute(<AuditLogs />, props)} exact />
                    <Route path="/admin/Facility" render={(props) => getAdminNavRoute(<Facility />, props)} exact />
                    <Route path="/admin/User" render={(props) => getAdminNavRoute(<UserManagement />, props)} exact />
                    <Route path="/admin/Test" render={(props) => getAdminNavRoute(<TestManagement />, props)} exact />
                    <Route path="/admin/Service" render={(props) => getAdminNavRoute(<ServicesManagement />, props)} exact />
                    <Route path="/admin/Employees" render={(props) => getAdminNavRoute(<EmployeeManagement />, props)} exact />
                    <Route path="/admin/EmployeeRecord/:uuid" render={(props) => getAdminNavRoute(<EmployeeRecord user={props.match.params.uuid} fromEmployeeMgmt={true}/>, props)} exact />
                    <Route path="/admin/CustomFields" render={(props) =>  getAdminNavRoute(<CustomFields/>, props)} exact/>
                    <Route path="/admin/EmployeeFields" render={(props) =>  getAdminNavRoute(<EmployeeFields/>, props)} exact/>
                    <Route path="/admin/Communication" render={(props) => getAdminNavRoute(<CommunicationTemplates />, props)} exact />
                    <Route path="/admin/Result" render={(props) =>  getAdminNavRoute(<ResultsManagement/>, props)} exact/>
                    <Route path="/admin/Banner" render={(props) =>  getAdminNavRoute(<BannerManagement/>, props)} exact/>
                    <Route path="/admin/PasswordRequirements" render={(props)=> getAdminNavRoute(<PasswordSettings />, props)} exact />
                    <Route path="/admin/File" render={(props)=> getAdminNavRoute(<DocumentUpload />, props)} exact />
                    <Route path="/admin/Import" render={(props)=> getAdminNavRoute(<SampleCSVImport />, props)} exact />
                    <Route path="/admin/VaccineManagement" render={(props) =>  getAdminNavRoute(<VaccineManagement/>, props)} exact/>
                    <Route path="/admin/TaskManagement" render={(props) =>  getAdminNavRoute(<TaskManagement/>, props)} exact/>
                    <Route path="/admin/IncidentManagement" render={(props) =>  getAdminNavRoute(<IncidentManagement/>, props)} exact/>
                    <Route path="/admin/ClaimManagement" render={(props) =>  getAdminNavRoute(<ClaimManagement/>, props)} exact/>
                    <Route path="/admin/EvaluationManagement" render={(props) =>  getAdminNavRoute(<EvaluationManagement/>, props)} exact/>
                    <Route path="/admin/Records" render={(props) =>  getAdminNavRoute(<Records/>, props)} exact/>
                    <Route path="/admin/Analytics" render={(props) =>  getAdminNavRoute(<Analytics/>, props)} exact/>
                    <Route path="/admin/IncidentRecords" render={(props) =>  getAdminNavRoute(<IncidentRecords />, props)} exact/>
                    <Route path="/admin/ClaimRecords" render={(props) =>  getAdminNavRoute(<ClaimRecords />, props)} exact/>
                    <Route path="/admin/TaskRecords" render={(props) =>  getAdminNavRoute(<TaskRecords />, props)} exact/>
                    {/* <Route path="/confirmation" render={(props) => getNavRoute(<Confirmation />)} exact />*/}
                    <Route path="/logout" render={(props)=> getNavRoute(<LoggedOut /> )} exact />
                    <Route path="/loggedout" render={(props) => getNavRoute(<LoggedOut />)} exact />
                    <Route path="/login" render={(props)=> getLoginRoute(<Login form_action={'login'} />) } exact />
                    <Route path="/forgotPassword" render={(props) => getLoginRoute(<ForgotPassword />)} exact/>
                    <Route path="/__/auth/:action" render={(props)=> getLoginRoute(<Login form_action={props.match.params.action} />)} />
                    <Redirect to={"/admin"} />
                </Switch>
            </React.Fragment>
        </BrowserRouter>);
}

export default App;
