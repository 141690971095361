import NetworkUtil from "./NetworkUtil";
import TestType from "../types/TestType"
import {ServerResponse} from "../types/ServerResponse";
import EvaluationType from "../types/EvaluationType";

export default class EvaluationsAPI {
    
    public static async filterEvaluation(filter):Promise<{success:boolean, data:EvaluationType[], reason:string}>{
        return await NetworkUtil.makePost("/api/admin/evaluations/filter", filter);
    }

    public static async editEvaluation(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/evaluations/edit", body);
    }

    public static async newEvaluation(body):Promise<ServerResponse>{
      return await NetworkUtil.makePost("/api/admin/evaluations/new", body);
    }

}