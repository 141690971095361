import React from 'react';
import Select from 'react-select';
import { buildRow, getBoolSelectVal, getLabel, getServiceTypeLabel, getSpecificServiceType, hideModal, getLabelForEvents, parseDate, dateWithNoTime } from '../../util/FormatUtil';
import { sweetalert } from '../../App';
import Validator from '../../validation/Validator';
import { Validators } from '../../validation/Validators';
import { isEmptyObject } from 'jquery';
import { CustomFieldBuilder } from '../../customfields/CustomFieldBuilder';
import GetField from '../../customfields/GetField';
import SystemAPI from '../../network/SystemAPI';
import RecordType from '../../types/RecordType';
import { ClaimRecord } from '../../types/Claim';

interface ClaimRecordModalState {
  selectedClaim: ClaimRecord;
  isHintActive:boolean
  hintID:number
  employee
}

interface ClaimRecordModalProps {
  selectedClaim: ClaimRecord;
  onSubmit?: (claim: ClaimRecord) => void;
  pageCustomQuestions?;
  facilities?;
  employees
  claims
}
export class ClaimRecordModal extends React.Component<ClaimRecordModalProps, ClaimRecordModalState> {
  public static ID = 'ClaimRecordModal';

  constructor(props) {
    super(props);
    this.state = {
      isHintActive: false,
      hintID: 0,
      employee: null,
      selectedClaim: {
        ID: null,
        EmployeeUID: null,
        ReqNum: null,
        FacilityID: null,
        CustomFieldData: null,
        CreatedDate: null,
      } as ClaimRecord,
    };
    this.clearFields = this.clearFields.bind(this);
  }

  componentWillReceiveProps(nextProps: Readonly<ClaimRecordModalProps>, nextContext: any) {
    // console.log('RecordsModal nextProps', nextProps);
    if (!isEmptyObject(nextProps.selectedClaim)) {
      let claim = nextProps.selectedClaim;
      this.setState({
        selectedClaim: {
          ID: claim.ID,
          ClaimID: claim.ClaimID,
          EmployeeUID: claim && claim.EmployeeUID ? claim.EmployeeUID : null,
          ReqNum: claim && claim.ReqNum ? claim.ReqNum : null,
          FacilityID: claim && claim.FacilityID ? claim.FacilityID : null,
          CustomFieldData: claim && claim.CustomFieldData && JSON.stringify(claim.CustomFieldData) !== '{}' ? JSON.parse(claim.CustomFieldData) : null,
          CreatedDate: claim && claim.CreatedDate ? claim.CreatedDate : null,
        } as ClaimRecord,
      });
    }
  }

  clearFields() {
    this.setState({
      selectedClaim: {
        ID: null,
        EmployeeUID: null,
        ReqNum: null,
        FacilityID: null,
        CustomFieldData: null,
        CreatedDate: null,
      } as ClaimRecord,
    });
  }

  render() {
    // console.log('ClaimRecordsModal props', this.props);
    // console.log('ClaimRecordsModal STATE', this.state);

    let employee = this.props.employees && this.props.employees.length > 0 ? this.props.employees.find(e => e.UID === this.state.selectedClaim.EmployeeUID) : null;
    let employeeNameString;
    if(employee){
      employeeNameString = `${employee.FirstName} ${employee.LastName} - ${dateWithNoTime(employee.DateOfBirth)} - ${employee.UID}`
    }
    

    let claim = this.props.claims && this.props.claims.length > 0 ? this.props.claims.find(f => f.value === this.state.selectedClaim.ClaimID) : null;
    let claimNameString;
    if(claim){
      claimNameString = claim.label;
    }
    
   

    return (
      <div className="modal fade form_modal" id={ClaimRecordModal.ID} tabIndex={-1} role="dialog" aria-labelledby="result_modal_label" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-xl" role="document">
          <div className="modal-content">
            <div className="container-fluid">
              <div className="modal-header">
                <h5 className="modal-title" id="result_modal_label">
                  Update Claim Records
                </h5>
                <button style={{ outline: 'none' }} type="button" onClick={() => this.clearFields()} className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body m-3">
                {buildRow(
                  'Employee Name',
                  <input
                    className="form-control"
                    id="EmployeeName"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'EmployeeName'}
                    aria-label="EmployeeName"
                    readOnly={true}
                    value={employeeNameString}
                  />
                )}
                {buildRow(
                  'Confirmation Code',
                  <input
                    className="form-control"
                    id="ConfirmationCode"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'ConfirmationCode'}
                    aria-label="ConfirmationCode"
                    readOnly={true}
                    value={this.state.selectedClaim.ReqNum ? this.state.selectedClaim.ReqNum : ''}
                  />
                )}
                 {/* {buildRow(
                //   'Facility',
                //   <input
                //     className="form-control"
                //     id="Facility"
                //     maxLength={500}
                //     autoComplete="off"
                //     type={'search'}
                //     name={'Facility'}
                //     aria-label="Facility"
                //     readOnly={true}
                //     //@ts-ignore
                //     value={this.state.selectedClaim && this.state.selectedClaim.FacilityID ? getLabel(this.state.selectedClaim.FacilityID, this.props.facilities).label : ''}
                //   />
                )}*/}

                {buildRow(
                  'Claim',
                  <input
                    className="form-control"
                    id="Claim"
                    maxLength={500}
                    autoComplete="off"
                    type={'search'}
                    name={'Claim'}
                    aria-label="Claim"
                    readOnly={true}
                    value={claimNameString}
                  />
                )}

                {/*Custom Questionnaire Data*/}

                {
                                (this.props.pageCustomQuestions)
                                    ?
                                    <div className="row">
                                        {this.props.pageCustomQuestions.map((val, index) => {
                                            if(val.Options.isActive){
                                                return (
                                                    <div className="col-12">
                                                        <div className="form-group row">
                                                            <GetField  
                                                            val={val} 
                                                            selectedItem={this.state.selectedClaim ? this.state.selectedClaim : null} 
                                                            handleChange={(e) => {
                                                                this.setState({selectedClaim: e})
                                                            }}  
                                                            />
                                                        </div>
                                                        <div className={this.state.isHintActive && val.ID === this.state.hintID ? "form-group row" : "d-none"}>
                                                            <div className="col-12 col-md-4">
                                                                <label className={"col-form-label"} htmlFor={'Hint'} style={{paddingTop: '0.5em', }}>Hint</label>
                                                            </div>
                                                            <div className="col-12 col-md-8 pl-0">
                                                                {val.Options.hint}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            } 
                                            else {
                                                return <></>
                                            }
                                        })}
                                    </div>
                                    :
                                    <div className="invisible"></div>
                            }
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={async () => {
                    let formValidation = {
                      EmployeeUID: this.state.selectedClaim && this.state.selectedClaim.EmployeeUID ? this.state.selectedClaim.EmployeeUID : null,
                      ReqNum: this.state.selectedClaim && this.state.selectedClaim.ReqNum ? this.state.selectedClaim.ReqNum : null,
                      FacilityID: this.state.selectedClaim && this.state.selectedClaim.FacilityID ? this.state.selectedClaim.FacilityID : null,
                      CustomFieldData: this.state.selectedClaim && this.state.selectedClaim.CustomFieldData ? this.state.selectedClaim.CustomFieldData : null,
                      CreatedDate: this.state.selectedClaim && this.state.selectedClaim.CreatedDate ? this.state.selectedClaim.CreatedDate : null,
                    };

                    let validator = new Validator<any>();
                    validator
                      .withSimpleValidation('EmployeeUID', Validators.requireNonNullValidator('Employee UID'))
                      .withSimpleValidation('ReqNum', Validators.requireNonNullValidator('Confirmation Code'))
                      .withSimpleValidation('FacilityID', Validators.requireNonNullValidator('Facility ID'));

                    let validationResponse = validator.validate(formValidation);
                    if (!validationResponse.success) {
                      return sweetalert.fire({ icon: 'error', title: '', text: validationResponse.error });
                    }

                    let invalid = CustomFieldBuilder.validateCustomFields(this.state.selectedClaim.CustomFieldData, this.props.pageCustomQuestions, this.state.selectedClaim)

                    if(invalid){
                        return;
                    }

                    hideModal(ClaimRecordModal.ID);
                    this.props.onSubmit({
                      ID: this.state.selectedClaim.ID,
                      EmployeeUID: formValidation.EmployeeUID,
                      ReqNum: formValidation.ReqNum,
                      FacilityID: formValidation.FacilityID,
                      CustomFieldData: JSON.stringify(formValidation.CustomFieldData),
                      CreatedDate: formValidation.CreatedDate,
                      RecordType: 'Claim'
                    } as ClaimRecord);
                    this.setState({
                      selectedClaim: {
                        ID: null,
                        EmployeeUID: null,
                        ReqNum: null,
                        FacilityID: null,
                        CustomFieldData: null,
                        CreatedDate: null,
                      } as ClaimRecord,
                    });
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
