import React from "react";
import Select from "react-select";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
// import TimePicker from 'react-time-picker';
// import DatePicker from "react-date-picker";
import DatePicker from 'react-datepicker'
import { CustomFields } from "../types/CustomFieldType";
import { getCustomFieldDataSelectLabel, getValueArray } from "../util/FormatUtil";


interface GetFieldProps{
    val
    selectedItem
    handleChange
    fromSamples?
    employeeSearchReadOnly?
    fromEmployeeMgmt?
}
interface GetFieldState{
    selectedItem
    rawDateInput
}
export default class GetField extends React.Component<GetFieldProps,GetFieldState>{

    constructor(props: GetFieldProps){
        super(props)
        this.state = {
            selectedItem: {},
            rawDateInput: '',
        }

    }

    componentWillReceiveProps(nextProps: Readonly<GetFieldProps>, nextContext: any) {
        // console.log('nextProps in GetField', nextProps)
        if(nextProps.selectedItem){
            this.setState({
                selectedItem: nextProps.selectedItem,
                rawDateInput: nextProps.selectedItem.CustomFieldData && nextProps.selectedItem.CustomFieldData[nextProps.val.DisplayName] ? nextProps.selectedItem.CustomFieldData[nextProps.val.DisplayName].answer : ''
            })
        }
    }

    getField(val){
        let customFieldDataCopy = this.props.selectedItem?.CustomFieldData ? JSON.parse(JSON.stringify(this.props.selectedItem.CustomFieldData)) : {};
        let fieldTypeID = val.FieldTypeID;
        if(fieldTypeID === CustomFields.TEXT_FIELD || fieldTypeID === CustomFields.EMAIL_FIELD){
            return(
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-md-2" : "row mr-md-0 ml-md-0"}>
                            <div className="col-12 p-md-0">
                                <input type={"search"}
                                    id={val.DisplayName.replaceAll(' ', '')}
                                    readOnly={this.props.employeeSearchReadOnly && !this.props.fromEmployeeMgmt}
                                    maxLength={val.Options.maxLength}
                                    aria-label={val.DisplayName}
                                    aria-required={val.Options.isRequired}
                                    className={'form-control'}
                                    value={this.props.selectedItem?.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName].answer : ''}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        customFieldDataCopy[val.DisplayName] = {answer: e.target.value, serviceType: val.Options.serviceType}
                                        this.setState( (prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }), 
                                        ()=> this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if(fieldTypeID === CustomFields.SELECT_FIELD){
            return(
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <Select
                                    id={val.ID}
                                    isDisabled={this.props.employeeSearchReadOnly && !this.props.fromEmployeeMgmt}
                                    isMulti={val.Options.isMulti}
                                    aria-label={val.DisplayName}
                                    isSearchable={true}
                                    placeholder={<div className="accessibilityText">Please Select...</div>}
                                    className={"state_select"}
                                    options={val.Options.options}
                                    value={this.props.selectedItem?.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? getCustomFieldDataSelectLabel(this.props.selectedItem.CustomFieldData[val.DisplayName].answer, val) : ''}
                                    onChange={ (e)=> {
                                        customFieldDataCopy[val.DisplayName] = val.Options.isMulti ? {answer: getValueArray(e), serviceType: val.Options.serviceType} : {answer: e.value, serviceType: val.Options.serviceType};
                                        this.setState( (prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }), 
                                        ()=> this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if(fieldTypeID === CustomFields.DATE_FIELD){
            return(
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label id={val.DisplayName.replaceAll(' ', '')} className={"col-form-label"} htmlFor={val.DisplayName} style={{paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8 pl-0" : "col-12 col-md-8 pl-3 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                            <input 
                                id={val.ID}
                                disabled={this.props.employeeSearchReadOnly && !this.props.fromEmployeeMgmt}
                                readOnly={this.props.employeeSearchReadOnly && !this.props.fromEmployeeMgmt}
                                aria-labelledby={val.DisplayName.replaceAll(' ', '')} 
                                className={"form-control"}
                                autoComplete={"off"}
                                type={"date"}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    this.setState({rawDateInput: value});
                                }}
                                onBlur={(e) => {
                                    const value = e.target.value;

                                     // Regular expression to check for YYYY-MM-DD format
                                    const datePattern = /^\d{4}-\d{2}-\d{2}$/;

                                    if (datePattern.test(value)) {
                                        // If the date format is correct, update the state
                                        let customFieldDataCopy = { ...this.state.selectedItem.CustomFieldData };
                                        customFieldDataCopy[val.DisplayName] = {answer: value, serviceType: val.Options.serviceType};

                                        this.setState(prevState => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            },
                                            rawDateInput: value
                                        }), () => {
                                            this.props.handleChange(this.state.selectedItem);
                                        });
                                    } else {
                                        console.error('Invalid date format:', value);
                                        this.setState({ rawDateInput: '' });
                                    }
                                }}
                                value={this.state.rawDateInput}
                            />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if(fieldTypeID === CustomFields.TEXTAREA_FIELD){
            return(
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <textarea
                                    id={val.ID}
                                    readOnly={this.props.employeeSearchReadOnly && !this.props.fromEmployeeMgmt}
                                    maxLength={val.Options.maxLength}
                                    rows={3}
                                    className={'form-control'}
                                    aria-label={val.DisplayName}
                                    aria-required={val.Options.isRequired}
                                    value={this.props.selectedItem?.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName].answer : ''}
                                    autoComplete="off"
                                    onChange={(e) =>{
                                        customFieldDataCopy[val.DisplayName] = {answer: e.target.value, serviceType: val.Options.serviceType}
                                        this.setState( (prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }), 
                                        ()=> this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                        <div className="row pt-1 pr-3 justify-content-end">
                            <div className={this.props.selectedItem?.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] && this.props.selectedItem.CustomFieldData[val.DisplayName].answer.length > 0 ? 'visible' : 'invisible'}>
                                <div style={{fontSize: '0.8em'}}>{this.props.selectedItem?.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] && this.props.selectedItem.CustomFieldData[val.DisplayName].answer.length}/{val.Options.maxLength}</div>
                            </div>
                        </div>
                    </div>
                </>
            )

        }
        else if(fieldTypeID === CustomFields.NUMBER_FIELD){
            return(
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <input type={"number"}
                                    id={val.ID}
                                    readOnly={this.props.employeeSearchReadOnly && !this.props.fromEmployeeMgmt}
                                    min={val.Options.min}
                                    max={val.Options.max}
                                    aria-label={val.DisplayName}
                                    aria-required={val.Options.isRequired}
                                    className={'form-control'}
                                    value={this.props.selectedItem?.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName].answer : ''}
                                    autoComplete="off"
                                    onChange={(e) =>{
                                        customFieldDataCopy[val.DisplayName] = {answer: e.target.value, serviceType: val.Options.serviceType}
                                        this.setState( (prevState) => ({
                                        selectedItem: {
                                            ...prevState.selectedItem,
                                            CustomFieldData: customFieldDataCopy
                                        }
                                    }), 
                                    ()=> this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else if(fieldTypeID === CustomFields.PHONE_FIELD){
            return(
                <>
                    <div className={"col-12 col-md-4"} data-toggle={'tooltip'} data-placement={'top'} title={val.Options.popoverText}>
                        <label className={"col-form-label"} htmlFor={val.DisplayName} style={{paddingTop: '0.5em', }}>{val.DisplayName}</label>
                    </div>
                    <div className={this.props.fromSamples ? "col-12 col-md-8" : "col-12 col-md-8 pl-md-0 pr-md-1"} id={val.DisplayName}>
                        <div className={this.props.fromSamples ? "row mr-2" : "row mr-0 ml-0"}>
                            <div className="col-12 p-0">
                                <PhoneInput
                                    id={val.ID}
                                    readOnly={this.props.employeeSearchReadOnly && !this.props.fromEmployeeMgmt}
                                    placeholder="Enter phone number"
                                    defaultCountry="US"
                                    aria-label={val.DisplayName}
                                    value={this.props.selectedItem?.CustomFieldData && this.props.selectedItem.CustomFieldData[val.DisplayName] ? this.props.selectedItem.CustomFieldData[val.DisplayName].answer : ''}
                                    onChange={(e) =>{
                                        customFieldDataCopy[val.DisplayName] = {answer: e, serviceType: val.Options.serviceType}
                                        this.setState( (prevState) => ({
                                            selectedItem: {
                                                ...prevState.selectedItem,
                                                CustomFieldData: customFieldDataCopy
                                            }
                                        }), 
                                        ()=> this.props.handleChange(this.state.selectedItem))
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </>
            )
        }
    }

    render() {
        // console.log('GetField state', this.state)
        return (
            <>
            {this.getField(this.props.val)}
            </>
        )
    }
}








