// i18n.js
import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import HttpBackend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Sample translation resources directly in the configuration for simplicity.
// In production, you might want to load these from separate files.

const resources = {
  //English
  en: {
    translation: {
      welcomeMessage: "Welcome to the <bold>{{productName}} Portal</bold>",
      registerMessage: "Register here!",
      logInButtonText: "Log In",

      "1 uppercase (A-Z) ": "1 uppercase (A-Z) ",
      "1 symbol (!@#$%^&*) ": "1 symbol (!@#$%^&*) ",
      "1 digits (0-9)": "1 digits (0-9)",

      "Password should contain at least 10 characters and include 1 uppercase (A-Z) 1 symbol (!@#$%^&*) 1 digits (0-9)":
        "Password should contain at least 10 characters and include 1 uppercase (A-Z) 1 symbol (!@#$%^&*) 1 digits (0-9)",

      "User Role is not a Public role. Please sign into our Admin portal.":
        "User Role is not a Public role. Please sign into our Admin portal.",
      requestAnEmemption: "Request an Exemption",
      Email: "Email",
      Password: "Password",
      Login: "Login",
      "Keep Me Signed In": "Keep Me Signed In",
      Register: "Register",
      "Forgot Password": "Forgot Password",
      "Phone Number": "Phone Number",
      "New Password": "New Password",
      "Confirm Password": "Confirm Password",
      "Passwords do not match": "Passwords do not match",
      "First Name": "First Name",
      "Last Name": "Last Name",
      "Reset Password": "Reset Password",
      "Enroll MFA": "Enroll MFA",
      "Password should contain at least 10 characters":
        "Password should contain at least 10 characters",
      "Password should contain at least 10 characters ":
        "Password should contain at least 10 characters ",
      "Password should contain at least {{char}} characters ":
        "Password should contain at least {{char}} characters ",
      "and include ": "and include ",
      "Warning: too many failed log in attempts will temporarily disable your account.":
        "Warning: too many failed log in attempts will temporarily disable your account.",
      "The provided value for the password is invalid.":
        "The provided value for the password is invalid.",
      "There is no user record corresponding to the provided email.":
        "There is no user record corresponding to the provided email.",
      "The provided email is already in use by an existing user.":
        "The provided email is already in use by an existing user.",
      "The provided value for the email property is invalid.":
        "The provided value for the email property is invalid.",
      "The provided phone number is already in use by an existing user.":
        "The provided phone number is already in use by an existing user.",
      "The provided verification code is invalid.":
        "The provided verification code is invalid.",
      "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.":
        "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.",
      "Please log in to re-authenticate your session":
        "Please log in to re-authenticate your session",
      "This account had been disabled.  Please contact an adminstrator.":
        "This account had been disabled.  Please contact an adminstrator.",
      "Error with login. ": "Error with login. ",
      "Please try again later.": "Please try again later.",
      "Please reset your password.  ":
        "Please reset your password. {{pswdConfigs.pattern.message}} ",
      "Please enter your phone number to enroll in multi-factor verification.":
        "Please enter your phone number to enroll in multi-factor verification.",
      "Please enter the 6-digit verification code to continue":
        "Please enter the 6-digit verification code to continue",
      "Please enter the 6-digit verification code":
        "Please enter the 6-digit verification code",
      "Invalid action code. Please try resetting your password again.":
        "Invalid action code. Please try resetting your password again.",
      "Unable to confirm password reset for username: ":
        "Unable to confirm password reset for username: {{form.username}}",
      "Thank you. Please login with your new password.":
        "Thank you. Please login with your new password.",
      "Unable to confirm password reset. Please try resetting your password again.":
        "Unable to confirm password reset. Please try resetting your password again.",
      "Unable to reset password. Try again later.":
        "Unable to reset password. Try again later.",
      "Thank you. Your password has been updated.":
        "Thank you. Your password has been updated.",
      "Please log in to re-authenticate your session.":
        "Please log in to re-authenticate your session.",
      "Failed to send MFA Enrollment email":
        "Failed to send MFA Enrollment email",
      "Thank you for registering.": "Thank you for registering.",
      "Please take the time to fill out your profile information before submitting a request for vaccine exemption.":
        "Please take the time to fill out your profile information before submitting a request for vaccine exemption.",
      "Registration could not be completed at this time. Please try again later.":
        "Registration could not be completed at this time. Please try again later.",
      "Email is Required": "Email is Required",
      "Please verify email syntax.": "Please verify email syntax.",
      "Password is Required": "Password is Required",
      "Password is Required.": "Password is Required.",
      "Enter phone number": "Enter phone number",
      "Invalid Phone Number": "Invalid Phone Number",
      "Phone Number is Required": "Phone Number is Required",
      "First Name is Required": "First Name is Required",
      "Last Name is Required": "Last Name is Required",
      "Please check your email for instructions.":
        "Please check your email for instructions.",
      "Username/Email": "Username/Email",
      "Username/Email is required": "Username/Email is required",
      "Logged out successfully": "Logged out successfully",
      "Log In": "Log In",
      copyright:
        "Copyright {{currentYear}} by IMMY Technology Group. All Rights Reserved.",
      "Log Out": "Log Out",
      "Submit Vaccine Exemptions": "Submit Vaccine Exemptions",
      "View Vaccine Exemptions": "View Vaccine Exemptions",
      "Update Profile": "Update Profile",
      Profile: "Profile",
      PasswordReset: "PasswordReset",
      "Vaccine Exemption Form": "Vaccine Exemption Form",
      "Type Of Exemption": "Type Of Exemption",
      "Please Select...": "Please Select...",
      "Please select which Immunizations this exemption applies to:":
        "Please select which Immunizations this exemption applies to:",
      "Parental decision": "Parental decision",
      "Religious exemption": "Religious exemption",
      Other: "Other",
      "Personal/Employee decision": "Personal/Employee decision",
      "Medical decision": "Medical decision",
      "Immunization(s) that are medically contraindicated":
        "Immunization(s) that are medically contraindicated",
      "Immunization(s)": "Immunization(s)",
      "First Name of Religious Leader or Parent/Guardian":
        "First Name of Religious Leader or Parent/Guardian",
      "Last Name of Religious Leader or Parent/Guardian":
        "Last Name of Religious Leader or Parent/Guardian",
      "Summary of Objection": "Summary of Objection",
      "Condition that would endanger the life or health of the child":
        "Condition that would endanger the life or health of the child",
      "Physician First Name": "Physician First Name",
      "Physician Last Name": "Physician Last Name",
      "Physician Address": "Physician Address",
      "Physician Phone Number": "Physician Phone Number",
      "Child Information": "Child Information",
      Review: "Review",
      Start: "Start",
      "Parent/ Guardian Information": "Parent/ Guardian Information",
      "School/ Childcare Information": "School/ Childcare Information",
      Acknowledgement: "Acknowledgement",
      "I understand that in the event of a disease outbreak in the School, Child Care Facility or Head Start, my Child may have to be excluded for his/ her protection and for the protection of the other children in the School, Child Care Facility or Head Start":
        "I understand that in the event of a disease outbreak in the School, Child Care Facility or Head Start, my Child may have to be excluded for his/ her protection and for the protection of the other children in the School, Child Care Facility or Head Start",
      Submit: "Submit",
      "Physician Approval Letter": "Physician Approval Letter",
      "You must select an Exemption Type": "You must select an Exemption Type",
      "You must complete child information":
        "You must complete child information",
      "You must complete parent information":
        "You must complete parent information",
      "You must complete school information":
        "You must complete school information",
      "You must acknowledge all policies listed in this form":
        "You must acknowledge all policies listed in this form",
      "*An Exemption must be completed for each child":
        "*An Exemption must be completed for each child",
      "First Name Of Child": "First Name Of Child",
      "Middle Initial": "Middle Initial",
      "Middle Initial Of Child": "Middle Initial Of Child",
      "Last Name Of Child": "Last Name Of Child",
      "Date of Birth": "Date of Birth",
      "Child Date of Birth": "Child Date of Birth",
      "Birth Country": "Birth Country",
      Gender: "Gender",
      "Child Gender": "Child Gender",
      Ethnicity: "Ethnicity",
      "Child Ethnicity": "Child Ethnicity",
      "Race (Limit 3)": "Race (Limit 3)",
      "Child Race": "Child Race",
      Save: "Save",
      Male: "Male",
      Female: "Female",
      Unknown: "Unknown",
      "American Indian or Alaska Native": "American Indian or Alaska Native",
      Asian: "Asian",
      "Black or African American": "Black or African American",
      "Native Hawaiian or Other Pacific Islander":
        "Native Hawaiian or Other Pacific Islander",
      White: "White",
      "Hispanic or Latino": "Hispanic or Latino",
      "Not Hispanic or Latino": "Not Hispanic or Latino",
      "First Name cannot be empty.": "First Name cannot be empty.",
      "Last Name cannot be empty.": "Last Name cannot be empty.",
      "Date of Birth cannot be empty.": "Date of Birth cannot be empty.",
      "Date of Birth cannot be in the future.":
        "Date of Birth cannot be in the future.",
      "Date of Birth can not be more than 20 years ago.":
        "Date of Birth can not be more than 20 years ago.",
      "Birth Country cannot be empty.": "Birth Country cannot be empty.",
      "Birth State cannot be empty.": "Birth State cannot be empty.",
      "Gender cannot be empty.": "Gender cannot be empty.",
      "Race cannot be empty.": "Race cannot be empty.",
      "Ethnicity cannot be empty.": "Ethnicity cannot be empty.",
      "Mother's Maiden Name cannot be empty.":
        "Mother's Maiden Name cannot be empty.",
      "Relationship cannot be empty.": "Relationship cannot be empty.",
      "Address cannot be empty.": "Address cannot be empty.",
      "City cannot be empty.": "City cannot be empty.",
      "City cannot be blank.": "City cannot be blank.",
      "Zipcode cannot be empty.": "Zipcode cannot be empty.",
      "Zipcode cannot be blank.": "Zipcode cannot be blank.",
      "Zipcode is not a valid zipcode.": "Zipcode is not a valid zipcode.",
      "County cannot be empty.": "County cannot be empty.",
      "County cannot be blank.": "County cannot be blank.",
      "State cannot be empty.": "State cannot be empty.",
      "Email must be a valid email address.":
        "Email must be a valid email address.",
      "Email cannot be blank.": "Email cannot be blank.",
      "Email cannot be longer than 100.": "Email cannot be longer than 100.",
      "Email cannot include the & symbol, if needed, please use the word 'And'":
        "Email cannot include the & symbol, if needed, please use the word 'And'",
      "Phone cannot be empty.": "Phone cannot be empty.",
      "Phone cannot be blank.": "Phone cannot be blank.",
      "Phone is not a valid phone number.":
        "Phone is not a valid phone number.",
      "First Name Of Parent": "First Name Of Parent",
      "Middle Initial Of Parent": "Middle Initial Of Parent",
      "Last Name Of Parent": "Last Name Of Parent",
      Relationship: "Relationship",
      "Relationship to Child": "Relationship to Child",
      "Mother's Maiden Name": "Mother's Maiden Name",
      "Street Address": "Street Address",
      "Parent Street Address": "Parent Street Address",
      County: "County",
      "Parent County": "Parent County",
      City: "City",
      "Parent City": "Parent City",
      State: "State",
      Zip: "Zip",
      "Parent Zipcode": "Parent Zipcode",
      "Parent Email": "Parent Email",
      "Cell Phone": "Cell Phone",
      Associate: "Associate",
      Brother: "Brother",
      "Care giver": "Care giver",
      Child: "Child",
      "Handicapped dependent": "Handicapped dependent",
      "Life partner": "Life partner",
      "Emergency contact": "Emergency contact",
      Employee: "Employee",
      Employer: "Employer",
      "Extended family": "Extended family",
      "Foster child": "Foster child",
      Friend: "Friend",
      Father: "Father",
      Grandchild: "Grandchild",
      Guardian: "Guardian",
      Grandparent: "Grandparent",
      Manager: "Manager",
      Mother: "Mother",
      "Natural child": "Natural child",
      None: "None",
      "Other adult": "Other adult",
      Owner: "Owner",
      Parent: "Parent",
      Stepchild: "Stepchild",
      Self: "Self",
      Sibling: "Sibling",
      Sister: "Sister",
      Spouse: "Spouse",
      Trainer: "Trainer",
      "Ward of court": "Ward of court",
      "Your exemption has been submitted. You will receive an email and text shortly. NOTE: An exemption must be submitted for each child.":
        "Your exemption has been submitted. You will receive an email and text shortly. NOTE: An exemption must be submitted for each child.",
      "Server Error": "Server Error",
      "Please try again!": "Please try again!",
      "Vaccine Records": "Vaccine Records",
      "No option": "No option",
      Immunizations: "Immunizations",
      "Submission Files Review": "Submission Files Review",
      "Service Information Start": "Service Information Start",
      "Child Information Review": "Child Information Review",
      "Child Information Start": "Child Information Start",
      "Parent Information Review": "Parent Information Review",
      "Parent Information Start": "Parent Information Start",
      "School Information Review": "School Information Review",
      "School Information Start": "School Information Start",
      Close: "Close",
      "Name cannot be empty.": "Name cannot be empty.",
      "District cannot be empty.": "District cannot be empty.",
      "School Year cannot be empty.": "School Year cannot be empty.",
      "Grade cannot be empty.": "Grade cannot be empty.",
      "Name of School": "Name of School",
      "School District": "School District",
      Year: "Year",
      Grade: "Grade",
      "School Phone": "School Phone",
      Childcare: "Childcare",
      Headstart: "Headstart",
      "Pre-K": "Pre-K",
      Kindergarten: "Kindergarten",
      "Physician Approval Letter Upload": "Physician Approval Letter Upload",
      "Selected files to upload will not be saved":
        "Selected files to upload will not be saved",
      "Remove Files": "Remove Files",
      "Go Back": "Go Back",
      "Selected File": "Selected File",
      "Drop files here or click to select files":
        "Drop files here or click to select files",
      FileDrop: "FileDrop",
      "Unable to filter Results data at this time":
        "Unable to filter Results data at this time",
      "Exemption Type": "Exemption Type",
      "Child First Name": "Child First Name",
      "Child Last Name": "Child Last Name",
      "School Year": "School Year",
      "Vaccine Exemptions": "Vaccine Exemptions",
      "Exemption Records": "Exemption Records",
      Search: "Search",
      Exemptions: "Exemptions",
      Total: "Total",
      Page: "Page",
      "Table Page Number": "Table Page Number",
      Name: "Name",
      "Click to Sort By Name": "Click to Sort By Name",
      "Click to Sort By Exemption Type": "Click to Sort By Exemption Type",
      Status: "Status",
      "Click to Sort By Status": "Click to Sort By Status",
      Approved: "Approved",
      Pending: "Pending",
      Denied: "Denied",
      Certificate: "Certificate",
      "View Certificate": "View Certificate",
      "File not found": "File not found",
      "Could not download file. Please try again.":
        "Could not download file. Please try again.",
      "Exemption Record": "Exemption Record",
      "Reason for Denial": "Reason for Denial",
      "Confirmation Code": "Confirmation Code",
      Exemption: "Exemption",
      "Immunizations for Exemption": "Immunizations for Exemption",
      "Contraindicated Immunizations": "Contraindicated Immunizations",
      "Child Harmful Health Condition": "Child Harmful Health Condition",
      "Physician Name": "Physician Name",
      "Physician Phone": "Physician Phone",
      "Religious Leader or Parent/Guardian":
        "Religious Leader or Parent/Guardian",
      "Child Name": "Child Name",
      "Child Birth Location": "Child Birth Location",
      "Parent Name": "Parent Name",
      "Mother Maiden Name": "Mother Maiden Name",
      "Parent Address": "Parent Address",
      "Parent Phone": "Parent Phone",
      School: "School",
      "School Name": "School Name",
      "School Grade": "School Grade",
      "Immunizations that are harmful to the child":
        "Immunizations that are harmful to the child",
      "School/ Childcare": "School/ Childcare",
      "Name of Childcare": "Name of Childcare",
      "First Name cannot be blank.": "First Name cannot be blank.",
      "First Name cannot be longer than 50.":
        "First Name cannot be longer than 50.",
      "Middle Name cannot be blank.": "Middle Name cannot be blank.",
      "Middle Name cannot be longer than 50.":
        "Middle Name cannot be longer than 50.",
      "Last Name cannot be blank.": "Last Name cannot be blank.",
      "Last Name cannot be longer than 50.":
        "Last Name cannot be longer than 50.",
      "Guardian First Name cannot be blank.":
        "Guardian First Name cannot be blank.",
      "Guardian First Name cannot be longer than 50.":
        "Guardian First Name cannot be longer than 50.",
      "Guardian Last Name cannot be blank.":
        "Guardian Last Name cannot be blank.",
      "Guardian Last Name cannot be longer than 50.":
        "Guardian Last Name cannot be longer than 50.",
      "Date of Birth can not be more than 150 years ago.":
        "Date of Birth can not be more than 150 years ago.",
      "Address cannot be longer than 500.":
        "Address cannot be longer than 500.",
      "Street Address cont. cannot be empty.":
        "Street Address cont. cannot be empty.",
      "Street Address cont. cannot be longer than 500.":
        "Street Address cont. cannot be longer than 500.",
      "City cannot be longer than 100.": "City cannot be longer than 100.",
      "County cannot be longer than 100.": "County cannot be longer than 100.",
      "Country cannot be empty.": "Country cannot be empty.",
      "EmployeeRace cannot be empty.": "EmployeeRace cannot be empty.",
      "Profile saved": "Profile saved",
      "Profile Management": "Profile Management",
      "Middle Name": "Middle Name",
      Race: "Race",
      "Street Address cannot be blank.": "Street Address cannot be blank.",
      "Street Address cont.": "Street Address cont.",
      Zipcode: "Zipcode",
      Country: "Country",
      "Treatment Facility Address": "Treatment Facility Address",
      "Injury and Illness Incident Report (Form 301)":
        "Injury and Illness Incident Report (Form 301)",
      "Employee First Name": "Employee First Name",
      "Employee Last Name": "Employee Last Name",
      "Employee Search": "Employee Search",
      "The first name of the employee": "The first name of the employee",
      "The last name of the employee": "The last name of the employee",
      "The gender of the employee": "The gender of the employee",
      "The date of birth of the employee": "The date of birth of the employee",
      "Employee Title": "Employee Title",
      "The title of the employee": "The title of the employee",
      "Employee Email": "Employee Email",
      "The email of the employee": "The email of the employee",
      Phone: "Phone",
      "The phone number of the employee": "The phone number of the employee",
      "Employee Hire Date": "Employee Hire Date",
      "The date employee was hired": "The date employee was hired",
      Incident: "Incident",
      "Case Number": "Case Number",
      "The case number": "The case number",
      "Case Classification": "Case Classification",
      "Injury Type": "Injury Type",
      "Returned to Full Duty": "Returned to Full Duty",
      "The date of injury": "The date of injury",
      Facility: "Facility",
      "Has employee returned to full duty?":
        "Has employee returned to full duty?",
      "Activity Before Incident": "Activity Before Incident",
      "Time employee began work": "Time employee began work",
      "Time Work Began": "Time Work Began",
      "What was the employee doing just before the incident occurred?":
        "What was the employee doing just before the incident occurred?",
      "Incident Description": "Incident Description",
      "Time of Event": "Time of Event",
      "What happened? How did the injury occur?":
        "What happened? How did the injury occur?",
      "Injury Description": "Injury Description",
      "What was the injury or illness?": "What was the injury or illness?",
      "Object or Substance Involved": "Object or Substance Involved",
      "What object or substance directly harmed the employee?":
        "What object or substance directly harmed the employee?",
      "Location of Event": "Location of Event",
      "Provider First Name": "Provider First Name",
      "First name of health care provider":
        "First name of health care provider",
      "Provider Last Name": "Provider Last Name",
      "Last name of health care provider": "Last name of health care provider",
      "Treatment Facility Name": "Treatment Facility Name",
      "Treated in Emergency Room?": "Treated in Emergency Room?",
      "Mark 'Yes' if employee was treated in emergency room":
        "Mark 'Yes' if employee was treated in emergency room",
      "Hospitalized overnight?": "Hospitalized overnight?",
      "Mark 'Yes' if employee was hospitalized overnight":
        "Mark 'Yes' if employee was hospitalized overnight",
      "Date of Injury": "Date of Injury",
      "Date of Death": "Date of Death",
      "Did Employee Die?": "Did Employee Die?",
      "Return to Full Duty Date": "Return to Full Duty Date",
      Treatment: "Treatment",
      "Form Completed By": "Form Completed By",
      "Name of who completed the form": "Name of who completed the form",
      Title: "Title",
      "Title of who completed the form": "Title of who completed the form",
      "The phone number of who completed the form":
        "The phone number of who completed the form",
      Date: "Date",
      "The date the form was completed": "The date the form was completed",
      "Please complete the form before submitting":
        "Please complete the form before submitting",
      "Return to Full Duty and Did Employee Die cannot both be true":
        "Return to Full Duty and Did Employee Die cannot both be true",
      "If Return to Full Duty is true, Employee Death Date must be empty":
        "If Return to Full Duty is true, Employee Death Date must be empty",
      "If Did Employee Die is true, Return to Full Duty Date must be empty":
        "If Did Employee Die is true, Return to Full Duty Date must be empty",
      "Time Work Began must be earlier than Time of Event":
        "Time Work Began must be earlier than Time of Event",
      "Time of Event cannot be in the future":
        "Time of Event cannot be in the future",
      "Update Report": "Update Report",
      "Submit Report": "Submit Report",
      OSHA: "OSHA",
      "OSHA Reporting": "OSHA Reporting",
      "Need help?": "Need help?",
      "Search for employee": "Search for employee",
      "Search for facility": "Search for facility",
      "Search for case classification": "Search for case classification",
      "Search for injury type": "Search for injury type",
      "Date of Injury Range": "Date of Injury Range",
      "Injury date range to filter by": "Injury date range to filter by",
      Clear: "Clear",
      "New Report": "New Report",
      "Summary (Form 300A)": "Summary (Form 300A)",
      "Search for summary facility": "Search for summary facility",
      SummaryFacility: "SummaryFacility",
      "Annual Average Number of Employees":
        "Annual Average Number of Employees",
      "Total Hours Worked By All Employees Last Year":
        "Total Hours Worked By All Employees Last Year",
      "Summary Date Range": "Summary Date Range",
      "Date range for summary report (Form 300A).":
        "Date range for summary report (Form 300A).",
      "Generate Summary": "Generate Summary",
      "Log (Form 300)": "Log (Form 300)",
      "Export to CSV": "Export to CSV",
      "Total Records": "Total Records",
      "Employee Name": "Employee Name",
      "Employee DOB": "Employee DOB",
      "Case #": "Case #",
      "Injury Date": "Injury Date",
      "Submitted By": "Submitted By",
      "Begin date must be before end date":
        "Begin date must be before end date",
      "No reports found": "No reports found",
      "Search failed. Please try again.": "Search failed. Please try again.",
      "Log of Work-Related Injuries and Illnesses (Form 300).xlsx":
        "Log of Work-Related Injuries and Illnesses (Form 300).xlsx",
      "Report updated successfully.": "Report updated successfully.",
      "Report created successfully.": "Report created successfully.",
      "Could not Update report at this time.":
        "Could not Update report at this time.",
      "Could not Create report at this time.":
        "Could not Create report at this time.",
      "Summary Facility needed to generate Summary (Form 300A)":
        "Summary Facility needed to generate Summary (Form 300A)",
      "Summary Date Range needed to generate Summary (Form 300A)":
        "Summary Date Range needed to generate Summary (Form 300A)",
      "Summary begin date must be before end date":
        "Summary begin date must be before end date",
      "Final Report requires Annual Average Number of Employees and Total Hours Worked By All Employees Last Year":
        "Final Report requires Annual Average Number of Employees and Total Hours Worked By All Employees Last Year",
      "Would you like to generate a draft report?":
        "Would you like to generate a draft report?",
      "Generate Draft": "Generate Draft",
      Cancel: "Cancel",
      "Away From Work": "Away From Work",
      "Job Transfer Or Restriction": "Job Transfer Or Restriction",
      "Other Recordable Case": "Other Recordable Case",
      Injury: "Injury",
      "Skin disorder": "Skin disorder",
      "Repiratory condition": "Repiratory condition",
      Poisoning: "Poisoning",
      "Hearing loss": "Hearing loss",
      "Other illness": "Other illness",
      Begin: "Begin",
      End: "End",

      requireNonNullValidator: "{{field}} cannot be empty.",
      requireNotFutureDate: "{{field}} cannot be in the future.",
      requireNotBlankValidator: "{{field}} cannot be blank.",
      requireNotBeforeDate: "{{field}} cannot be before {{beforeDateString}}.",
      requireNotAfterDate: "{{field}} cannot be after {{afterDateString}}.",
      fields: {
        username: "Username",
        email: "Email",
        password: "Password",
        DateOfInjury: "Date Of Injury",
        "Date Of Injury": "Date Of Injury",
        "Case Classification": "Case Classification",
        CaseClassification: "Case Classification",
        Facility: "Facility",
        "Injury Type": "Injury Type",
        "Type Of Injury": "Type Of Injury",
        "Time Work Began": "Time Work Began",
        TimeEmployeeBeganWork: "Time Employee Began Work",
        "Time Of Event": "Time Of Event",
        TimeOfEvent: "Time Of Event",
        "Return To Full Duty Date": "Return To Full Duty Date",
        ReturnToFullDutyDate: "Return To Full Duty Date",
        "Employee Death Date": "Employee Death Date",
        EmployeeDeathDate: "Employee Death Date",
        "Treated In Emergency Room?": "Treated In Emergency Room?",
        TreatedInEmergencyRoom: "Treated In Emergency Room?",
        "Hospitalized Overnight?": "Hospitalized Overnight?",
        HospitalizedOvernight: "Hospitalized Overnight?",
        DateOfBirth: "Date Of Birth",
        "Date Of Birth": "Date Of Birth",
        HireDate: "Hire Date",
        "Hire Date": "Hire Date",
        "Form Completed Date": "Form Completed Date",
        FormCompletedDate: "Form Completed Date",
        ActivityBeforeIncident: "Activity Before Incident",
        "Activity Before Incident": "Activity Before Incident",
        IncidentDescription: "Incident Description",
        "Incident Description": "Incident Description",
        InjuryDescription: "Injury Description",
        "Injury Description": "Injury Description",
        ObjectOrSubstanceInvolved: "Object Or Substance Involved",
        "Object Or Substance Involved": "Object Or Substance Involved",
        LocationOfEvent: "Location Of Event",
        "Location Of Event": "Location Of Event",
        PhysicianFirstName: "Provider First Name",
        "Physician First Name": "Provider First Name",
        PhysicianLastName: "Provider Last Name",
        "Physician Last Name": "Provider Last Name",
        TreatmentFacilityName: "Treatment Facility Name",
        "Treatment Facility Name": "Treatment Facility Name",
        TreatmentFacilityAddress: "Treatment Facility Address",
        "TreatmentFacility Address": "Treatment Facility Address",
        FormCompletedBy: "Form Completed By",
        "Form Completed By": "Form Completed By",
        FormCompletedByTitle: "Form Completed By Title",
        "Form Completed By Title": "Form Completed By Title",
        FormCompletedByPhone: "Form Completed By Phone",
        "Form Completed By Phone": "Form Completed By Phone",
        "Name": "Name",
        "IsEnabled?": "Is Enabled?",
        "Is Enabled?": "Is Enabled?",
        "Allowed Results": "Allowed Results",
        Description: "Description",
        "Assigned Test": "Assigned Test",
        "Facility Name": "Facility Name",
        "Facility Group": "Facility Group",
        "Services": "Services",
        "Facility Abbreviation": "Facility Abbreviation",
        "Facility Phone": "Facility Phone",
        "Facility Email": "Facility Email",
        "Facility Address": "Facility Address",
        Message: "Message",
        "Checked In Date": "Checked In Date",
        "Employee Name": "Employee Name",
        "Employee UID": "Employee UID",
        "Service": "Service",
        "Service Type": "Service Type",
        "Service Specific Type": "Service Specific Type",
        "Appointment Date": "Appointment Date",
        "Appointment Time": "Appointment Time",
        "Appointment Canceled": "Appointment Canceled",
        "Confirmation Code": "Confirmation Code",
        "Appointment Code": "Appointment Code",
        "Is Active:": "Is Active?",
        "Is Active?": "Is Active?",
        "Appointments Per 5 Minutes": "Appointments Per 5 Minutes",
        "Is Private": "Is Private",
        "Is Private?": "Is Private?",
        "Access Code": "Access Code",
        "Date": "Date",
        "Start Time": "Start Time",
        "End Time": "End Time",
        "Nickname": "Nickname",
        "Employee": "Employee",
        "Task": "Task",
        "Record": "Record",
      },
      beforeDateString: {
        DateOfInjury: "Date Of Injury",
        "Date Of Injury": "Date Of Injury",
        "Return To Full Duty Date": "Return to Full Duty Date",
        ReturnToFullDutyDate: "Return to Full Duty Date",
        "Employee Death Date": "Employee Death Date",
        EmployeeDeathDate: "Employee Death Date",
        DateOfBirth: "Date Of Birth",
        "Date Of Birth": "Date Of Birth",
        HireDate: "Hire Date",
        "Hire Date": "Hire Date",
        "Form Completed Date": "Form Completed Date",
        FormCompletedDate: "Form Completed Date",
      },
      afterDateString: {
        DateOfInjury: "Date Of Injury",
        "Date Of Injury": "Date Of Injury",
        "Return To Full Duty Date": "Return to Full Duty Date",
        ReturnToFullDutyDate: "Return to Full Duty Date",
        "Employee Death Date": "Employee Death Date",
        EmployeeDeathDate: "Employee Death Date",
        DateOfBirth: "Date Of Birth",
        "Date Of Birth": "Date Of Birth",
        HireDate: "Hire Date",
        "Hire Date": "Hire Date",
        "Form Completed Date": "Form Completed Date",
        FormCompletedDate: "Form Completed Date",
      },
    },
  },
  //Spanish
  es: {
    translation: {
      welcomeMessage: "Bienvenida al <bold>{{productName}} Portal</bold>",
      registerMessage: "¡Registrar aquí!",
      logInButtonText: "Acceso",
      "1 uppercase (A-Z) ": "1 Mayúsculas (A-Z) ",
      "1 symbol (!@#$%^&*) ": "1 Símbolos (!@#$%^&*) ",
      "1 digits (0-9)": "1 Dígitos (0-9)",
      "Password should contain at least 10 characters and include 1 uppercase (A-Z) 1 symbol (!@#$%^&*) 1 digits (0-9)":
        "La contraseña debe contener al menos 10 caracteres e incluir al menos 1 mayúscula (A-Z), 1 símbolo (!@#$%^&*) y 1 dígito (0-9).",
      "User Role is not a Public role. Please sign into our Admin portal.":
        "El rol de usuario no es un rol público. Por favor, inicie sesión en nuestro portal de administración.",
      requestAnEmemption: "Solicitar una exención",
      Email: "Correo electrónico",
      Password: "Contraseña",
      Login: "Iniciar sesión",
      "Keep Me Signed In": "Mantener sesión iniciada",
      Register: "Registrarse",
      "Forgot Password?": "¿Olvidaste tu contraseña?",
      "Phone Number": "Número de teléfono",
      "New Password": "Nueva contraseña",
      "Confirm Password": "Confirmar contraseña",
      "Passwords do not match": "Las contraseñas no coinciden",
      "First Name": "Nombre",
      "Last Name": "Apellido",
      "Reset Password": "Restablecer contraseña",
      "Enroll MFA": "Inscribir MFA",
      "Password should contain at least 10 characters":
        "La contraseña debe contener al menos 10 caracteres",
      "Password should contain at least 10 characters ":
        "La contraseña debe contener al menos 10 caracteres ",
      "Password should contain at least {{char}} characters ":
        "La contraseña debe contener al menos {{char}} caracteres ",
      "and include ": "y debe incluir ",
      "Warning: too many failed log in attempts will temporarily disable your account.":
        "Advertencia: demasiados intentos de inicio de sesión fallidos desactivarán temporalmente tu cuenta.",
      "The provided value for the password is invalid.":
        "El valor proporcionado para la contraseña no es válido.",
      "There is no user record corresponding to the provided email.":
        "No hay ningún registro de usuario correspondiente al correo electrónico proporcionado.",
      "The provided email is already in use by an existing user.":
        "El correo electrónico proporcionado ya está en uso por un usuario existente.",
      "The provided value for the email property is invalid.":
        "El valor proporcionado para la propiedad de correo electrónico no es válido.",
      "The provided phone number is already in use by an existing user.":
        "El número de teléfono proporcionado ya está en uso por un usuario existente.",
      "The provided verification code is invalid.":
        "El código de verificación proporcionado no es válido.",
      "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later.":
        "El acceso a esta cuenta ha sido temporalmente desactivado debido a muchos intentos fallidos de inicio de sesión. Puede restaurarlo inmediatamente restableciendo su contraseña o puede intentarlo nuevamente más tarde.",
      "Please log in to re-authenticate your session":
        "Por favor, inicia sesión para volver a autenticar tu sesión",
      "This account had been disabled.  Please contact an adminstrator.":
        "Esta cuenta ha sido desactivada. Por favor, contacta a un administrador.",
      "Error with login. ": "Error al iniciar sesión. ",
      "Please try again later.": "Por favor, inténtalo de nuevo más tarde.",
      "Please reset your password.  ":
        "Por favor, restablece tu contraseña. {{pswdConfigs.pattern.message}} ",
      "Please enter your phone number to enroll in multi-factor verification.":
        "Por favor, ingresa tu número de teléfono para inscribirte en la verificación multifactor.",
      "Please enter the 6-digit verification code to continue":
        "Por favor, ingresa el código de verificación de 6 dígitos para continuar",
      "Please enter the 6-digit verification code":
        "Por favor, ingresa el código de verificación de 6 dígitos",
      "Invalid action code. Please try resetting your password again.":
        "Código de acción no válido. Por favor, intenta restablecer tu contraseña nuevamente.",
      "Unable to confirm password reset for username: ":
        "No se puede confirmar el restablecimiento de la contraseña para el nombre de usuario: {{form.username}}",
      "Thank you. Please login with your new password.":
        "Gracias. Por favor, inicia sesión con tu nueva contraseña.",
      "Unable to confirm password reset. Please try resetting your password again.":
        "No se puede confirmar el restablecimiento de la contraseña. Por favor, intenta restablecer tu contraseña nuevamente.",
      "Unable to reset password. Try again later.":
        "No se puede restablecer la contraseña. Inténtalo de nuevo más tarde.",
      "Thank you. Your password has been updated.":
        "Gracias. Tu contraseña ha sido actualizada.",
      "Please log in to re-authenticate your session.":
        "Por favor, inicia sesión para volver a autenticar tu sesión.",
      "Failed to send MFA Enrollment email":
        "Error al enviar el correo electrónico de inscripción en MFA",
      "Thank you for registering.": "Gracias por registrarte.",
      "Please take the time to fill out your profile information before submitting a request for vaccine exemption.":
        "Por favor, tómate el tiempo para completar la información de tu perfil antes de enviar una solicitud de exención de vacunas.",
      "Registration could not be completed at this time. Please try again later.":
        "La registración no pudo completarse en este momento. Por favor, inténtalo de nuevo más tarde.",
      "Email is Required": "Se requiere correo electrónico",
      "Please verify email syntax.":
        "Por favor, verifica la sintaxis del correo electrónico.",
      "Password is Required": "Se requiere contraseña",
      "Password is Required.": "Se requiere contraseña.",
      "Enter phone number": "Ingresa número de teléfono",
      "Invalid Phone Number": "Número de teléfono no válido",
      "Phone Number is Required": "Se requiere número de teléfono",
      "First Name is Required": "Se requiere nombre",
      "Last Name is Required": "Se requiere apellido",
      "Please check your email for instructions.":
        "Por favor, revisa tu correo electrónico para obtener instrucciones.",
      "Username/Email": "Nombre de usuario/Correo electrónico",
      "Username/Email is required":
        "Se requiere Nombre de usuario/Correo electrónico",
      "Logged out successfully": "Cerró sesión correctamente",
      "Log In": "Iniciar sesión",
      copyright:
        "Derechos de autor {{currentYear}} por IMMY Technology Group. Todos los derechos reservados.",
      "Log Out": "Cerrar sesión",
      "Submit Vaccine Exemptions": "Enviar Exenciones de Vacunas",
      "View Vaccine Exemptions": "Ver Exenciones de Vacunas",
      "Update Profile": "Actualizar Perfil",
      Profile: "Perfil",
      "Password Reset": "Restablecimiento de Contraseña",
      "Vaccine Exemption Form": "Formulario de Exención de Vacunas",
      "Type Of Exemption": "Tipo de Exención",
      "Please Select...": "Por favor seleccione...",
      "Please select which Immunizations this exemption applies to:":
        "Por favor seleccione a qué inmunizaciones se aplica esta exención:",
      "Parental decision": "Decisión parental",
      "Religious exemption": "Exención religiosa",
      Other: "Otro",
      "Personal/Employee decision": "Decisión personal/paciente",
      "Medical decision": "Decisión médica",
      "Immunization(s) that are medically contraindicated":
        "Inmunización(es) que están contraindicadas médicamente",
      "Immunization(s)": "Inmunización(es)",
      "First Name of Religious Leader or Parent/Guardian":
        "Nombre del líder religioso o padre/tutor",
      "Last Name of Religious Leader or Parent/Guardian":
        "Apellido del líder religioso o padre/tutor",
      "Summary of Objection": "Resumen de Objeción",
      "Condition that would endanger the life or health of the child":
        "Condición que pondría en peligro la vida o la salud del niño",
      "Physician First Name": "Nombre del médico",
      "Physician Last Name": "Apellido del médico",
      "Physician Address": "Dirección del médico",
      "Physician Phone Number": "Número de teléfono del médico",
      "Child Information": "Información del Niño",
      Review: "Revisión",
      Start: "Comenzar",
      "Parent/ Guardian Information": "Información del Padre/ Tutor",
      "School/ Childcare Information": "Información de la Escuela/ Guardería",
      Acknowledgement: "Reconocimiento",
      "I understand that in the event of a disease outbreak in the School, Child Care Facility or Head Start, my Child may have to be excluded for his/ her protection and for the protection of the other children in the School, Child Care Facility or Head Start":
        "Entiendo que en caso de un brote de enfermedad en la Escuela, Centro de Cuidado Infantil o Head Start, mi hijo/a puede tener que ser excluido/a para su protección y para la protección de los otros niños en la Escuela, Centro de Cuidado Infantil o Head Start",
      Submit: "Enviar",
      "Physician Approval Letter": "Carta de Aprobación del Médico",
      "You must select an Exemption Type":
        "Debes seleccionar un tipo de Exención",
      "You must complete child information":
        "Debes completar la información del niño",
      "You must complete parent information":
        "Debes completar la información del padre/tutor",
      "You must complete school information":
        "Debes completar la información de la escuela",
      "You must acknowledge all policies listed in this form":
        "Debes reconocer todas las políticas enumeradas en este formulario",
      "*An Exemption must be completed for each child":
        "*Una exención debe completarse para cada niño",
      "First Name Of Child": "Primer Nombre del Niño",
      "Middle Initial": "Inicial del Segundo Nombre",
      "Middle Initial Of Child": "Inicial del Segundo Nombre del Niño",
      "Last Name Of Child": "Apellido del Niño",
      "Date of Birth": "Fecha de Nacimiento",
      "Child Date of Birth": "Fecha de Nacimiento del Niño",
      "Birth Country": "País de Nacimiento",
      Gender: "Género",
      "Child Gender": "Género del Niño",
      Ethnicity: "Etnicidad",
      "Child Ethnicity": "Etnicidad del Niño",
      "Race (Limit 3)": "Raza (Límite 3)",
      "Child Race": "Raza del Niño",
      Save: "Guardar",
      Male: "Masculino",
      Female: "Femenino",
      Unknown: "Desconocido",
      "American Indian or Alaska Native":
        "Indígena Americano o Nativo de Alaska",
      Asian: "Asiático",
      "Black or African American": "Negro o Afroamericano",
      "Native Hawaiian or Other Pacific Islander":
        "Nativo Hawaiano u Otro Isleño del Pacífico",
      White: "Blanco",
      "Hispanic or Latino": "Hispano o Latino",
      "Not Hispanic or Latino": "No Hispano o Latino",
      "First Name cannot be empty.": "El nombre no puede estar vacío.",
      "Last Name cannot be empty.": "El apellido no puede estar vacío.",
      "Date of Birth cannot be empty.":
        "La fecha de nacimiento no puede estar vacía.",
      "Date of Birth cannot be in the future.":
        "La fecha de nacimiento no puede estar en el futuro.",
      "Date of Birth can not be more than 20 years ago.":
        "La fecha de nacimiento no puede ser hace más de 20 años.",
      "Birth Country cannot be empty.":
        "El país de nacimiento no puede estar vacío.",
      "Birth State cannot be empty.":
        "El estado de nacimiento no puede estar vacío.",
      "Gender cannot be empty.": "El género no puede estar vacío.",
      "Race cannot be empty.": "La raza no puede estar vacía.",
      "Ethnicity cannot be empty.": "La etnia no puede estar vacía.",
      "Mother's Maiden Name cannot be empty.":
        "El apellido de soltera de la madre no puede estar vacío.",
      "Relationship cannot be empty.": "La relación no puede estar vacía.",
      "Address cannot be empty.": "La dirección no puede estar vacía.",
      "City cannot be empty.": "La ciudad no puede estar vacía.",
      "Zipcode cannot be empty.": "El código postal no puede estar vacío.",
      "Zipcode cannot be blank.": "El código postal no puede estar en blanco.",
      "Zipcode is not a valid zipcode.": "El código postal no es válido.",
      "County cannot be cannot be blank.": "El condado no puede estar vacío.",
      "State cannot be empty.": "El estado no puede estar vacío.",
      "Email must be a valid email address.":
        "El correo electrónico debe ser una dirección de correo electrónico válida.",
      "Email cannot be blank.":
        "El correo electrónico no puede estar en blanco.",
      "Email cannot be longer than 100.":
        "El correo electrónico no puede tener más de 100 caracteres.",
      "Email cannot include the & symbol, if needed, please use the word 'And'":
        "El correo electrónico no puede incluir el símbolo &. Si es necesario, por favor, use la palabra 'Y'.",
      "Phone cannot be empty.": "El teléfono no puede estar vacío.",
      "Phone cannot be blank.": "El teléfono no puede estar en blanco.",
      "Phone is not a valid phone number.":
        "El teléfono no es un número de teléfono válido.",
      "First Name Of Parent": "Nombre del Padre",
      "Middle Initial Of Parent": "Inicial del Segundo Nombre del Padre",
      "Last Name Of Parent": "Apellido del Padre",
      Relationship: "Relación",
      "Relationship to Child": "Relación con el Niño",
      "Mother's Maiden Name": "Apellido de Soltera de la Madre",
      "Street Address": "Dirección",
      "Parent Street Address": "Dirección del Padre",
      County: "Condado",
      "Parent County": "Condado del Padre",
      City: "Ciudad",
      "Parent City": "Ciudad del Padre",
      State: "Estado",
      Zip: "Código Postal",
      "Parent Zipcode": "Código Postal del Padre",
      "Parent Email": "Correo Electrónico del Padre",
      "Cell Phone": "Teléfono Móvil",
      Associate: "Asociado",
      Brother: "Hermano",
      "Care giver": "Cuidador",
      Child: "Niño",
      "Handicapped dependent": "Dependiente discapacitado",
      "Life partner": "Compañero de vida",
      "Emergency contact": "Contacto de emergencia",
      Employee: "Empleado",
      Employer: "Empleador",
      "Extended family": "Familia extendida",
      "Foster child": "Niño adoptivo",
      Friend: "Amigo",
      Father: "Padre",
      Grandchild: "Nieto",
      Guardian: "Guardián",
      Grandparent: "Abuelo",
      Manager: "Gerente",
      Mother: "Madre",
      "Natural child": "Hijo biológico",
      None: "Ninguno",
      "Other adult": "Otro adulto",
      Owner: "Propietario",
      Parent: "Padre",
      Stepchild: "Hijastro",
      Self: "Uno mismo",
      Sibling: "Hermano/a",
      Sister: "Hermana",
      Spouse: "Cónyuge",
      Trainer: "Entrenador",
      "Ward of court": "Pupilo del tribunal",
      "Your exemption has been submitted. You will receive an email and text shortly. NOTE: An exemption must be submitted for each child.":
        "Su exención ha sido enviada. Recibirá un correo electrónico y un mensaje de texto en breve. NOTA: Se debe enviar una exención para cada niño.",
      "Server Error": "Error del servidor",
      "Please try again!": "¡Por favor, inténtelo de nuevo!",
      "Vaccine Records": "Registros de vacunas",
      "No option": "Sin opción",
      Immunizations: "Inmunizaciones",
      "Submission Files Review": "Revisión de archivos de presentación",
      "Service Information Start": "Inicio de información del servicio",
      "Child Information Review": "Revisión de información del niño",
      "Child Information Start": "Inicio de información del niño",
      "Parent Information Review": "Revisión de información del padre",
      "Parent Information Start": "Inicio de información del padre",
      "School Information Review": "Revisión de información escolar",
      "School Information Start": "Inicio de información escolar",
      Close: "Cerrar",
      "Name cannot be empty.": "El nombre no puede estar vacío.",
      "District cannot be empty.": "El distrito no puede estar vacío.",
      "School Year cannot be empty.": "El año escolar no puede estar vacío.",
      "Grade cannot be empty.": "El grado no puede estar vacío.",
      "Name of School": "Nombre de la Escuela",
      "School District": "Distrito Escolar",
      Year: "Año",
      Grade: "Grado",
      "School Phone": "Teléfono de la Escuela",
      Childcare: "Guardería",
      Headstart: "Inicio",
      "Pre-K": "Preescolar",
      Kindergarten: "Kindergarten",
      "Physician Approval Letter Upload":
        "Cargar Carta de Aprobación del Médico",
      "Selected files to upload will not be saved":
        "Los archivos seleccionados para cargar no se guardarán",
      "Remove Files": "Eliminar Archivos",
      "Go Back": "Volver",
      "Selected File": "Archivo Seleccionado",
      "Drop files here or click to select files":
        "Arrastra archivos aquí o haz clic para seleccionar archivos",
      FileDrop: "Cargar Archivos",
      "Unable to filter Results data at this time":
        "No se pueden filtrar los datos de los resultados en este momento",
      "Exemption Type": "Tipo de Exención",
      "Child First Name": "Nombre del Niño",
      "Child Last Name": "Apellido del Niño",
      "School Year": "Año Escolar",
      "Vaccine Exemptions": "Exenciones de Vacunas",
      "Exemption Records": "Registros de Exención",
      Search: "Buscar",
      Exemptions: "Exenciones",
      Total: "Total",
      Page: "Página",
      "Table Page Number": "Número de Página de la Tabla",
      Name: "Nombre",
      "Click to Sort By Name": "Haz clic para Ordenar por Nombre",
      "Click to Sort By Exemption Type":
        "Haz clic para Ordenar por Tipo de Exención",
      Status: "Estado",
      "Click to Sort By Status": "Haz clic para Ordenar por Estado",
      Approved: "Aprobado",
      Pending: "Pendiente",
      Denied: "Denegado",
      Certificate: "Certificado",
      "View Certificate": "Ver Certificado",
      "File not found": "Archivo no encontrado",
      "Could not download file. Please try again.":
        "No se pudo descargar el archivo. Por favor, inténtalo de nuevo.",
      "Exemption Record": "Registro de Exención",
      "Reason for Denial": "Motivo de Denegación",
      "Confirmation Code": "Código de Confirmación",
      Exemption: "Exención",
      "Immunizations for Exemption": "Inmunizaciones para Exención",
      "Contraindicated Immunizations": "Inmunizaciones Contraindicadas",
      "Child Harmful Health Condition": "Condición de Salud Dañina del Niño",
      "Physician Name": "Nombre del Médico",
      "Physician Phone": "Teléfono del Médico",
      "Religious Leader or Parent/Guardian": "Líder Religioso o Padre/Tutor",
      "Child Name": "Nombre del Niño",
      "Child Birth Location": "Lugar de Nacimiento del Niño",
      "Parent Name": "Nombre del Padre/Tutor",
      "Mother Maiden Name": "Apellido de Soltera de la Madre",
      "Parent Address": "Dirección del Padre/Tutor",
      "Parent Phone": "Teléfono del Padre/Tutor",
      School: "Escuela",
      "School Name": "Nombre de la Escuela",
      "School Grade": "Grado Escolar",
      "Immunizations that are harmful to the child":
        "Inmunizaciones que son perjudiciales para el niño",
      "School/ Childcare": "Escuela/ Cuidado Infantil",
      "Name of Childcare": "Nombre del Cuidado Infantil",
      "First Name cannot be blank.":
        "El nombre del paciente no puede estar en blanco.",
      "First Name cannot be longer than 50.":
        "El nombre del paciente no puede tener más de 50 caracteres.",
      "Middle Name cannot be blank.":
        "El segundo nombre del paciente no puede estar en blanco.",
      "Middle Name cannot be longer than 50.":
        "El segundo nombre del paciente no puede tener más de 50 caracteres.",
      "Last Name cannot be blank.":
        "El apellido del paciente no puede estar en blanco.",
      "Last Name cannot be longer than 50.":
        "El apellido del paciente no puede tener más de 50 caracteres.",
      "Guardian First Name cannot be blank.":
        "El nombre del tutor no puede estar en blanco.",
      "Guardian First Name cannot be longer than 50.":
        "El nombre del tutor no puede tener más de 50 caracteres.",
      "Guardian Last Name cannot be blank.":
        "El apellido del tutor no puede estar en blanco.",
      "Guardian Last Name cannot be longer than 50.":
        "El apellido del tutor no puede tener más de 50 caracteres.",
      "Date of Birth can not be more than 150 years ago.":
        "La fecha de nacimiento del paciente no puede ser hace más de 150 años.",
      "Address cannot be longer than 500.":
        "La dirección del paciente no puede tener más de 500 caracteres.",
      "Street Address cont. cannot be empty.":
        "La dirección de la calle (continuación) del paciente no puede estar en blanco.",
      "Street Address cont. cannot be longer than 500.":
        "La dirección de la calle (continuación) del paciente no puede tener más de 500 caracteres.",
      "City cannot be longer than 100.":
        "La ciudad del paciente no puede tener más de 100 caracteres.",
      "County cannot be empty.":
        "El condado del paciente no puede estar en blanco.",
      "County cannot be longer than 100.":
        "El condado del paciente no puede tener más de 100 caracteres.",
      "Country cannot be empty.":
        "El país del paciente no puede estar en blanco.",
      "EmployeeRace cannot be empty.":
        "La raza del paciente no puede estar en blanco.",
      "Profile saved": "Perfil guardado",
      "Profile Management": "Gestión de perfil",
      "Middle Name": "Segundo nombre",
      Race: "Raza",
      "Street Address cont.": "Continuación de la dirección",
      Zipcode: "Código postal",
      Country: "País",
      "Street Address cannot be blank.":
        "La dirección no puede estar en blanco.",
      "City cannot be blank.": "La ciudad no puede estar en blanco.",
      "County cannot be blank.": "El condado no puede estar en blanco.",
      "Treatment Facility Address":
        "Dirección de la Instalación de Tratamiento",
      "Injury and Illness Incident Report (Form 301)":
        "Informe de Incidentes de Lesiones y Enfermedades (Formulario 301)",
      "Employee First Name": "Nombre del Empleado",
      "Employee Last Name": "Apellido del Empleado",
      "Employee Search": "Búsqueda de Empleado",
      "The first name of the employee": "El nombre del empleado",
      "The last name of the employee": "El apellido del empleado",
      "The gender of the employee": "El género del empleado",
      "The date of birth of the employee":
        "La fecha de nacimiento del empleado",
      "Employee Title": "Título del Empleado",
      "The title of the employee": "El título del empleado",
      "Employee Email": "Correo Electrónico del Empleado",
      "The email of the employee": "El correo electrónico del empleado",
      Phone: "Teléfono",
      "The phone number of the employee": "El número de teléfono del empleado",
      "Employee Hire Date": "Fecha de Contratación del Empleado",
      "The date employee was hired":
        "La fecha en que el empleado fue contratado",
      Incident: "Incidente",
      "Case Number": "Número de Caso",
      "The case number": "El número de caso",
      "Case Classification": "Clasificación del Caso",
      "The date of injury": "La fecha de la lesión",
      "Injury Type": "Tipo de Lesión",
      "Time Work Began": "Hora en que comenzó el trabajo",
      "Time employee began work": "Hora en que el empleado comenzó a trabajar",
      "Time of Event": "Hora del Evento",
      Facility: "Instalación",
      "Has employee returned to full duty?":
        "¿Ha regresado el empleado a servicio completo?",
      "Returned to Full Duty": "Regresó a Servicio Completo",
      "Activity Before Incident": "Actividad Antes del Incidente",
      "What was the employee doing just before the incident occurred?":
        "¿Qué estaba haciendo el empleado justo antes de que ocurriera el incidente?",
      "Incident Description": "Descripción del Incidente",
      "What happened? How did the injury occur?":
        "¿Qué sucedió? ¿Cómo ocurrió la lesión?",
      "Injury Description": "Descripción de la Lesión",
      "Object or Substance Involved": "Objeto o Sustancia Involucrada",
      "What was the injury or illness?": "¿Cuál fue la lesión o enfermedad?",
      "What object or substance directly harmed the employee?":
        "¿Qué objeto o sustancia dañó directamente al empleado?",
      "Location of Event": "Ubicación del Evento",
      "Provider First Name": "Nombre del proveedor",
      "First name of health care provider":
        "Primer nombre del proveedor de atención médica",
      "Provider Last Name": "Apellido del proveedor",
      "Last name of health care provider":
        "Apellido del proveedor de atención médica",
      "Treatment Facility Name": "Nombre del centro de tratamiento",
      "Treated in Emergency Room?": "¿Tratado en la sala de emergencias?",
      "Mark 'Yes' if employee was treated in emergency room":
        "Marque 'Sí' si el empleado fue tratado en la sala de emergencias",
      "Hospitalized overnight?": "¿Hospitalizado durante la noche?",
      "Mark 'Yes' if employee was hospitalized overnight":
        "Marque 'Sí' si el empleado fue hospitalizado durante la noche",
      "Date of Injury": "Fecha de la Lesión",
      "Return to Full Duty Date": "Fecha de Regreso a Servicio Completo",
      "Did Employee Die?": "¿El Empleado Murió?",
      "Date of Death": "Fecha de Defunción",
      Treatment: "Tratamiento",
      "Form Completed By": "Formulario completado por",
      "Name of who completed the form":
        "Nombre de quien completó el formulario",
      Title: "Título",
      "Title of who completed the form":
        "Título de quien completó el formulario",
      "The phone number of who completed the form":
        "Número de teléfono de quien completó el formulario",
      Date: "Fecha",
      "The date the form was completed":
        "Fecha en que se completó el formulario",
      "Please complete the form before submitting":
        "Por favor, complete el formulario antes de enviarlo",
      "Return to Full Duty and Did Employee Die cannot both be true":
        "Regreso a tiempo completo y el empleado no pueden ser ambos verdaderos",
      "If Return to Full Duty is true, Employee Death Date must be empty":
        "Si el regreso a tiempo completo es verdadero, la fecha de muerte del empleado debe estar vacía",
      "If Did Employee Die is true, Return to Full Duty Date must be empty":
        "Si el empleado murió es verdadero, la fecha de regreso a tiempo completo debe estar vacía",
      "Time Work Began must be earlier than Time of Event":
        "La hora de inicio del trabajo debe ser anterior a la hora del evento",
      "Time of Event cannot be in the future":
        "La hora del evento no puede estar en el futuro",
      "Update Report": "Actualizar informe",
      "Submit Report": "Enviar informe",
      OSHA: "OSHA",
      "OSHA Reporting": "Informe de OSHA",
      "Need help?": "¿Necesitas ayuda?",
      "Search for employee": "Buscar empleado",
      "Search for facility": "Buscar instalación",
      "Search for case classification": "Buscar clasificación de casos",
      "Search for injury type": "Buscar tipo de lesión",
      "Date of Injury Range": "Rango de fechas de lesiones",
      "Injury date range to filter by":
        "Rango de fechas de lesiones para filtrar",
      Clear: "Limpiar",
      "New Report": "Nuevo informe",
      "Summary (Form 300A)": "Resumen (Formulario 300A)",
      "Search for summary facility": "Buscar instalación de resumen",
      SummaryFacility: "Instalación de resumen",
      "Annual Average Number of Employees": "Promedio anual de empleados",
      "Total Hours Worked By All Employees Last Year":
        "Total de horas trabajadas por todos los empleados el año pasado",
      "Summary Date Range": "Rango de fechas de resumen",
      "Date range for summary report (Form 300A).":
        "Rango de fechas para el informe de resumen (Formulario 300A).",
      "Generate Summary": "Generar resumen",
      "Log (Form 300)": "Registro (Formulario 300)",
      "Export to CSV": "Exportar a CSV",
      "Total Records": "Total de registros",
      "Employee Name": "Nombre del empleado",
      "Employee DOB": "Fecha de nacimiento del empleado",
      "Case #": "Número de caso",
      "Injury Date": "Fecha de la lesión",
      "Submitted By": "Presentado por",
      "Begin date must be before end date":
        "La fecha de inicio debe ser antes de la fecha de finalización",
      "No reports found": "No se encontraron informes",
      "Search failed. Please try again.":
        "La búsqueda falló. Inténtalo de nuevo.",
      "Log of Work-Related Injuries and Illnesses (Form 300).xlsx":
        "Registro de lesiones y enfermedades relacionadas con el trabajo (Formulario 300).xlsx",
      "Report updated successfully.": "Informe actualizado con éxito.",
      "Report created successfully.": "Informe creado con éxito.",
      "Could not Update report at this time.":
        "No se pudo actualizar el informe en este momento.",
      "Could not Create report at this time.":
        "No se pudo crear el informe en este momento.",
      "Summary Facility needed to generate Summary (Form 300A)":
        "Instalación de resumen necesaria para generar el resumen (Formulario 300A)",
      "Summary Date Range needed to generate Summary (Form 300A)":
        "Se necesita un rango de fechas de resumen para generar el resumen (Formulario 300A)",
      "Summary begin date must be before end date":
        "La fecha de inicio del resumen debe ser antes de la fecha de finalización",
      "Final Report requires Annual Average Number of Employees and Total Hours Worked By All Employees Last Year":
        "El informe final requiere el número promedio anual de empleados y el total de horas trabajadas por todos los empleados el año pasado",
      "Would you like to generate a draft report?":
        "¿Le gustaría generar un informe preliminar?",
      "Generate Draft": "Generar borrador",
      Cancel: "Cancelar",
      "Away From Work": "Lejos del Trabajo",
      "Job Transfer Or Restriction": "Transferencia de Trabajo o Restricción",
      "Other Recordable Case": "Otro Caso Registrable",
      Injury: "Lesión",
      "Skin disorder": "Discapacidad en la piel",
      "Repiratory condition": "Condición respiratoria",
      Poisoning: "Envenenamiento",
      "Hearing loss": "Pérdida auditiva",
      "Other illness": "Otra enfermedad",
      Begin: "Comenzar",
      End: "Finalizar",

      requireNonNullValidator: "{{field}} no puede estar vacío.",
      requireNotBlankValidator: "{{field}} no puede estar en blanco.",
      requireNotFutureDate: "{{field}} no puede ser en el futuro.",
      requireNotBeforeDate:
        "{{field}} no puede ser antes de {{beforeDateString}}.",
      requireNotAfterDate:
        "{{field}} no puede ser después de {{afterDateString}}.",
      fields: {
        DateOfInjury: "Fecha de la lesión",
        "Date Of Injury": "Fecha de la lesión",
        "Case Classification": "Clasificación del caso",
        CaseClassification: "Clasificación del caso",
        Facility: "Instalación",
        "Injury Type": "Tipo de lesión",
        "Type Of Injury": "Tipo de lesión",
        "Time Work Began": "Hora de inicio del trabajo",
        TimeEmployeeBeganWork: "Hora de inicio del trabajo",
        "Time Of Event": "Hora del evento",
        TimeOfEvent: "Hora del evento",
        "Return To Full Duty Date": "Fecha de regreso a servicio completo",
        ReturnToFullDutyDate: "Fecha de regreso a servicio completo",
        "Employee Death Date": "Fecha de muerte del empleado",
        EmployeeDeathDate: "Fecha de muerte del empleado",
        "Treated In Emergency Room?": "¿Tratado en la sala de emergencias?",
        TreatedInEmergencyRoom: "¿Tratado en la sala de emergencias?",
        "Hospitalized Overnight?": "¿Hospitalizado durante la noche?",
        HospitalizedOvernight: "¿Hospitalizado durante la noche?",
        DateOfBirth: "Fecha de nacimiento",
        "Date Of Birth": "Fecha de nacimiento",
        HireDate: "Fecha de contratación",
        "Hire Date": "Fecha de contratación",
        "Form Completed Date": "Fecha de completado del formulario",
        FormCompletedDate: "Fecha de completado del formulario",
        ActivityBeforeIncident: "Actividad antes del incidente",
        "Activity Before Incident": "Actividad antes del incidente",
        IncidentDescription: "Descripción del incidente",
        "Incident Description": "Descripción del incidente",
        InjuryDescription: "Descripción de la lesión",
        "Injury Description": "Descripción de la lesión",
        ObjectOrSubstanceInvolved: "Objeto o sustancia involucrada",
        "Object Or Substance Involved": "Objeto o sustancia involucrada",
        LocationOfEvent: "Ubicación del evento",
        "Location Of Event": "Ubicación del evento",
        PhysicianFirstName: "Nombre del médico",
        "Physician First Name": "Nombre del médico",
        PhysicianLastName: "Apellido del médico",
        "Physician Last Name": "Apellido del médico",
        TreatmentFacilityName: "Nombre de la instalación de tratamiento",
        "Treatment Facility Name": "Nombre de la instalación de tratamiento",
        TreatmentFacilityAddress: "Dirección de la instalación de tratamiento",
        "TreatmentFacility Address":
          "Dirección de la instalación de tratamiento",
        FormCompletedBy: "Completado por",
        "Form Completed By": "Completado por",
        FormCompletedByTitle: "Título del completado por",
        "Form Completed By Title": "Título del completado por",
        FormCompletedByPhone: "Teléfono del completado por",
        "Form Completed By Phone": "Teléfono del completado por",
        "Name": "Nombre",
        "IsEnabled?": "¿Está habilitado?",
        "Allowed Results": "Resultados permitidos",
        Description: "Descripción",
        "Assigned Test": "Prueba asignada",
        "Facility Name": "Nombre de la instalación",
        "Facility Group": "Grupo de instalación",
        "Services": "Servicios",
        "Facility Abbreviation": "Abreviación de la instalación",
        "Facility Phone": "Teléfono de la instalación",
        "Facility Email": "Correo electrónico de la instalación",
        "Facility Address": "Dirección de la instalación",
        Message: "Mensaje",
        "Checked In Date": "Fecha de comprobación",
        "Employee Name": "Nombre del empleado",
        "Employee UID": "UID del empleado",
        "Service": "Servicio",
        "Service Type": "Tipo de servicio",
        "Service Specific Type": "Tipo de servicio específico",
        "Appointment Date": "Fecha de la cita",
        "Appointment Time": "Hora de la cita",
        "Appointment Canceled": "Cita cancelada",
        "Confirmation Code": "Código de confirmación",
        "Appointment Code": "Código de cita",
        "Is Active": "Está activo",
        "Is Active?": "¿Está activo?",
        "Appointment Per 5 Minutes": "Citas por 5 minutos",
        "Is Private": "Es privado",
        "Is Private?": "¿Es privado?",
        "Access Code": "Código de acceso",
        "Date": "Fecha",
        "Start Time": "Hora de inicio",
        "End Time": "Hora de finalización",
        "Nickname": "Apodo",
        "Is Enabled?": "¿Está habilitado?",
        "Employee": "Empleado",
        "Task": "Tarea",
        "Record": "Registro",
      },
      beforeDateString: {
        DateOfInjury: "Fecha de la lesión",
        "Date Of Injury": "Fecha de la lesión",
        "Return To Full Duty Date": "Fecha de regreso a servicio completo",
        ReturnToFullDutyDate: "Fecha de regreso a servicio completo",
        "Employee Death Date": "Fecha de muerte del empleado",
        EmployeeDeathDate: "Fecha de muerte del empleado",
        DateOfBirth: "Fecha de nacimiento",
        "Date Of Birth": "Fecha de nacimiento",
        HireDate: "Fecha de contratación",
        "Hire Date": "Fecha de contratación",
        "Form Completed Date": "Fecha de completado del formulario",
        FormCompletedDate: "Fecha de completado del formulario",
      },
      afterDateString: {
        DateOfInjury: "Fecha de la lesión",
        "Date Of Injury": "Fecha de la lesión",
        "Return To Full Duty Date": "Fecha de regreso a servicio completo",
        ReturnToFullDutyDate: "Fecha de regreso a servicio completo",
        "Employee Death Date": "Fecha de muerte del empleado",
        EmployeeDeathDate: "Fecha de muerte del empleado",
        DateOfBirth: "Fecha de nacimiento",
        "Date Of Birth": "Fecha de nacimiento",
        HireDate: "Fecha de contratación",
        "Hire Date": "Fecha de contratación",
        "Form Completed Date": "Fecha de completado del formulario",
        FormCompletedDate: "Fecha de completado del formulario",
      },
    },
  },
};

// Initialize i18n
i18next
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: "en", // Default language
    detection: {
      order: ["querystring", "cookie"],
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false, // React already handles escaping
    },
  });

export default i18next;
